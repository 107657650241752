import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import './DateTimePickersStyles.css';
import currencies from '../../../../../../config/currency';
import IOSToggleSwitch from '../../../../../common/IOSToggleSwitch/IOSToggleSwitch';

const useStyles = makeStyles(() => ({
  languageSelect: {
    width: '100%',
  },
  datePicker: {
    width: '100%',
  },
  dateAndTimePicker: {
    maxHeight: '100%!important',
  },
}));

/**
 * @description EventGeneralInformationStep component - First step in create event form
 * @method EventGeneralInformationStep
 * @returns {'<EventGeneralInformationStep>'} <EventGeneralInformationStep>
 */
export default function EventGeneralInformationStep({ eventGeneralInfo, setEventGeneralInfo }) {
  const classes = useStyles();
  useEffect(() => {
    setEventGeneralInfo(eventGeneralInfo);
  }, [eventGeneralInfo, setEventGeneralInfo]);

  function handleNameChangeBG(event) {
    setEventGeneralInfo({
      ...eventGeneralInfo,
      name_bg: event.target.value,
    });
  }

  function handleNameChangeEN(event) {
    setEventGeneralInfo({
      ...eventGeneralInfo,
      name_en: event.target.value,
    });
  }

  function handleCurrencyChange(event) {
    setEventGeneralInfo({
      ...eventGeneralInfo,
      currency: event.target.value,
    });
  }

  const handleStartDateChange = (date) => {
    setEventGeneralInfo({
      ...eventGeneralInfo,
      start_time: date,
    });
  };

  const handleEndDateChange = (date) => {
    setEventGeneralInfo({
      ...eventGeneralInfo,
      end_time: date,
    });
  };

  const togglePenniesChecked = () => {
    setEventGeneralInfo({
      ...eventGeneralInfo,
      display_pennies: !eventGeneralInfo.display_pennies,
    });
  };

  return (
    <>
      <Typography component='h6' variant='h6' gutterBottom>
        Add Event General Information:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            component='span'
            required
            id='name_bg'
            label='Event Name BG'
            fullWidth
            onChange={handleNameChangeBG}
            value={eventGeneralInfo.name_bg}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            component='span'
            required
            id='name_en'
            name='name'
            label='Event Name EN'
            fullWidth
            onChange={handleNameChangeEN}
            value={eventGeneralInfo.name_en}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl required className={classes.languageSelect}>
            <InputLabel id=''>Currency</InputLabel>
            <Select
              component='span'
              required
              labelId=''
              id='currency'
              value={eventGeneralInfo.currency}
              onChange={handleCurrencyChange}
              className={classes.selectEmpty}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value} id={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12} sm={6} justify='center' alignItems='flex-end'>
          <IOSToggleSwitch
            checked={eventGeneralInfo.display_pennies}
            onChange={togglePenniesChecked}
            label='Display Pennies'
            labelPlacement='start'
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            className={classes.datePicker}
          >
            <KeyboardDateTimePicker
              ampm={false}
              label='Start Date*'
              value={eventGeneralInfo.start_time}
              onChange={handleStartDateChange}
              minDate={format(new Date(1970, 0, 1), "yyyy-MM-dd'T'HH:mm:ss")}
              format='yyyy/MM/dd HH:mm'
              fullWidth
              id='start_time'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
          >
            <KeyboardDateTimePicker
              ampm={false}
              label='End Date*'
              value={eventGeneralInfo.end_time}
              onChange={handleEndDateChange}
              minDate={format(new Date(1970, 0, 1), "yyyy-MM-dd'T'HH:mm:ss")}
              format='yyyy/MM/dd HH:mm'
              fullWidth
              id='end_time'
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </>
  );
}
