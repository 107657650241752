/* eslint-disable import/prefer-default-export */
import React, { createContext, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CustomSnackBar from '../components/common/CustomSnackBar/CustomSnackBar';

/**
 * @file SnackBarContext - context used for SnackBar popup
 */

const initialState = {
  isPopUpOppened: false,
  snackType: 'error',
  snackMessage: 'example',
  snackId: 'example',
};

export const SnackBarContext = createContext(initialState);

/**
 * @description SnackBarProvider component
 * @method SnackBarProvider
 * @returns {'<SnackBarProvider>'} <SnackBarProvider>
 */
export const SnackBarProvider = ({ children }) => {
  const [snackState, setSnackState] = useState(initialState);
  function handleClose() {
    setSnackState({ ...snackState, isPopUpOppened: false });
  }
  function openSnackBar(type, message, id) {
    setSnackState({
      isPopUpOppened: true,
      snackType: type,
      snackMessage: message,
      snackId: id,
    });
  }
  return (
    <SnackBarContext.Provider
      value={{ openSnackBar, snackState }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackState.isPopUpOppened}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <CustomSnackBar
          id={snackState.snackId}
          variant={snackState.snackType}
          message={snackState.snackMessage}
          onClose={handleClose}
        />
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};
