/* eslint-disable camelcase */
import React, {
  useState, useEffect, useContext, useRef, useCallback,
} from 'react';
import Papa from 'papaparse';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Paper, useMediaQuery } from '@material-ui/core';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import {
  PlusOneRounded as PlusOneRoundedIcon,
  NoteAddRounded as NoteAddRoundedIcon,
  MoreHoriz as MoreHorizIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import AddTerminalModal from './AddTerminalModal/AddTerminalModal';
import EditTerminalModal from './EditTerminalModal/EditTerminalModal';
import AlertsModal from './AlertsModal/AlertsModal';
import { DialogContext } from '../../../../../../context/DialogContext';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import {
  getTerminal, deleteTerminal, addTerminal, getTerminals,
} from '../../../../../../api/TerminalsCalls';
import { checkForDuplicatedIDs } from '../../../../../../utils/csvFilesIDsValidation/csvFilesIDsValidation';
import TerminalsCards from './TerminalsCards/TerminalsCards';
import TerminalsAccordionStack from './TerminalsAccordionStack/TerminalsAccordionStack';
import useInterval from '../../../../../../hooks/useInterval';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export default function Terminals() {
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [openNewTerminalDialog, setOpenNewTerminalDialog] = useState(false);
  const [openViberAlertsDialog, setOpenViberAlertsDialog] = useState(false);
  const [openEditTerminalDialog, setOpenEditTerminalDialog] = useState(false);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [terminalToEdit, setTerminalToEdit] = useState({});
  const classes = useStyles();
  let { id } = useParams();
  id = Number(id);
  const [terminals, setTerminals] = useState([]);
  const [terminalIDEdit, setTerminalIDEdit] = useState('');
  const [csvAdded, setCsvAdded] = useState(false);
  const inputCSVRef = useRef(null);

  const addData = useCallback(async () => {
    // Used to set the sort order for terminals using the state value.
    const sortBy = { NO_CONNECTION: 0, PASSIVE: 1, ACTIVE: 2 };
    const terminalsForEvent = await getTerminals(id);
    setTerminals(terminalsForEvent.data.sort((a, b) => sortBy[a.state] - sortBy[b.state]));
  }, [id]);

  useEffect(() => {
    addData();
  }, [id, csvAdded, openNewTerminalDialog, openEditTerminalDialog, addData]);

  useInterval(() => {
    addData();
  }, 60000);

  const handleDelete = (terminalID, external_id) => {
    const deleteHandlerForForm = async () => {
      try {
        await deleteTerminal(terminalID);
        const terminalsForEvent = await getTerminals(id);
        setTerminals(terminalsForEvent.data);
        openSnackBar('info', `Terminal with ID ${external_id} deleted.`, 'delete-terminal-success-popup');
      } catch (error) {
        openSnackBar('error', 'Something went wrong!', 'delete-terminal-fail-popup');
      }
      closeDialog();
    };
    openDialog(
      'error',
      'Delete',
      `Terminal with ID ${external_id} will be deleted!`,
      'Are you sure?',
      'delete-terminal-modal-button',
      deleteHandlerForForm,
    );
  };

  async function handleEdit(terminalID) {
    const terminal = await getTerminal(terminalID);
    setTerminalToEdit(terminal.data);
    setTerminalIDEdit(terminalID);
    setOpenEditTerminalDialog(true);
  }

  const handleActionButtonClose = () => {
    setOpenSpeedDial(false);
  };

  const handleActionButtonOpen = () => {
    setOpenSpeedDial(true);
  };

  const handleAddSingleTerminal = () => {
    setOpenNewTerminalDialog(true);
  };

  const handleViberAlerts = () => {
    setOpenViberAlertsDialog(true);
  };

  const handleAddCVSForTerminals = () => {
    inputCSVRef.current.click();
  };

  async function deleteAllTerminals() {
    try {
      const terminalsForEvent = await getTerminals(id);
      await Promise.all(
        terminalsForEvent.data.map(async (terminal) => {
          await deleteTerminal(terminal.id);
        }),
      );
    } catch (error) {
      openSnackBar('error', 'Something went wrong!', 'delete-terminals-fail-popup');
    }
  }

  async function addTerminalsFromFile(terminalsArray) {
    return Promise.all(
      terminalsArray.map(async (terminal) => {
        // eslint-disable-next-line no-param-reassign
        terminal.event_id = id;
        await addTerminal(terminal);
      }),
    );
  }

  const arrayToTerminalObject = (values) => {
    const keys = ['external_id', 'name_bg', 'name_en'];
    return keys.reduce((o, k, i) => ({ ...o, [k]: values[i] }), {});
  };

  function createTerminalsArray(multipleTerminalsArray) {
    return multipleTerminalsArray.map((singleTerminal) => arrayToTerminalObject(singleTerminal));
  }

  const handleCSVInputChange = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      async complete(results) {
        results.data.pop();
        const normalisedTerminals = createTerminalsArray(results.data);
        const isDuplicatedId = checkForDuplicatedIDs(normalisedTerminals);
        if (isDuplicatedId) {
          openSnackBar('error', 'There are duplicated IDs, please check your csv file!', 'duplicated-ids-fail-terminal-popup');
          return;
        }
        try {
          await deleteAllTerminals();
          await trackPromise(
            addTerminalsFromFile(normalisedTerminals)
              .then(setCsvAdded(!csvAdded))
              .then(openSnackBar('success', 'Terminals succesfully added from file.', 'add-terminals-from-file-success-popup')),
          );
        } catch (error) {
          openSnackBar('error', 'Something went wrong!', 'add-terminals-from-file-fail-popup');
        }
      },
    });
  };

  const speedDialActions = [
    {
      icon: <PlusOneRoundedIcon />,
      name: 'Add a Single Terminal',
      id: 'add-single-terminal',
      handler: handleAddSingleTerminal,
    },
    {
      icon: <NoteAddRoundedIcon />,
      name: 'Upload CSV With Terminals',
      id: 'add-csv-terminals',
      handler: handleAddCVSForTerminals,
    },
    {
      icon: <NotificationsActiveIcon />,
      name: 'Alerts',
      id: 'add-alerts-data',
      handler: handleViberAlerts,
    },
  ];

  return (
    <div className={classes.content}>
      <Container maxWidth='lg' className={classes.container}>
        {
          matchesDesktopScreen && (
            <Paper className={classes.root}>
              <TerminalsAccordionStack
                headingID='Terminal ID'
                panelsArray={terminals}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                cypressID='terminal-name-en'
              />
            </Paper>
          )
        }
        {
          matchesMobileScreen && (
            <TerminalsCards
              cardsArray={terminals}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          )
        }
        <SpeedDial
          ariaLabel='SpeedDial example'
          className={classes.speedDial}
          icon={<MoreHorizIcon />}
          onClose={handleActionButtonClose}
          onOpen={handleActionButtonOpen}
          open={openSpeedDial}
          direction='up'
          id='speed-dial'
        >
          {
            speedDialActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
                id={action.id}
                data-cy={action.id}
              />
            ))
          }
        </SpeedDial>
      </Container>
      <AddTerminalModal
        openDialog={openNewTerminalDialog}
        setOpenDialog={setOpenNewTerminalDialog}
        eventId={id}
      />
      <AlertsModal
        openDialog={openViberAlertsDialog}
        setOpenDialog={setOpenViberAlertsDialog}
        eventId={id}
      />
      <EditTerminalModal
        openDialog={openEditTerminalDialog}
        setOpenDialog={setOpenEditTerminalDialog}
        eventId={id}
        terminalToEdit={terminalToEdit}
        setTerminalToEdit={setTerminalToEdit}
        terminalIDEdit={terminalIDEdit}
      />
      <input
        ref={inputCSVRef}
        type='file'
        id='csv-terminals-file'
        accept='.csv'
        onChange={handleCSVInputChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
