/* eslint-disable camelcase */
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
  media: {
    height: 140,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    wordWrap: 'break-word',
  },
  dynamicHeight: {
    height: 'calc(100% - 64px)',
  },
  scrollableTable: {
    width: '100%',
    overflowX: 'auto',
  },
}));

export default function StaffCard({
  id,
  external_id,
  nameBG,
  nameEN,
  handleDelete,
  handleEdit,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = async () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.dynamicHeight}
      >
        <CardContent onClick={handleExpandClick}>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            ID:
            {' '}
            {external_id}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Name BG:
            {' '}
            {nameBG}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Name EN:
            {' '}
            {nameEN}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <ToolTip title='Edit'>
          <IconButton onClick={() => handleEdit(id)}>
            <EditIcon />
          </IconButton>
        </ToolTip>
        <ToolTip title='Delete'>
          <IconButton onClick={() => handleDelete(id, external_id)}>
            <DeleteIcon />
          </IconButton>
        </ToolTip>
      </CardActions>
    </Card>
  );
}
