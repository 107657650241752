import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { setIsDialogOpened } from '../../../../context/UserActions';
import { UserContext } from '../../../../context/UserContext';
import StyledDialogTitle from '../../../common/StyledDialogTitle/WhiteBackgroundDialogTitle/WhiteBackgroundDialogTitle';
import Transition from '../../../common/TransitionComponent/TransitionComponent';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
  },
}));

export default function ViberSubscriptionDialog({ isOpen, setIsOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userState, userDispatch } = useContext(UserContext);

  const handleClose = () => {
    setIsOpen(false);
    setIsDialogOpened(false, userDispatch);
  };

  const handleSubscribeClick = () => {
    window.location.href = userState.viberUrl;
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-cy='viber-subscription-dialog'
    >
      <StyledDialogTitle>
        {t('viberSubscriptionDialog.dialogTitle')}
        <IconButton aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Typography>{t('viberSubscriptionDialog.dialogContent')}</Typography>
        <Typography variant='body2'>
          {t('viberSubscriptionDialog.dialogExplanatoryText')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} data-cy='initial-viber-dialog-no-button'>
          {t('viberSubscriptionDialog.noButton')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubscribeClick}
          data-cy='initial-viber-dialog-yes-button'
        >
          {t('viberSubscriptionDialog.yesButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ViberSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
