/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description Get payment transactions for the event api call
 * @async
 * @method getPayments
 * @param  {Integer} id - event id
 * @returns {Object} response object
 */

export async function getPayments(eventId) {
  const response = await loggedInAxios.get(`/payments?event_id=${eventId}`);
  return response;
}

/**
 * @description Update payment status api call
 * @async
 * @method updatePaymentStatus
 * @param  {Integer, JSON} orderNumber, patchData
 * @returns {Object} response object
 */

export async function updatePaymentStatus(orderNumber, patchData) {
  const response = await loggedInAxios.patch(`/payments/${orderNumber}/`, patchData);
  return response;
}
