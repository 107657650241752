import React, {
  useContext, useEffect, useState,
} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Grow from '@material-ui/core/Grow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import EventCard from './Cards/EventCard';
import AddEventCard from './Cards/AddEventCard';
import NavigationBar from '../../navigation/NavigationBar';
import { openForm, closeForm } from '../../../../context/Actions';
import { CreateEventContext } from '../../../../context/CreateEventContext';
import { EventsContext } from '../../../../context/EventsContext';
import { getEvents } from '../../../../api/EventCalls';
import { addEventsData } from '../../../../context/EventsActions';
import AddEventForm from './AddEventForm/AddEventForm';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: '5vh',
    right: '5vh',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  cardGrow: {
    display: 'flex',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide duration={1500} direction='up' ref={ref} {...props} />);

/**
 * @description Title for dialog
 * @function DialogTitle
 * @param  {props} props - all of props from the parent.
 * @returns {'<MuiDialogTitle>'} - MuiDialogTitle component
 */
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography component='h6' variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' data-cy='close-create-event-dialog' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * @description This is the Admin landing page where events are shown to user, after logging in.
 * @function AdminLanding
 * @param  {props} props - all of props from the parent.
 * @returns {'<NavigationBar>'} - NavigationBar component
 */
export default function AdminLanding() {
  const theme = useTheme();
  const classes = useStyles();
  const [isAnimationOn, setIsAnimationOn] = useState(false);
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { state, dispatch } = useContext(CreateEventContext);
  const { eventsState, eventsDispatch } = useContext(EventsContext);
  const scroll = 'body';
  const handleOpen = () => {
    openForm(dispatch);
  };

  const handleClose = () => {
    closeForm(dispatch);
  };

  useEffect(() => {
    async function addData() {
      const events = await getEvents();
      addEventsData(events.data.reverse(), eventsDispatch);
      setIsAnimationOn(true);
    }
    addData();
  }, [eventsDispatch]);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <NavigationBar />
      <CssBaseline />
      <main>
        <Container maxWidth='md'>
          <Grid container spacing={4}>
            {
              matchesDesktopScreen && (
              <Grow
                className={classes.cardGrow}
                in={isAnimationOn}
                style={{ transformOrigin: '0 0 0' }}
                {...(isAnimationOn ? { timeout: 1000 } : {})}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <AddEventCard />
                </Grid>
              </Grow>
              )
            }
            {eventsState.events.map((card, i) => (
              <Grow
                className={classes.cardGrow}
                key={card.id}
                in={isAnimationOn}
                style={{ transformOrigin: '0 0 0' }}
            // used for animation
                {...(isAnimationOn ? { timeout: 250 * i } : {})}
              >
                <Grid item key={card} xs={12} sm={6} md={3}>
                  <EventCard title={card.name_en} image={card.picture} eventId={card.id} id='card' />
                </Grid>
              </Grow>
            ))}
          </Grid>
          {
            matchesMobileScreen && (
            <Fab onClick={handleOpen} id='create-event-button' size='large' color='primary' aria-label='add' className={classes.fab}>
              <AddIcon />
            </Fab>
            )
          }
        </Container>
        <Dialog
          open={state.isFormOpened}
          scroll={scroll}
          id='create-event-dialog'
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          onClose={handleClose}
          disableBackdropClick
        >
          <DialogTitle id='customized-dialog-title' onClose={handleClose}>
            Create a New Event
          </DialogTitle>
          <AddEventForm />
        </Dialog>
      </main>
    </>
  );
}
