import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardGrow: {
    display: 'flex',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  chip: {
    marginBottom: theme.spacing(2),
  },
}));
export default function ChipContainer({ chipData, handleChipDelete }) {
  const classes = useStyles();
  return (
    <div className={classes.chipContainer}>
      {chipData.map((data) => (
        <Chip
          color='primary'
          key={data.key}
          label={data.label}
          onDelete={handleChipDelete(data)}
          className={classes.chip}
          size='medium'
        />
      ))}
    </div>
  );
}
