/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description getCardBrands api call
 * @async
 * @method getCardBrands
 * @returns {Object} response object
 */
export async function getCardBrands() {
  const response = await loggedInAxios.get('/card_brands/');
  return response;
}
