/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description Get customers refund requests
 * @async
 * @method getCustomersRefundRequests
 * @param  {JSON} postData
 * @returns {Object} response object
 */
export async function getCustomersRefundRequests(eventId) {
  const response = await loggedInAxios.get(`/refunds/?event_id=${eventId}`);
  return response;
}
