/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/UserContext';

const useStyles = makeStyles({
  card: {
    borderRadius: '1rem',
    width: '90%',
    maskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  header: {
    fontSize: '1.2rem',
    boxShadow: '0 2px 7px -6px black',
    textAlign: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    height: '100%',
    fontSize: '1.2rem',
  },
  balanceTitle: {
    alignSelf: 'center',
  },
  balance: {
    fontWeight: 'bold',
  },
  alignJustifiedCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  alignTextLeft: {
    textAlign: 'start',
  },
  alignTextRight: {
    textAlign: 'end',
  },
});

export default function OverviewCard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userState } = useContext(UserContext);
  const overviewData = {
    [t('overviewCard.customer')]: userState.overview.customer.external_id || '',
    [t('overviewCard.card')]: userState.overview.card_id || '',
    [t('overviewCard.reload')]: userState.overview.loaded_amount || '',
    [t('overviewCard.balance')]: userState.overview.current_balance || '',
    [t('overviewCard.accum-spending')]: userState.overview.account_spending || '',
    [t('overviewCard.undo-reload')]: userState.overview.undo_reload || '',
    [t('overviewCard.undo-card-purchase')]: userState.overview.undo_card_purchase || '',
    [t('overviewCard.bonus')]: userState.overview.bonus || '',
    [t('overviewCard.last-trans')]: userState.overview.last_transaction_time.replace('T', ' ') || '',
    [t('overviewCard.generated')]: userState.overview.generated.replace('T', ' ') || '',
  };

  const membership = userState?.overview?.fest_club ? 'Fest Club Member' : '';

  const getRow = (leftText, rightText) => (
    <div key={leftText} className={classes.alignJustifiedCardContent}>
      <Typography
        variant='body1'
        className={classes.alignTextLeft}
      >
        {leftText}
      </Typography>
      <Typography
        variant='body2'
        className={classes.alignTextRight}
      >
        {rightText}
      </Typography>
    </div>

  );

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.header}
        title={membership}
      />
      <CardContent
        className={classes.cardContent}
      >
        {
          Object.entries(overviewData)
            .map((row) => getRow(row[0], row[1]))
        }
      </CardContent>
    </Card>
  );
}
