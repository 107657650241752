import {
  createMuiTheme, responsiveFontSizes,
} from '@material-ui/core/styles';

const MUIDataTableTheme = createMuiTheme({
  overrides: {
    MuiChip: {
      root: {
        overflow: 'auto',
      },
    },
    MUIDataTableFilter: {
      gridListTile: {
        minWidth: '160px',
      },
    },
    MUIDataTableFilterList: {
      root: {
        marginBottom: '0.5rem',
      },
    },
  },
});

export default responsiveFontSizes(MUIDataTableTheme);
