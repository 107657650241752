/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import {
  Button, Dialog, IconButton, Typography, Box,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { setIsDialogOpened } from '../../../../context/UserActions';
import { UserContext } from '../../../../context/UserContext';
import { sendReloadRequest } from '../../../../api/UserCalls';
import { SnackBarContext } from '../../../../context/SnackBarContext';
import Transition from '../../../common/TransitionComponent/TransitionComponent';
import DialogContent from '../../../common/DialogContent/DialogContent';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '0rem 1rem 1rem 1rem',
  },
  closeButton: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.6rem',
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '0rem 1rem',
    borderRadius: '1rem',
    minWidth: '24rem',
    padding: '1rem 0rem',
    [theme.breakpoints.down('380')]: {
      minWidth: '16rem',
    },
  },
  typography: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    color: '#fff',
    fontSize: '1.2rem',
    borderRadius: '0.7rem',
    minWidth: '9.2rem',
    flex: '0 1',
    margin: '0.5rem',
    [theme.breakpoints.down('380')]: {
      maxWidth: '10rem',
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: '0rem',
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    maxWidth: '22rem',
    justifyContent: 'center',
    [theme.breakpoints.down('380')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  imgHolder: {
    width: '4.7rem',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>{children}</Typography>
      {onClose ? (
        <IconButton id='close-add-money-dialog' aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AddMoneyDialog({ isAddMoneyDialogOpen, setIsAddMoneyDialogOpen, optionsData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { hash } = useParams();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { userDispatch } = useContext(UserContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const { amounts, card_brands, currency } = optionsData;
  const eventCompletedMessage = t('addMoneyDialog.eventCompletedMessage');

  const handleClose = () => {
    setIsAddMoneyDialogOpen(false);
    setIsDialogOpened(false, userDispatch);
  };

  // Device type is required from the payment gateway.
  // In that way it verifies, which payment form to load
  const checkDeviceType = () => (matchesMobileScreen ? 'MOBILE' : 'DESKTOP');

  const sendData = async (e) => {
    const amount = e.currentTarget.value.split(' ')[0];
    const pageView = checkDeviceType();
    const language = localStorage.getItem('i18nextLng');
    const data = {
      amount,
      page_view: pageView,
      language,
    };
    try {
      await sendReloadRequest(hash, data);
    } catch (error) {
      if (error.response.status === 400) {
        openSnackBar('error', `${eventCompletedMessage}`, 'event-completed-popup');
      } else {
        openSnackBar('error', 'Something went wrong!', 'reload-fail-popup');
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isAddMoneyDialogOpen}
      classes={{ paper: classes.paper }}
      TransitionComponent={Transition}
      transitionDuration={300}
    >
      <DialogTitle onClose={handleClose}>
        {t('addMoneyDialog.dialogTitle')}
      </DialogTitle>
      <DialogContent dividers>
        <Typography className={classes.typography}>
          {t('addMoneyDialog.dialogText.firstParagraph')}
        </Typography>
        <div className={classes.buttonsContainer} data-cy='amount-options-container'>
          {amounts?.map((amount) => (
            <Button
              key={amount.id}
              variant='contained'
              color='primary'
              value={amount.value}
              className={classes.button}
              startIcon={<AddIcon style={{ fontSize: '1.5rem', paddingBottom: '0.1rem' }} />}
              onClick={(e) => sendData(e)}
            >
              {amount.value}
              {' '}
              {currency}
            </Button>
          ))}
        </div>
      </DialogContent>
      <Box>
        <Typography
          className={classes.typography}
        >
          {t('addMoneyDialog.dialogText.secondParagraph')}
        </Typography>
        <Box className={classes.imagesContainer} data-cy='cards-images-container'>
          {card_brands?.map((card) => (
            <div className={classes.imgHolder} key={card.id}>
              <img
                className={classes.img}
                src={card.image}
                alt={card.name}
                title={card.name}
              />
            </div>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
}

AddMoneyDialog.propTypes = {
  isAddMoneyDialogOpen: PropTypes.bool.isRequired,
  setIsAddMoneyDialogOpen: PropTypes.func.isRequired,
  optionsData: PropTypes.shape({
    currency: PropTypes.string,
    amounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    })),
    card_brands: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.number,
    })),
  }),
};
