import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import BranchesAccordion from './AccordionStack/AccordionStack';

const useStyles = makeStyles(() => ({
  paperCard: {
    marginBottom: '2rem',
    borderRadius: '0px',
  },
  branchTitle: {
    display: 'flex',
    padding: '1rem',
    paddingLeft: '2rem',
    boxShadow: '0 8px 6px -6px #00000080',
  },
  container: {
    padding: '2rem',
  },
}));

export default function BranchCard({ branch }) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  return (
    <Paper elevation={0} className={classes.paperCard}>
      <Typography
        className={classes.branchTitle}
        variant='h4'
        component='h2'
      >
        {t('staff.branch_name')}
        {': '}
        {branch[0].branch[i18n.language]}
      </Typography>
      <Container className={classes.container}>
        <BranchesAccordion
          terminalsOverview={branch}
        />
      </Container>
    </Paper>
  );
}
