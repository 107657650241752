import React from 'react';
import PropTypes from 'prop-types';
import TerminalsCard from './TerminalsCard';

export default function TerminalsCards({ cardsArray, handleEdit, handleDelete }) {
  return (
    <div>
      {cardsArray.map((card) => (
        <TerminalsCard
          id={card.id}
          externalId={card.external_id}
          nameBG={card.name_bg}
          nameEN={card.name_en}
          state={card.state}
          timeLastTransaction={card.time_last_transaction}
          timeLastVisit={card.time_last_visit}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          key={card.id}
        />
      ))}
    </div>
  );
}

TerminalsCards.propTypes = {
  cardsArray: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
