/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for OwnersContext
 */

export const addStaffOverview = (overview, dispatch) => {
  dispatch({
    type: 'ADD_STAFF_OVERVIEW',
    overview,
  });
};

export const addTerminalsOverview = (terminalsOverview, dispatch) => {
  dispatch({
    type: 'ADD_TERMINALS_OVERVIEW',
    terminalsOverview,
  });
};
