/* eslint-disable camelcase */
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { trackPromise } from 'react-promise-tracker';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import { updateEvent } from '../../../../../../api/EventCalls';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import IOSToggleSwitch from '../../../../../common/IOSToggleSwitch/IOSToggleSwitch';
import currencies from '../../../../../../config/currency';
import { normalizeEventUpdateData } from '../../../../../../utils/normalizationFunctions';

const useStyles = makeStyles(() => ({
  alignToTheSides: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.2rem',
  },
  centerWithPadding: {
    width: '75%',
    marginTop: '4.5rem',
  },
  textField: {
    fontSize: '6rem!important',
    margin: 0,
    width: '55%',
  },
  inputNumbers: {
    maxWidth: '55%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alignButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  iconInButton: {
    marginLeft: '5px',
  },
  rangeSeparator: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  toggle: {
    margin: 0,
    height: '42px',
  },
  textFieldWithToggleSwitch: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '55%',
  },
}));

export default function EditForm({
  eventState,
  setEventState,
  isDisabled,
  setIsDisabled,
  id,
}) {
  const classes = useStyles();

  const { openSnackBar } = useContext(SnackBarContext);

  const handleEditClick = () => {
    setIsDisabled(false);
  };

  const handleSaveClick = async () => {
    const formData = normalizeEventUpdateData(eventState);

    await trackPromise(
      updateEvent(formData, id)
        .then((response) => {
          if (response.status === 202) {
            openSnackBar('warning', `${response.data.details}`, 'update-event-loading-popup');
          } else {
            openSnackBar('success', 'Event updated!', 'update-event-success-popup');
          }
        })
        .catch(() => openSnackBar('error', 'Something went wrong!', 'update-event-fail-popup')),
    );
    setIsDisabled(true);
  };

  const handleNameBGChange = (e) => {
    setEventState({
      ...eventState,
      name_bg: e.target.value,
    });
  };

  const handleNameENChange = (e) => {
    setEventState({
      ...eventState,
      name_en: e.target.value,
    });
  };

  const handleCurrencyChange = (e) => {
    setEventState({
      ...eventState,
      currency: e.target.value,
    });
  };

  const handleStartDateChange = (date) => {
    setEventState({
      ...eventState,
      start_time: date,
    });
  };

  const handleEndDateChange = (date) => {
    setEventState({
      ...eventState,
      end_time: date,
    });
  };

  const handleStartIdChange = (e) => {
    setEventState({
      ...eventState,
      start_customer_id: e.target.value,
    });
  };

  const handleEndIdChange = (e) => {
    setEventState({
      ...eventState,
      end_customer_id: e.target.value,
    });
  };

  const togglePenniesChecked = () => {
    setEventState({
      ...eventState,
      display_pennies: !eventState.display_pennies,
    });
  };

  return (
    <Grid
      className={classes.centerWithPadding}
      justify='center'
      direction='column'
      container
    >
      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          Event Name BG:
        </Typography>
        <TextField
          disabled={isDisabled}
          value={eventState.name_bg}
          onChange={handleNameBGChange}
          className={classes.textField}
        />
      </div>

      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          Event Name EN:
        </Typography>
        <TextField
          disabled={isDisabled}
          value={eventState.name_en}
          className={classes.textField}
          onChange={handleNameENChange}
          id='name_en'
        />
      </div>

      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          Currency:
        </Typography>
        <Select
          disabled={isDisabled}
          value={eventState.currency}
          className={classes.textField}
          onChange={handleCurrencyChange}
          id='currency'
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value} id={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          Display Pennies:
        </Typography>
        <div className={classes.textFieldWithToggleSwitch}>
          <TextField
            disabled={isDisabled}
            value={eventState.display_pennies ? '0.00' : '0'}
            margin='none'
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment inputref="inputmode = 'none'" position='end'>
                  <IOSToggleSwitch
                    className={classes.toggle}
                    checked={eventState.display_pennies}
                    onChange={togglePenniesChecked}
                    disabled={isDisabled}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          Start Time:
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={eventState.start_time}
            disabled={isDisabled}
            onChange={handleStartDateChange}
            className={classes.textField}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          End Time:
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={eventState.end_time}
            disabled={isDisabled}
            onChange={handleEndDateChange}
            className={classes.textField}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className={classes.alignToTheSides}>
        <Typography component='h5' variant='h5'>
          Card Range:
        </Typography>
        <div className={classes.inputNumbers}>
          <TextField
            disabled={isDisabled}
            value={eventState.start_customer_id}
            margin='none'
            type='number'
            onChange={handleStartIdChange}
          />
          <Typography
            className={classes.rangeSeparator}
            component='h5'
            variant='h5'
          >
            :
          </Typography>
          <TextField
            disabled={isDisabled}
            value={eventState.end_customer_id}
            margin='none'
            type='number'
            onChange={handleEndIdChange}
          />
        </div>
      </div>
      <div className={classes.alignButton}>
        {isDisabled ? (
          <Button
            id='edit-event-button'
            onClick={handleEditClick}
            variant='contained'
            color='primary'
          >
            Edit
            <EditIcon className={classes.iconInButton} />
          </Button>
        ) : (
          <Button
            id='save-event-button'
            onClick={handleSaveClick}
            variant='contained'
            color='primary'
          >
            Save
            <SaveIcon className={classes.iconInButton} />
          </Button>
        )}
      </div>
    </Grid>
  );
}
