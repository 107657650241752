import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  // eslint-disable-next-line sort-keys
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/**
 * @description Navigation Bar
 * @method NavigationBar - Admin navigation bar
 * @param  {props} props - all of props from the parent.
 * @returns {'<AppBar>'} <AppBar>
 */
function NavigationBar(props) {
  const classes = useStyles();

  /**
   * @description Logs out user.
   * @method handleLogOut
   */
  function handleLogOut() {
    localStorage.clear();
    props.history.push('/admin');
  }

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
            <MenuIcon />
          </IconButton>
          <Typography component='h6' variant='h6' className={classes.title}>
            Comitex
          </Typography>
          <IconButton edge='end' color='inherit' aria-label='menu' onClick={handleLogOut}>
            <LogOutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(NavigationBar);
