/* eslint-disable import/prefer-default-export */
import React, { createContext, useReducer } from 'react';

const initialState = {
  editGroupId: '',
  groups: [],
  allBranchesForEvent: [],
  availableBranches: [],
  selectedBranches: [],
  groupToEdit: {},
};

export const GroupsContext = createContext(initialState);

const addGroupsData = (state, groups) => ({ ...state, groups });

const removeGroupsData = (state) => ({ ...state, groups: [] });

const addAvailableBranches = (state, branches) => ({ ...state, availableBranches: branches });

const addAllBranchesForEvent = (state, branches) => ({ ...state, allBranchesForEvent: branches });

const addSelectedBranches = (state, branches) => ({ ...state, selectedBranches: branches });

const changeEditGroupId = (state, editGroupId) => ({ ...state, editGroupId });

const addGroupToEdit = (state, group) => ({ ...state, groupToEdit: group });

/**
 * @description Groups Reducer
 * @method groupsReducer
 */
const groupsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_GROUPS_DATA':
      return addGroupsData(state, action.groups);
    case 'REMOVE_GROUPS_DATA':
      return removeGroupsData(state);
    case 'CHANGE_EDIT_GROUP_ID':
      return changeEditGroupId(state, action.editGroupId);
    case 'ADD_AVAILABLE_BRANCHES':
      return addAvailableBranches(state, action.branches);
    case 'ADD_SELECTED_BRANCHES':
      return addSelectedBranches(state, action.branches);
    case 'ADD_ALL_BRANCHES_FOR_EVENT':
      return addAllBranchesForEvent(state, action.branches);
    case 'ADD_GROUP_TO_EDIT':
      return addGroupToEdit(state, action.group);
    default:
      return state;
  }
};

/**
 * @description GroupsContextProvider component
 * @method GroupsContextProvider
 * @returns {'<GroupsContextProvider>'} <GroupsContextProvider>
 */
export const GroupsContextProvider = ({ children }) => {
  const [groupsState, groupsDispatch] = useReducer(groupsReducer, initialState);

  return (
    <GroupsContext.Provider
      value={{ groupsState, groupsDispatch }}
    >
      {children}
    </GroupsContext.Provider>
  );
};
