import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TransferList from '../TransferList/TransferList';
import './AddGroupModalStyles.css';
import { addGroup } from '../../../../../../../api/GroupsCalls';
import { GroupsContext } from '../../../../../../../context/GroupsContext';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    marginTop: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    alignCenter: {
      marginTop: '0.5rem',
      marginBottom: '2rem',
    },
  },
}));

export default function AddGroupModal({
  openDialog,
  setOpenDialog,
  eventId,
}) {
  const classes = useStyles();
  const handleClose = () => {
    setOpenDialog(false);
  };
  const { groupsState } = useContext(GroupsContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const branchesToSelect = groupsState.availableBranches;
  const [nameBG, setNameBG] = useState('');
  const [nameEN, setNameEN] = useState('');
  async function handleAddGroup() {
    const postData = {
      event_id: eventId,
      name_bg: nameBG,
      name_en: nameEN,
      branches: groupsState.selectedBranches.map((branch) => branch.id),
    };
    try {
      await addGroup(postData);
      openSnackBar('success', `Group with name ${nameEN} added.`, 'add-group-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong', 'add-group-fail-popup');
    }
    setNameBG('');
    setNameEN('');
    setOpenDialog(false);
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        scroll='body'
      >
        <DialogTitle id='form-dialog-title'>Add Group</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid className={classes.alignCenter} item md={6} xs={12}>
              <TextField
                margin='dense'
                id='add-group-dialog-name-bg'
                label='Name BG'
                type='text'
                fullWidth
                onChange={(e) => { setNameBG(e.target.value); }}
              />
              <TextField
                margin='dense'
                id='add-group-dialog-name-en'
                label='Name EN'
                type='text'
                fullWidth
                onChange={(e) => { setNameEN(e.target.value); }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TransferList
                leftInit={branchesToSelect}
                rightInit={[]}
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button id='add-group-modal-button' variant='contained' onClick={handleAddGroup} color='primary'>
            Add Group
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
