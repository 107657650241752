import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%!important',
    width: '100%!important',
  },

  paperClass: {
    position: 'absolute',
    height: '50%!important',
    width: '50%!important',
    borderRadius: '3vh!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
  },

  title: {
    color: 'black',
    textAlign: 'center',
  },
  textContainer: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '3px solid rgb(0,0,0)',
    },
    [theme.breakpoints.up('md')]: {
      borderRight: '3px solid rgb(0,0,0)',
    },
  },
}));

export default function Page404() {
  const classes = useStyles();
  return (
    <div className={classes.root} id='no-match'>

      <Paper className={classes.paperClass}>
        <Grid
          container
          spacing={10}
          direction='row'
          justify='center'
          alignItems='center'
        >
          <Grid item className={classes.textContainer}>
            <Typography component='h3' variant='h3' className={classes.title}>
              Ooops!
            </Typography>

          </Grid>
          <Grid item>
            <Typography component='h5' variant='h5' className={classes.title}>
              Page not found! Please contact
              {' '}
              {process.env.REACT_APP_CONTACT_EMAIL}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
