/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for EventsContext
 */

export const addEventsData = (events, dispatch) => {
  dispatch({
    type: 'ADD_EVENTS_DATA',
    events,
  });
};

export const removeEventsData = (dispatch) => {
  dispatch({
    type: 'REMOVE_EVENTS_DATA',
  });
};

export const changeEditEventId = (editEventId, dispatch) => {
  dispatch({
    type: 'CHANGE_EDIT_EVENT_ID',
    editEventId,
  });
};
