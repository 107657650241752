import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  FormLabel,
  TextField,
  FormGroup,
} from '@material-ui/core';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import MUIDataTableTheme from './MUIDataTableTheme/MuiDataTableTheme';
import { getPayments, updatePaymentStatus } from '../../../../../../api/PaymentCalls';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      marginTop: theme.spacing(8),
    },
  },
  select: {
    fontSize: '0.875rem !important',
  },
  buttonsGroup: {
    display: 'flex',
  },
}));

const paymentStatuses = [
  {
    id: 0,
    name: 'AWAITING_PAYMENT',
  },
  {
    id: 1,
    name: 'PAYMENT_FAILED',
  },
  {
    id: 2,
    name: 'UPDATE_BALLANCE_FAILED',
  },
  {
    id: 3,
    name: 'COMPLETED',
  },
  {
    id: 4,
    name: 'FAILED',
  },
];

export default function Payments({ eventId, eventCurrency }) {
  const classes = useStyles();
  const [paymentsTableData, setPaymentsTableData] = useState([]);
  const [currentlyEditingRow, setCurrentlyEditingRow] = useState(null);
  const { openSnackBar } = useContext(SnackBarContext);

  async function handleUpdatePaymentStatus(orderNum, updatedStatus) {
    const patchData = {
      status: updatedStatus,
    };

    try {
      await updatePaymentStatus(orderNum, patchData);
      openSnackBar('success', `Order ${orderNum} was updated successfully.`, 'update-transaction-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong!', 'update-transaction-fail-popup');
    }
  }

  const handleCloseClick = () => setCurrentlyEditingRow(null);

  const handleEditClick = (tableData) => setCurrentlyEditingRow(tableData.rowIndex);

  const handleDoneClick = async (tableData) => {
    setCurrentlyEditingRow(null);

    const status = tableData.rowData[1];
    const orderNumber = tableData.rowData[3];

    await handleUpdatePaymentStatus(orderNumber, status);
  };

  useEffect(() => {
    const getPaymentTransactions = async (id) => {
      try {
        const response = await getPayments(id);
        setPaymentsTableData(response.data);
      } catch (error) {
        openSnackBar('error', 'Something went wrong. Cannot fetch transactions.', 'fetch-transactions-fail-popup');
      }
    };

    getPaymentTransactions(eventId);
  }, [eventId, openSnackBar]);

  const columns = [
    {
      name: 'customer_id',
      label: 'Customer ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        search: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          currentlyEditingRow === tableMeta.rowIndex ? (
            <FormControl>
              <Select
                value={value}
                onChange={(event) => { updateValue(event.target.value); }}
                classes={{
                  root: classes.select,
                  select: classes.select,
                }}
              >
                {paymentStatuses.map((option) => (
                  <MenuItem id={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            value
          )
        ),
      },
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        filter: true,
        sort: true,
        search: false,
        customBodyRender: (value) => `${value} ${eventCurrency}`,
      },
    },
    {
      name: 'order_number',
      label: 'Order Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'order_id',
      label: 'Order ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'date_created',
      label: 'Date Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => format(new Date(value), 'd LLLL yyyy, HH:mm:ss'),
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return `Created - Start: ${v[0]}, End: ${v[1]}`;
            } if (v[0]) {
              return `Created - Start Date: ${v[0]}`;
            } if (v[1]) {
              return `Created - End Date: ${v[1]}`;
            }
            return [];
          },
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            const from = new Date(filters[0]).setHours(0, 0, 0, 0);
            const to = new Date(filters[1]).setHours(23, 59, 59, 59);
            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } if (filters[0] && check >= to) {
              return true;
            } if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <FormLabel>Date Created</FormLabel>
              <FormGroup row>
                <TextField
                  id='startDate'
                  label='Start Date'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id='endDate'
                  label='End Date'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </>
          ),
        },
      },
    },
    {
      name: 'date_updated',
      label: 'Date Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => format(new Date(value), 'd LLLL yyyy, HH:mm:ss'),
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return `Updated - Start: ${v[0]}, End: ${v[1]}`;
            } if (v[0]) {
              return `Start Date: ${v[0]}`;
            } if (v[1]) {
              return `Updated - End Date: ${v[1]}`;
            }
            return [];
          },
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            const from = new Date(filters[0]).setHours(0, 0, 0, 0);
            const to = new Date(filters[1]).setHours(23, 59, 59, 59);
            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } if (filters[0] && check >= to) {
              return true;
            } if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <FormLabel>Date Updated</FormLabel>
              <FormGroup row>
                <TextField
                  id='startDate'
                  label='Start Date'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id='endDate'
                  label='End Date'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </>
          ),
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: true,
        search: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => (
          currentlyEditingRow === tableMeta.rowIndex ? (
            <div className={classes.buttonsGroup}>
              <IconButton onClick={() => handleDoneClick(tableMeta)}>
                <DoneIcon />
              </IconButton>
              <IconButton onClick={() => handleCloseClick()}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <IconButton onClick={() => handleEditClick(tableMeta)}>
              <EditIcon />
            </IconButton>
          )
        ),
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    responsive: 'vertical',
    selectableRows: 'none',
    download: true,
    downloadOptions: {
      filename: 'Payments.csv',
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    viewColumns: true,
    print: false,
    pagination: true,
    rowsPerPageOptions: [10, 30, 50, 100],
    elevation: 1,
  };

  return (
    <div className={classes.content}>
      <Container maxWidth='lg' className={classes.container}>
        <MuiThemeProvider theme={MUIDataTableTheme}>
          <MUIDataTable
            className={classes.root}
            title='Payments'
            data={paymentsTableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Container>
    </div>
  );
}
