import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';
import { updateStaffMember } from '../../../../../../../api/StaffCalls';

export default function EditStaffMemberModal({
  openDialog,
  setOpenDialog,
  staffMemberIDEdit,
  staffMemberToEdit,
  setMemberToEdit,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };
  const { openSnackBar } = useContext(SnackBarContext);
  async function handleEditStaffMember() {
    const patchData = {
      external_id: staffMemberToEdit.external_id,
      name_bg: staffMemberToEdit.name_bg,
      name_en: staffMemberToEdit.name_en,
    };
    try {
      await updateStaffMember(staffMemberIDEdit, patchData);
      openSnackBar('success', `Staff member with id ${staffMemberIDEdit} updated.`, 'update-staff-member-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong', 'edit-staff-member-fail-popup');
    }
    setOpenDialog(false);
  }

  function handleIDChange(event) {
    setMemberToEdit({
      ...staffMemberToEdit,
      external_id: event.target.value,
    });
  }

  function handleNameBGChange(event) {
    setMemberToEdit({
      ...staffMemberToEdit,
      name_bg: event.target.value,
    });
  }

  function handleNameENChange(event) {
    setMemberToEdit({
      ...staffMemberToEdit,
      name_en: event.target.value,
    });
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        scroll='body'
      >
        <DialogTitle id='form-dialog-title'>Edit Member</DialogTitle>
        <DialogContent>
          <Grid style={{ marginBottom: '1rem' }} container display='flex' direction='column'>
            <TextField
              margin='dense'
              id='edit-staff-member-dialog-id'
              label='ID'
              type='text'
              onChange={handleIDChange}
              value={staffMemberToEdit.external_id}
            />
            <TextField
              margin='dense'
              id='edit-staff-member-dialog-name-bg'
              label='Name BG'
              type='text'
              onChange={handleNameBGChange}
              value={staffMemberToEdit.name_bg}
            />
            <TextField
              margin='dense'
              id='edit-staff-member-dialog-name-en'
              label='Name EN'
              type='text'
              onChange={handleNameENChange}
              value={staffMemberToEdit.name_en}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '16px 20px' }}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            id='edit-staff-member-modal-button'
            variant='contained'
            onClick={handleEditStaffMember}
            color='primary'
          >
            Edit Staff
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
