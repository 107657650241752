import axios from 'axios';

// Instance of axios without auth headers
export const loginAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

/**
 * @description login api call
 * @async
 * @method login
 * @param  {Object} credentials - username and password object
 * @returns {Object} response object
 */
export async function login(credentials) {
  const response = await loginAxios.post('/api/token/', credentials);
  const { refresh, access } = response.data;
  localStorage.setItem('access_token', access);
  localStorage.setItem('refresh_token', refresh);
  return response;
}
