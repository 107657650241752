/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSpring, animated as a } from 'react-spring';

export default function CardAnimated({
  card1, card2, flipped, setFlipped,
}) {
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(500px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 300, friction: 40 },
  });

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center' }}
      onClick={() => setFlipped(!flipped)}
    >
      <a.div
        className='card-spinning'
        style={{
          zIndex: 0,
          position: 'absolute',
          opacity: opacity.interpolate((o) => 1 - o),
          transform,
          width: '90%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {card1}
      </a.div>
      <a.div
        className='card-spinning'
        style={{
          zIndex: 0,
          position: 'absolute',
          opacity,
          transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
          width: '90%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {card2}
      </a.div>
    </div>
  );
}
