import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { CreateEventContext } from '../../../../../../context/CreateEventContext';
import { changeStep } from '../../../../../../context/Actions';
import './ColorlibStepIcon.css';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
          'linear-gradient( 136deg, rgb(19,37,132) 0%, rgb(33,55,170) 50%, rgb(94,110,199) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
        'linear-gradient( 136deg, rgb(19,37,132) 0%, rgb(33,55,170) 50%, rgb(94,110,199) 100%)',
  },
});

/**
 * @description ColorlibStepIcon component - used for step icons in Create Event form
 * @method ColorlibStepIcon
 * @returns {'<ColorlibStepIcon>'} <ColorlibStepIcon>
 */
export default function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon } = props;
  const { state, dispatch } = useContext(CreateEventContext);
  function handleStepChange(event) {
    switch (event.target.id) {
      case 'stepBtn1':
        return changeStep(0, dispatch);
      case 'stepBtn2':
        return changeStep(1, dispatch);
      case 'stepBtn3':
        return changeStep(2, dispatch);
      case 'stepBtn4':
        return changeStep(3, dispatch);
      default:
        return changeStep(0, dispatch);
    }
  }
  const icons = {
    1:
  <IconButton
    id='stepBtn1'
    color='inherit'
    onClick={handleStepChange}
    disableRipple
    elementtype='button'
  >
    <EventIcon />
  </IconButton>,
    2:
  <IconButton
    id='stepBtn2'
    color='inherit'
    onClick={handleStepChange}
  >
    <PeopleIcon />
  </IconButton>,
    3:
  <IconButton
    id='stepBtn3'
    color='inherit'
    disabled={state.isNextButtonDisabled}
    onClick={handleStepChange}
  >
    <StorageIcon />
  </IconButton>,
    4:
  <IconButton
    id='stepBtn4'
    color='inherit'
    onClick={handleStepChange}
  >
    <CheckIcon />
  </IconButton>,
  };

  return (
    <div
      id='add-event-form-step-icons'
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
