import React, { useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { login } from '../../../../api/LoginCalls';
import { SnackBarContext } from '../../../../context/SnackBarContext';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * @description Login page compponent
 * @function Login
 * @param  {props} props - all of props from the parent.
 * @returns {'<Login>'} - Login page
 */
export default function Login(props) {
  const image = {
    // eslint-disable-next-line global-require
    avatar: require('../../../common/Images/comitex.png'),
  };
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { openSnackBar, snackState } = useContext(SnackBarContext);

  /**
     * @description Sets the username in local state
     * @function handleUsernameInputChange
     * @param  {event} event - event of typing in the input of username field
     */
  const handleUsernameInputChange = (event) => {
    setUsername(event.target.value);
  };

  /**
     * @description Sets the password in local state
     * @function handlePasswordInputChange
     * @param  {event} event - event of typing in the input of password field
     */
  const handlePasswordInputChange = (event) => {
    setPassword(event.target.value);
  };

  /**
     * @description Handles the login submit and changes the route
     * @function handleFormSubmit
     * @param  {event} event - event of submitting the login form
     */
  async function handleFormSubmit(event) {
    event.preventDefault();
    const loginForm = {
      username,
      password,
    };
    if (loginForm !== null) {
      try {
        await login(loginForm);
        props.history.push('/events');
        openSnackBar('success', 'Login success.', 'login-success');
      } catch (error) {
        if (error?.response?.status === 401) {
          openSnackBar('error', 'Ups! Wrong credentials.', 'login-fail-popup');
        }
      }
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar alt='Comitex Logo' src={image.avatar} className={classes.bigAvatar} />
        <Typography component='h1' variant='h5'>
          Comitex Sign in
        </Typography>
        <form id='login-form' className={classes.form} onSubmit={handleFormSubmit}>
          <TextField
            component='span'
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='username'
            label='User Name'
            name='username'
            autoComplete='username'
            autoFocus
            onChange={handleUsernameInputChange}
            error={snackState.isPopUpOppened}
          />
          <TextField
            component='span'
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={handlePasswordInputChange}
            error={snackState.isPopUpOppened}
          />
          <Button
            id='login-submit-btn'
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}
