import React from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import './StaffLanding.css';
import Navigation from './Navigation/Navigation';
import Overview from './Components/Overview/Overview';
import TerminalsOverview from './Components/TerminalsOverview/TerminalsOverview';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  baseDetails: {
    marginTop: '20rem',
  },
}));

export default function StaffLanding() {
  const classes = useStyles();
  const currentURL = useLocation();
  const { hash } = useParams();
  const history = useHistory();
  function getComponentsByPath() {
    switch (currentURL.pathname) {
      case `/staff/${hash}`:
        return (
          <Overview />
        );
      case `/staff/${hash}/terminals`:
        return (
          <TerminalsOverview />
        );
      default:
        return history.push('/404');
    }
  }
  return (
    <div className={classes.root}>
      <Navigation />
      {getComponentsByPath()}
    </div>
  );
}
