/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, IconButton, Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { setIsDialogOpened } from '../../../../context/UserActions';
import { UserContext } from '../../../../context/UserContext';
import { sendRefundRequest } from '../../../../api/UserCalls';
import { SnackBarContext } from '../../../../context/SnackBarContext';
import Transition from '../../../common/TransitionComponent/TransitionComponent';
import DialogContent from '../../../common/DialogContent/DialogContent';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '0rem 1rem 1rem 1rem',
    [theme.breakpoints.down('315')]: {
      display: 'flex',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.6rem',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('315')]: {
      position: 'static',
      height: 'fit-content',
    },
  },
  dialogActions: {
    padding: '0rem 1rem',
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '0rem 1rem',
    borderRadius: '1rem',
    maxWidth: '24rem',
    padding: '1rem 0rem',
    [theme.breakpoints.down('380')]: {
      minWidth: '16rem',
    },
  },
  typography: {
    textAlign: 'left',
  },
  button: {
    color: '#fff',
    fontSize: '1.2rem',
    borderRadius: '0.7rem',
    [theme.breakpoints.down('380')]: {
      maxWidth: '10rem',
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: '0rem',
      },
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function RefundMoneyDialog({ isRefundDialogOpen, setRefundDialogOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hash } = useParams();
  const { userDispatch } = useContext(UserContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const successRefundMessage = t('refundMoneyDialog.messages.success');
  const commonErrorMessage = t('refundMoneyDialog.messages.error');
  const repeatedRequestMessage = t('refundMoneyDialog.messages.info');
  const requireLoadMessage = t('refundMoneyDialog.messages.requireLoad');

  const handleClose = () => {
    setRefundDialogOpen(false);
    setIsDialogOpened(false, userDispatch);
  };

  const handleError = (error) => {
    switch (error?.response?.status) {
      case 409:
        return openSnackBar('info', `${repeatedRequestMessage}`, 'refund-already-sent-popup');
      case 402:
        return openSnackBar('info', `${requireLoadMessage}`, 'require-load-to-refund-popup');
      default:
        return openSnackBar('error', `${commonErrorMessage}`, 'refund-fail-popup');
    }
  };

  const requestRefund = async () => {
    try {
      await sendRefundRequest(hash);
      openSnackBar('success', `${successRefundMessage}`, 'success-refund-popup');
      setRefundDialogOpen(false);
      setIsDialogOpened(false, userDispatch);
    } catch (error) {
      handleError(error);
      setRefundDialogOpen(false);
      setIsDialogOpened(false, userDispatch);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isRefundDialogOpen}
      classes={{ paper: classes.paper }}
      TransitionComponent={Transition}
      transitionDuration={300}
      data-cy='refund-money-dialog'
    >
      <DialogTitle onClose={handleClose}>
        {t('refundMoneyDialog.dialogTitle')}
      </DialogTitle>
      <DialogContent dividers>
        <Typography className={classes.typography}>
          {t('refundMoneyDialog.dialogText')}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0rem 1rem' }}>
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={requestRefund}
          data-cy='refund-button'
        >
          {t('refundMoneyDialog.refundButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RefundMoneyDialog.propTypes = {
  isRefundDialogOpen: PropTypes.bool.isRequired,
  setRefundDialogOpen: PropTypes.func.isRequired,
};
