/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description Create event api call
 * @async
 * @method createEvent
 * @param  {Object} formData - FormData object
 * @returns {Object} response object
 */
export async function createEvent(formData) {
  const response = await loggedInAxios.post('/events/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response;
}

/**
 * @description Get events api call
 * @async
 * @method getEvents
 * @returns {Object} response object
 */
export async function getEvents() {
  const response = await loggedInAxios.get('/events/');
  return response;
}

/**
 * @description Get single event api call
 * @async
 * @method getEvent
 * @returns {Object} response object
 */
export async function getEvent(id) {
  const response = await loggedInAxios.get(`/events/${id}`);
  return response;
}

/**
 * @description Delete event api call
 * @async
 * @method deleteEvent
 * @returns {Object} response object
 */
export async function deleteEvent(id) {
  const response = await loggedInAxios.delete(`/events/${id}`);
  return response;
}

/**
 * @description Update event api call
 * @async
 * @method updateEvent
 * @returns {Object} response object
 */
export async function updateEvent(formData, id) {
  const response = await loggedInAxios.put(`/events/${id}/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response;
}

/**
 * @description Update event database api call
 * @async
 * @method updateEvent
 * @returns {Object} response object
 */
export async function updateDatabaseEvent(formData, id) {
  const response = await loggedInAxios.patch(`/events/${id}/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response;
}

/**
 * @description Download archive with QR codes event api call
 * @async
 * @method downloadQRCodesArchive
 * @returns {Object} response object
 */
export async function downloadQRCodesArchive(eventId) {
  const response = await loggedInAxios.get(`/events/${eventId}/archive`, {
    responseType: 'arraybuffer',
  });
  return response;
}

/**
 * @description Download CSV file with QR codes - event api call
 * @async
 * @method downloadQRCodesCSV
 * @returns {Object} response object
 */
export async function downloadQRCodesCSV(eventId) {
  const response = await loggedInAxios.get(`/events/${eventId}/qr_code`, {
    responseType: 'arraybuffer',
  });
  return response;
}

/**
 * @description Refetch all transactions - event api call
 * @async
 * @method getAllTransactionsJSON
 * @returns {Object} response object
 */
export async function refetchAllTransactions(eventId) {
  const response = await loggedInAxios.get(`/events/${eventId}/refetch`);
  return response;
}
