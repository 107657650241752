/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper, Stepper, Step, StepLabel, Button, Typography, StepContent,
} from '@material-ui/core';
import { trackPromise } from 'react-promise-tracker';
import { format } from 'date-fns';
import EventGeneralInformationStep from './Steps/EventGeneralInformationStep';
import CustomerIDsAndNamesStep from './Steps/CustomerIDsAndNamesStep';
import DBConfigStep from './Steps/DBConfigStep';
import ReviewStep from './Steps/ReviewStep';
import { CreateEventContext } from '../../../../../context/CreateEventContext';
import { changeStep, closeForm } from '../../../../../context/Actions';
import ColorlibStepIcon from './Colorlib/ColorlibStepIcon';
import { createEvent, getEvents } from '../../../../../api/EventCalls';
import { addEventsData } from '../../../../../context/EventsActions';
import { SnackBarContext } from '../../../../../context/SnackBarContext';
import { EventsContext } from '../../../../../context/EventsContext';
import { normalizeEventCreationData } from '../../../../../utils/normalizationFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  layout: {
    width: 'auto',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
}));

const steps = ['Event General Information', 'Customer IDs and Files', 'Database Configuration', 'Event Creation Review'];

/**
 * @description AddEventForm component - used for creating the event
 * @method AddEventForm
 * @returns {'<AddEventForm>'} <AddEventForm>
 */
export default function AddEventForm() {
  const classes = useStyles();
  const { state, dispatch } = useContext(CreateEventContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const { eventsDispatch } = useContext(EventsContext);
  const [eventGeneralInfo, setEventGeneralInfo] = useState({
    name_bg: '',
    name_en: '',
    currency: '',
    display_pennies: false,
    start_time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    end_time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
  });

  const [customerIDsAndNames, setCustomerIDsAndNames] = useState({
    start_customer_id: '',
    end_customer_id: '',
    map_photo: '',
    event_photo: '',
    not_active_image: '',
    subranges: [],
  });

  const [dBConfig, setDBConfig] = useState({
    db_host: '',
    db_password: '',
    db_name: '',
    db_port: '',
    db_user: '',
  });

  /**
 * @description Used for getting the steps in Step form
 * @method getStepContent
 * @returns {Step}
 */
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <EventGeneralInformationStep
            eventGeneralInfo={eventGeneralInfo}
            setEventGeneralInfo={setEventGeneralInfo}
          />
        );
      case 1:
        return (
          <CustomerIDsAndNamesStep
            customerIDsAndNames={customerIDsAndNames}
            setCustomerIDsAndNames={setCustomerIDsAndNames}
          />
        );
      case 2:
        return <DBConfigStep dBConfig={dBConfig} setDBConfig={setDBConfig} />;
      case 3:
        return (
          <ReviewStep
            eventGeneralInfo={eventGeneralInfo}
            customerIDsAndNames={customerIDsAndNames}
            dBConfig={dBConfig}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    changeStep(state.step + 1, dispatch);
  };

  const handleBack = () => {
    changeStep(state.step - 1, dispatch);
  };

  async function handleEventCreation(event) {
    event.preventDefault();
    const formData = normalizeEventCreationData(eventGeneralInfo, customerIDsAndNames, dBConfig);
    await trackPromise(
      createEvent(formData)
        .then(openSnackBar('success', 'Event creation started...', 'create-event-success-popup'))
        .catch((error) => {
          if (error.response.status === 400) {
            openSnackBar('error', 'Something is wrong with inputs.', 'create-event-fail-popup');
          }
        }),
    );
    await trackPromise(
      getEvents()
        .then((events) => {
          addEventsData(events.data.reverse(), eventsDispatch);
        })
        .catch(() => {
          openSnackBar('error', 'Event list not updated!.', 'refresh-event-fail-popup');
        }),
    );
    closeForm(dispatch);
  }
  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Stepper activeStep={state.step} orientation='vertical'>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              <StepContent>
                <Typography component='span' variant='body2'>
                  {getStepContent(index)}
                </Typography>
                <div className={classes.actionsContainer}>
                  <Button
                    disabled={state.step === 0}
                    onClick={handleBack}
                    className={classes.button}
                    id='form-back-step'
                  >
                    Back
                  </Button>
                  {
                    state.step === steps.length - 1
                      ? (
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleEventCreation}
                          className={classes.button}
                          id='create-event-button'
                        >
                          Create Event
                        </Button>
                      )
                      : (
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={state.isNextButtonDisabled}
                          onClick={handleNext}
                          className={classes.button}
                          id='form-next-step'
                        >
                          Next
                        </Button>
                      )
                  }
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </div>
  );
}
