import React from 'react';
import BranchesCard from './BranchesCard';

export default function BranchesCards({ branchesOverview }) {
  return (
    <>
      {branchesOverview.map((singleBranch) => (
        <BranchesCard
          singleBranch={singleBranch}
          key={singleBranch.branch.external_id}
        />
      ))}
    </>
  );
}
