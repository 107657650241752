import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { getDateWithHour } from '../../../../../../../utils/utils';

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0.5rem',
  },
  datePickProvider: {
    padding: '1rem',
  },
  rootStyle: {
    borderRadius: 4,
  },
  filterButton: {
    marginTop: '2rem',
    borderRadius: 10,
  },
});

function FilterByDateDialog({
  onClose,
  open,
  filterHandler,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dates, setDates] = useState({
    start: getDateWithHour(0, 0, 0),
    end: getDateWithHour(23, 59, 59),
  });

  const handleFilterButtonClick = () => {
    filterHandler(dates.start, dates.end);
  };

  const handleStartDateChange = (date) => {
    setDates({
      ...dates,
      start: date,
    });
  };

  const handleEndDateChange = (date) => {
    setDates({
      ...dates,
      end: date,
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='date-filter-dialog'
      open={open}
      classes={{
        paper: classes.rootStyle,
      }}
    >
      <DialogTitle>
        {t('filterByDateDialog.dialogTitle')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography component='span' variant='body2'>
          {t('filterByDateDialog.start-time')}
        </Typography>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          className={classes.datePickProvider}
        >
          <DateTimePicker
            onChange={handleStartDateChange}
            value={dates.start}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
        <Typography style={{ marginTop: '2rem' }} component='span' variant='body2'>
          {t('filterByDateDialog.end-time')}
        </Typography>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
        >
          <DateTimePicker
            onChange={handleEndDateChange}
            value={dates.end}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
        <Button
          onClick={handleFilterButtonClick}
          className={classes.filterButton}
          variant='contained'
          color='primary'
        >
          {t('filterByDateDialog.filter-button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default function FilterDialog({
  isFilterDialogOpened,
  setIsFilterDialogOpened,
  filterHandler,
}) {
  const handleClose = () => {
    setIsFilterDialogOpened(false);
  };

  return (
    <FilterByDateDialog
      onClose={handleClose}
      open={isFilterDialogOpened}
      filterHandler={filterHandler}
    />
  );
}
