/* eslint-disable no-use-before-define */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Language as LanguageIcon, Menu as MenuIcon } from '@material-ui/icons';
import './UserLanding.css';
import BalanceOverviewCard from './BalanceOverviewCard/BalanceOverviewCard';
import OverviewCard from './OverviewCard/OverviewCard';
import MenuDialog from './MenuDialog/MenuDialog';
import CardAnimated from './CardAnimated/CardAnimated';
import { UserContext } from '../../../../context/UserContext';
import { getOverview, getUserSubscription, getReloadOptions } from '../../../../api/UserCalls';
import {
  addUserData,
  setSubscribedToViber,
  setIsDialogOpened,
  setIsFirstVisit,
} from '../../../../context/UserActions';
import { SnackBarContext } from '../../../../context/SnackBarContext';
import TransactionsDialog from '../TransactionsDialog/TransactionsDialog';
import MapDialog from '../MapDialog/MapDialog';
import AddMoneyDialog from '../AddMoneyDialog/AddMoneyDialog';
import RefundMoneyDialog from '../RefundMoneyDialog/RefundMoneyDialog';
import LanguageMenuDialog from './LanguageMenuDialog/LanguageMenuDialog';
import ViberSubscriptionDialog from '../ViberSubscriptionDialog/ViberSubscriptionDialog';
import ChangeNotificationsLanguageDialog from '../ChangeNotificationsLanguageDialog/ChangeNotificationsLanguageDialog';
import { setBackgroundImage } from '../../../../utils/setBackgroundImage';

const useStyles = makeStyles((theme) => ({
  landingBackground: {
    width: '100%',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    color: 'white',
    textShadow: '0 4px 3px rgba(0, 0, 0, 0.4)',
    maxWidth: 'calc(100vw - 50px)',
    [theme.breakpoints.down(281)]: {
      fontSize: '2rem',
    },
  },
  smallDevicesTitle: {
    [theme.breakpoints.down(321)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(281)]: {
      fontSize: '0.86rem',
    },
  },
  alignCenterHorizotally: {
    display: 'flex',
    justifyContent: 'center',
  },
  appBar: {
    zIndex: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0.5rem 0rem',
    marginBottom: '2rem',
  },
  eventTitle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alignVertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default function UserLanding() {
  const history = useHistory();
  const classes = useStyles();
  const [flipped, setFlipped] = useState(false);
  const { hash } = useParams();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { userState, userDispatch } = useContext(UserContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const [picture, setPicture] = useState('');
  const [isTransactionsDialogOpen, setIsTransactionsDialogOpen] = useState(false);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [isAddMoneyDialogOpen, setIsAddMoneyDialogOpen] = useState(false);
  const [isRefundMoneyDialogOpen, setIsRefundMoneyDialogOpen] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState(false);
  const [isChangeLanguageDialogOpen, setIsChangeLanguageDialogOpen] = useState(false);
  const [notificationsLanguage, setNotificationsLanguage] = useState('');
  const [reloadOptionsData, setReloadOptionsData] = useState({});
  const isSubscribed = useRef(userState.isSubscribedToViber);
  const snackbarMessage = t('addMoneyDialog.snackbarMessage');
  const queryParam = location.search.split('=');
  const statusFromUrl = queryParam[1];

  const language = localStorage.getItem('i18nextLng');
  const successTransactionMessage = language === 'bg' ? 'Транзакцията е успешна.' : 'Transaction was successful.';

  useEffect(() => {
    if (statusFromUrl === 'success') {
      openSnackBar('success', `${successTransactionMessage}`, 'transaction-success-popup');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const eventEndTime = Date.parse(userState?.overview?.event_end_time);
    const today = Date.parse(new Date());
    if (eventEndTime) {
      setIsSubscriptionDialogOpen(userState.isFirstVisit && eventEndTime >= today);
    }
    async function addData() {
      try {
        const userData = await getOverview(hash);
        addUserData(userData?.data, userDispatch);
        setPicture(userData?.data.event_picture);
        setBackgroundImage(
          picture,
          'cover',
          '#00468E',
          'center',
          'multiply',
        );
      } catch (error) {
        if (error.response.status === 400) {
          if (error.response.data.not_active_image) history.push(`/users/${hash}/event-not-active`);
          else {
            openSnackBar(
              'error',
              'Something went wrong.',
              'get-users-overview-fail-popup',
            );
          }
        } else {
          history.push('/404');
        }
      }
    }

    addData();
  }, [hash, history, picture, userDispatch, userState.isFirstVisit, userState.overview.event_end_time, openSnackBar]);

  const checkIfUserIsSubscribedToViber = useCallback(async () => {
    try {
      const response = await getUserSubscription(hash);
      setSubscribedToViber(response.data.subscribed, userDispatch);
      setNotificationsLanguage(response.data.language);
    } catch (error) {
      setSubscribedToViber(false, userDispatch);
    }
  }, [hash, userDispatch]);

  useEffect(() => {
    checkIfUserIsSubscribedToViber();
    isSubscribed.current = userState.isSubscribedToViber;
  }, [checkIfUserIsSubscribedToViber, userState.isSubscribedToViber]);

  const openViberDialog = (isUserSubscribed) => {
    if (isUserSubscribed) {
      setIsChangeLanguageDialogOpen(true);
    } else {
      setIsSubscriptionDialogOpen(true);
    }
  };

  useEffect(() => {
    const getReloadOptionsData = async () => {
      try {
        const response = await getReloadOptions(hash);
        setReloadOptionsData(response.data[0]);
      } catch (error) {
        setIsAddMoneyDialogOpen(!isAddMoneyDialogOpen);
        openSnackBar('error', `${snackbarMessage}`, 'reload-options-fail-popup');
        setIsDialogOpened(false, userDispatch);
      }
    };
    if (isAddMoneyDialogOpen) {
      getReloadOptionsData();
    }
  }, [isAddMoneyDialogOpen, openSnackBar, snackbarMessage, userDispatch, hash]);

  const handleNotificationsClick = async () => {
    await checkIfUserIsSubscribedToViber();
    setIsDialogOpened(true, userDispatch);
    openViberDialog(isSubscribed.current);
  };

  const handleAddMoneyClick = () => {
    setIsAddMoneyDialogOpen(true);
    setIsDialogOpened(true, userDispatch);
  };

  const handleRefundMoneyClick = () => {
    setIsRefundMoneyDialogOpen(true);
    setIsDialogOpened(true, userDispatch);
  };

  const openMenu = () => {
    setCardToFlipBack(
      <MenuDialog
        setCardToFlipFront={setCardToFlipFront}
        setCardToFlipBack={setCardToFlipBack}
        setIsTransactionsDialogOpen={setIsTransactionsDialogOpen}
        setIsMapDialogOpen={setIsMapDialogOpen}
        handleAddMoneyClick={handleAddMoneyClick}
        handleRefundMoneyClick={handleRefundMoneyClick}
        handleNotificationsClick={handleNotificationsClick}
      />,
    );
    setCardToFlipFront(
      <MenuDialog
        setCardToFlipFront={setCardToFlipFront}
        setCardToFlipBack={setCardToFlipBack}
        setIsTransactionsDialogOpen={setIsTransactionsDialogOpen}
        setIsMapDialogOpen={setIsMapDialogOpen}
        handleAddMoneyClick={handleAddMoneyClick}
        handleRefundMoneyClick={handleRefundMoneyClick}
        handleNotificationsClick={handleNotificationsClick}
      />,
    );

    setIsFirstVisit(false, userDispatch);
    setFlipped(!flipped);
  };

  const [cardToFlipFront, setCardToFlipFront] = useState(
    <BalanceOverviewCard />,
  );
  const [cardToFlipBack, setCardToFlipBack] = useState(<OverviewCard />);

  const openLanguageMenu = () => {
    setCardToFlipBack(
      <LanguageMenuDialog
        setCardToFlipFront={setCardToFlipFront}
        setCardToFlipBack={setCardToFlipBack}
        setFlipped={setFlipped}
        flipped={flipped}
      />,
    );
    setCardToFlipFront(
      <LanguageMenuDialog
        setCardToFlipFront={setCardToFlipFront}
        setCardToFlipBack={setCardToFlipBack}
        setFlipped={setFlipped}
        flipped={flipped}
      />,
    );
    setFlipped(!flipped);
  };

  return (
    <>
      <div className={classes.landingBackground}>
        <AppBar className={classes.appBar} position='static'>
          <Toolbar className={classes.toolbar}>
            <IconButton
              onClick={openMenu}
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              data-cy='menu-button'
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' className={clsx(classes.title, classes.smallDevicesTitle)}>
              FestWallet Payment System
            </Typography>
            <IconButton
              onClick={openLanguageMenu}
              edge='end'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
            >
              <LanguageIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          id='event-title'
          className={classes.eventTitle}
        >
          <div className={classes.alignCenterHorizotally}>
            <Typography className={classes.title} variant='h3' component='h1'>
              {userState.overview.event[i18n.language]}
            </Typography>
          </div>
        </Box>
        <Box
          position='relative'
        >
          {!userState.isDialogOpened && (
            <CardAnimated
              style={{ alignSelf: 'center' }}
              card1={cardToFlipFront}
              card2={cardToFlipBack}
              flipped={flipped}
              setFlipped={setFlipped}
            />
          )}
        </Box>
      </div>
      <TransactionsDialog
        isOpen={isTransactionsDialogOpen}
        setIsOpen={setIsTransactionsDialogOpen}
      />
      <AddMoneyDialog
        isAddMoneyDialogOpen={isAddMoneyDialogOpen}
        setIsAddMoneyDialogOpen={setIsAddMoneyDialogOpen}
        optionsData={reloadOptionsData}
      />
      <RefundMoneyDialog
        isRefundDialogOpen={isRefundMoneyDialogOpen}
        setRefundDialogOpen={setIsRefundMoneyDialogOpen}
      />
      <MapDialog isOpen={isMapDialogOpen} setIsOpen={setIsMapDialogOpen} />
      <ChangeNotificationsLanguageDialog
        isOpen={isChangeLanguageDialogOpen}
        setIsOpen={setIsChangeLanguageDialogOpen}
        notificationsLanguage={notificationsLanguage}
        setNotificationsLanguage={setNotificationsLanguage}
      />
      <ViberSubscriptionDialog
        isOpen={isSubscriptionDialogOpen}
        setIsOpen={setIsSubscriptionDialogOpen}
      />
    </>
  );
}
