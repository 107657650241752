import React, { createContext, useState } from 'react';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

/**
 * @file DialogContext - context used for Dialog which can be used for everything
 */

const initialState = {
  isDialogOpened: false,
  buttonVariant: 'error',
  buttonMessage: 'example',
  dialogMessage: 'example',
  dialogTitle: 'example',
  dialogId: 'example',
  handleClickFunction: () => {},
};

const useStyles = makeStyles((theme) => ({
  success: {
    '&:hover': {
      backgroundColor: green[600],
      color: 'white',
    },
  },
  error: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    },
  },
  info: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  warning: {
    '&:hover': {
      backgroundColor: amber[700],
      color: 'white',
    },
  },
}));

export const DialogContext = createContext(initialState);

/**
 * @description DialogProvider component
 * @method DialogContext
 * @returns {'<DialogProvider>'} <DialogProvider>
 */
export const DialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState(initialState);
  const classes = useStyles();

  function closeDialog() {
    setDialogState({ ...dialogState, isDialogOpened: false });
  }

  function openDialog(
    buttonVariant,
    buttonMessage,
    dialogMessage,
    dialogTitle,
    buttonId,
    handleClickFunction,
  ) {
    setDialogState({
      isDialogOpened: true,
      buttonVariant,
      buttonMessage,
      dialogMessage,
      dialogTitle,
      buttonId,
      handleClickFunction,
    });
  }

  return (
    <DialogContext.Provider
      value={{ openDialog, dialogState, closeDialog }}
    >
      <Dialog
        open={dialogState.isDialogOpened}
        onClose={closeDialog}
      >
        <DialogTitle>
          {dialogState.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogState.dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='primary' autoFocus>
            Cancel
          </Button>
          <Button
            onClick={dialogState.handleClickFunction}
            className={clsx(classes[dialogState.buttonVariant])}
            id={dialogState.buttonId}
          >
            {dialogState.buttonMessage}
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};
