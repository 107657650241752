import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Image from './Vector2.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
  },
  fixedHeight: {
    height: 559,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
    flexDirection: 'row',
  },
}));

export default function StyledPaper(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Paper {...props} className={fixedHeightPaper} />
  );
}
