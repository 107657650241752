import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TransferList from '../TransferList/TransferList';
import './EditGroupModalStyles.css';
import { updateGroup } from '../../../../../../../api/GroupsCalls';
import { GroupsContext } from '../../../../../../../context/GroupsContext';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    marginTop: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    alignCenter: {
      marginTop: '0.5rem',
      marginBottom: '2rem',
    },
  },
}));

export default function EditGroupModal({
  openDialog,
  setOpenDialog,
  eventId,
}) {
  const classes = useStyles();
  const { groupsState } = useContext(GroupsContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const branchesToSelect = groupsState.availableBranches;
  const branchesSelected = groupsState.selectedBranches;
  const initialState = groupsState.groupToEdit;
  const [groupState, setGroupState] = useState(initialState);

  useEffect(() => {
    setGroupState(groupsState.groupToEdit);
  }, [groupsState.groupToEdit]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  async function handleEditGroup() {
    const groupId = groupsState.editGroupId;
    const postData = {
      event_id: eventId,
      name_bg: groupState.name_bg,
      name_en: groupState.name_en,
      branches: groupsState.selectedBranches.map((branch) => branch.id),
    };
    try {
      await updateGroup(groupId, postData);
      openSnackBar('success', `Group with ID ${groupId} updated.`, 'update-group-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong', 'update-group-fail-popup');
    }
    setGroupState(initialState);
    setOpenDialog(false);
  }

  function handleNameBGChange(event) {
    setGroupState({
      ...groupState,
      name_bg: event.target.value,
    });
  }

  function handleNameENChange(event) {
    setGroupState({
      ...groupState,
      name_en: event.target.value,
    });
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        scroll='body'
      >
        <DialogTitle id='form-dialog-title'>Edit Group</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid className={classes.alignCenter} item md={6} xs={12} style={{ marginTop: '2rem' }}>
              <TextField
                margin='dense'
                id='add-group-dialog-name-bg'
                label='Name BG'
                type='text'
                fullWidth
                onChange={handleNameBGChange}
                value={groupState.name_bg}
              />
              <TextField
                margin='dense'
                id='add-group-dialog-name-en'
                label='Name EN'
                type='text'
                fullWidth
                onChange={handleNameENChange}
                value={groupState.name_en}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TransferList
                leftInit={branchesToSelect}
                rightInit={branchesSelected}
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button id='edit-group-modal-button' onClick={handleEditGroup} variant='contained' color='primary'>
            Edit Group
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
