import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import TerminalCard from './TerminalCard/TerminalCard';

const useStyles = makeStyles(() => ({
  paperCard: {
    marginBottom: '2rem',
    maskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
  branchTitle: {
    display: 'flex',
    padding: '1rem',
    paddingLeft: '2rem',
    boxShadow: '0 8px 6px -6px #00000080',
  },
  container: {
    padding: '1rem',
  },
}));

export default function BranchCard({ branch }) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  return (
    <Paper
      className={classes.paperCard}
    >
      <Typography
        className={classes.branchTitle}
        variant='h4'
        component='h2'
      >
        {t('owners.branch_name')}
        {': '}
        {branch[0].branch[i18n.language]}
      </Typography>
      <Container className={classes.container}>
        {
          branch.map((terminal) => (
            <TerminalCard
              singleTerminal={terminal}
              key={terminal.name.external_id}
            />
          ))
      }
      </Container>
    </Paper>
  );
}
