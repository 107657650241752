import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { format } from 'date-fns';
import AccordionStack from './AccordionStack/AccordionStack';
import { getBranchesOverview, getFilteredBranchesOverview } from '../../../../../../api/OwnersCalls';
import { addBranchesOverview } from '../../../../../../context/OwnersActions';
import { OwnersContext } from '../../../../../../context/OwnersContext';
import FilterByDateDialog from '../common/FilterByDateDialog/FilterByDateDialog';
import CustomSpeedDial from '../common/CustomSpeedDial/CustomSpeedDial';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import ChipContainer from '../common/ChipContainer/ChipContainer';
import BranchesCards from './BranchesCards/BranchesCards';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
    borderRadius: '0px',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
}));

export default function BranchesOverview() {
  const classes = useStyles();
  const { ownersState, ownersDispatch } = useContext(OwnersContext);
  const { hash } = useParams();
  const { openSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [chipData, setChipData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterDialogOpened, setIsFilterDialogOpened] = useState(false);

  const filterByDateHandler = async (startDate, endDate) => {
    const start = format(Date.parse(startDate), "yyyy-MM-dd'T'HH:mm:ss");
    const end = format(Date.parse(endDate), "yyyy-MM-dd'T'HH:mm:ss");
    try {
      const filteredBranches = await getFilteredBranchesOverview(hash, start, end);
      addBranchesOverview(filteredBranches.data, ownersDispatch);
      setIsFilterApplied(true);
      setIsFilterDialogOpened(false);
    } catch (error) {
      openSnackBar('error', 'Failed to filter branches transactions.', 'filter-transactions-branches-fail-popup');
    }
  };

  const speedDialActions = [
    {
      icon: <DateRangeIcon />,
      name: 'Filter By Date',
      id: 'filter-by-date',
      handler: () => { setIsFilterDialogOpened(true); },
    },
  ];

  const handleChipDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    if (chipToDelete.label === t('transactionsDialog.chips.dateFilter')) {
      setIsFilterApplied(false);
    }
  };

  useEffect(
    () => {
      if (isFilterApplied) {
        setChipData((c) => [...c, {
          key: 0,
          label: t('transactionsDialog.chips.dateFilter'),
        }]);
      }
    },
    [isFilterApplied, t],
  );

  useEffect(() => {
    async function addData() {
      try {
        if (!isFilterApplied) {
          const branchesOverview = await getBranchesOverview(hash);
          addBranchesOverview(branchesOverview.data, ownersDispatch);
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          if (error.response.data.not_active_image) history.push(`/owners/${hash}/event-not-active`);
          else {
            openSnackBar(
              'error',
              'Something went wrong.',
              'get-branches-overview-fail-popup',
            );
          }
        } else {
          history.push('/404');
        }
      }
    }
    addData();
  }, [history, isFilterApplied, hash, ownersDispatch, openSnackBar]);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg' className={classes.container}>
          <ChipContainer
            className={classes.chipContainer}
            chipData={chipData}
            handleChipDelete={handleChipDelete}
          />
          <Grid container spacing={3} direction='row' justify='center'>
            <Grid item xs={12} md={8} lg={9}>
              {
                matchesDesktopScreen
              && (
                <Paper
                  elevation={0}
                  className={classes.root}
                >
                  <AccordionStack
                    branchesOverview={ownersState.branchesOverview}
                  />
                </Paper>
              )
              }
              {
                matchesMobileScreen
              && (
                <BranchesCards
                  branchesOverview={ownersState.branchesOverview}
                />
              )
              }
            </Grid>
          </Grid>
        </Container>
        <FilterByDateDialog
          isFilterDialogOpened={isFilterDialogOpened}
          setIsFilterDialogOpened={setIsFilterDialogOpened}
          filterHandler={filterByDateHandler}
        />
        <CustomSpeedDial speedDialActions={speedDialActions} />
      </div>
    </>
  );
}
