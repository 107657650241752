/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description Get All Terminals event api call
 * @async
 * @method getTerminals
 * @returns {Object} response object
 */
export async function getTerminals(eventId) {
  const response = await loggedInAxios.get(`/terminals/?event_id=${eventId}`);
  return response;
}

/**
 * @description Get Terminal event api call
 * @async
 * @method getTerminal
 * @param  {Integer} terminalId - terminal id
 * @returns {Object} response object
 */
export async function getTerminal(terminalId) {
  const response = await loggedInAxios.get(`/terminals/${terminalId}/`);
  return response;
}

/**
 * @description Add Terminal event api call
 * @async
 * @method addTerminal
 * @param  {JSON} postData
 * @returns {Object} response object
 */
export async function addTerminal(postData) {
  const response = await loggedInAxios.post('/terminals/', postData);
  return response;
}

/**
 * @description Update Terminals event api call
 * @async
 * @param  {Integer, JSON} terminalId, postData
 * @method updateTerminal
 * @returns {Object} response object
 */
export async function updateTerminal(terminalId, patchData) {
  const response = await loggedInAxios.patch(`/terminals/${terminalId}/`, patchData);
  return response;
}

/**
 * @description Delete Terminal event api call
 * @async
 * @method deleteTerminal
 * @param  {Integer} terminalId
 * @returns {Object} response object
 */
export async function deleteTerminal(terminalId) {
  const response = await loggedInAxios.delete(`/terminals/${terminalId}/`);
  return response;
}
