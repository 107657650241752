import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Login from '../Admin/pages/Login/Login';
import AdminLanding from '../Admin/pages/AdminLanding/AdminLanding';
import Page500 from '../Admin/pages/Page500/Page500';
import EditEvent from '../Admin/pages/EditEvent/EditEvent';
import Page404 from '../Page404/Page404';
import EventUsersEntryRouter from '../EventUsersEntryRouter/EventUsersEntryRouter';

/**
 * @description Routes component - used for routing
 * @method Routes
 * @returns {'<Routes>'} <Routes>
 */
export default function Routes() {
  return (
    <Switch>
      <Route path='/admin' component={Login} exact />
      <Route path='/500' component={Page500} exact />
      <PrivateRoute path='/events' component={AdminLanding} />
      <Route path='/edit/:id' component={EditEvent} />
      <Route
        path={['/owners/:hash', '/users/:hash', '/staff/:hash']}
        component={EventUsersEntryRouter}
      />
      <Route
        render={() => (
          <Page404 />
        )}
      />
    </Switch>
  );
}
