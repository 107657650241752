/* eslint-disable camelcase */
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { trackPromise } from 'react-promise-tracker';
import { updateEvent } from '../../../../../../api/EventCalls';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import IOSToggleSwitch from '../../../../../common/IOSToggleSwitch/IOSToggleSwitch';
import currencies from '../../../../../../config/currency';
import { normalizeEventUpdateData } from '../../../../../../utils/normalizationFunctions';

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: '2rem',
    width: '100%',
  },
  mobileContainer: {
    justifyContent: 'center',
    padding: '1rem',
  },
  button: {
    marginTop: '2rem',
  },
  toggle: {
    margin: 0,
    height: '42px',
  },
}));

export default function EditForm({
  eventState,
  setEventState,
  isDisabled,
  setIsDisabled,
  id,
}) {
  const classes = useStyles();
  const { openSnackBar } = useContext(SnackBarContext);

  const handleEditClick = () => {
    setIsDisabled(false);
  };

  const handleSaveClick = async () => {
    const formData = normalizeEventUpdateData(eventState);

    await trackPromise(
      updateEvent(formData, id)
        .then((response) => {
          if (response.status === 202) {
            openSnackBar('warning', `${response.data.details}`, 'update-event-loading-popup');
          } else {
            openSnackBar('success', 'Event updated!', 'update-event-success-popup');
          }
        })
        .catch(() => openSnackBar('error', 'Something went wrong!', 'update-event-fail-popup')),
    );
    setIsDisabled(true);
  };

  const handleNameBGChange = (e) => {
    setEventState({
      ...eventState,
      name_bg: e.target.value,
    });
  };

  const handleNameENChange = (e) => {
    setEventState({
      ...eventState,
      name_en: e.target.value,
    });
  };

  const handleCurrencyChange = (e) => {
    setEventState({
      ...eventState,
      currency: e.target.value,
    });
  };

  const handleStartDateChange = (date) => {
    setEventState({
      ...eventState,
      start_time: date,
    });
  };

  const handleStartEndChange = (date) => {
    setEventState({
      ...eventState,
      end_time: date,
    });
  };

  const handleStartIdChange = (e) => {
    setEventState({
      ...eventState,
      start_customer_id: e.target.value,
    });
  };

  const handleEndIdChange = (e) => {
    setEventState({
      ...eventState,
      end_customer_id: e.target.value,
    });
  };

  const togglePenniesChecked = () => {
    setEventState({
      ...eventState,
      display_pennies: !eventState.display_pennies,
    });
  };

  return (
    <Grid container className={classes.mobileContainer}>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Event Name BG:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <TextField
          disabled={isDisabled}
          value={eventState.name_bg}
          onChange={handleNameBGChange}
          className={classes.textField}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Event Name EN:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <TextField
          disabled={isDisabled}
          value={eventState.name_en}
          className={classes.textField}
          onChange={handleNameENChange}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Currency:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Select
          disabled={isDisabled}
          value={eventState.currency}
          className={classes.textField}
          onChange={handleCurrencyChange}
          id='currency'
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value} id={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Display Pennies:
        </Typography>
        <TextField
          className={classes.textField}
          disabled
          value={eventState.display_pennies ? 'Yes' : 'No'}
          margin='none'
          type='text'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IOSToggleSwitch
                  className={classes.toggle}
                  checked={eventState.display_pennies}
                  onChange={togglePenniesChecked}
                  disabled={isDisabled}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Start Time:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={eventState.start_time}
            disabled={isDisabled}
            onChange={handleStartDateChange}
            className={classes.textField}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          End Time:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={eventState.end_time}
            disabled={isDisabled}
            onChange={handleStartEndChange}
            className={classes.textField}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Card Range:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <TextField
          disabled={isDisabled}
          value={eventState.start_customer_id}
          margin='none'
          type='number'
          onChange={handleStartIdChange}
          className={classes.textField}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <TextField
          disabled={isDisabled}
          value={eventState.end_customer_id}
          margin='none'
          type='number'
          onChange={handleEndIdChange}
          className={classes.textField}
        />
      </Grid>
      {isDisabled ? (
        <Button onClick={handleEditClick} variant='contained' color='primary'>
          Edit
          <EditIcon className={classes.iconInButton} />
        </Button>
      ) : (
        <Button onClick={handleSaveClick} variant='contained' color='primary'>
          Save
          <SaveIcon className={classes.iconInButton} />
        </Button>
      )}
    </Grid>
  );
}
