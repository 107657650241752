/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { loginAxios } from './LoginCalls';

// Instance of axios with auth headers
export const loggedInAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

/**
 * @description forces user to logout - clears localStorage and changes url to /
 * @method forceLogOut
 */
function forceLogOut() {
  localStorage.clear();
  window.location.href = '/';
}

/**
   * @description refreshes the access token in localStorage
   * @method refreshAccessToken
   */
function refreshAccessToken(response) {
  const { access } = response.data;
  localStorage.setItem('access_token', access);
}

/**
   * @description is the token expired
   * @method isTokenExpired
   * @param  {String} tokenName  - username and password object
   * @returns {Boolean} isExpired
   */
function isTokenExpired(tokenName) {
  const token = localStorage.getItem(tokenName);
  if (token === null) {
    return false;
  }
  const decodedToken = jwt.decode(token);
  const dateNow = new Date();
  if (decodedToken.exp < dateNow.getTime() / 1000) { return true; }
  return false;
}

/**
 * @description get Refresh Token api call
 * @async
 * @method getRefreshToken
 * @returns {Object} response object
 */
async function getRefreshToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  const postBody = { refresh: refreshToken };
  try {
    const response = await loginAxios.post('/api/token/refresh/', postBody);
    return response;
  } catch (error) {
    forceLogOut();
    throw error;
  }
}

loggedInAxios.interceptors.request.use(async (config) => {
  if (isTokenExpired('access_token')) {
    const response = await getRefreshToken();
    await refreshAccessToken(response);
  }
  const accessToken = localStorage.getItem('access_token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
}, (error) => error);
