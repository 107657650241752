/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for UserContext
 */

export const addUserData = (userData, dispatch) => {
  dispatch({
    type: 'ADD_USER_DATA',
    userData,
  });
};

export const addTransactionsData = (transactionsData, dispatch) => {
  dispatch({
    type: 'ADD_TRANSACTIONS_DATA',
    transactionsData,
  });
};

export const setIsDialogOpened = (isOpened, dispatch) => {
  dispatch({
    type: 'SET_IS_DIALOG_OPENED',
    isOpened,
  });
};

export const setIsFirstVisit = (hasVisitted, dispatch) => {
  dispatch({
    type: 'SET_IS_FIRST_VISIT',
    hasVisitted,
  });
};

export const setViberUrl = (url, dispatch) => {
  dispatch({
    type: 'SET_VIBER_URL',
    url,
  });
};

export const setSubscribedToViber = (isSubscribed, dispatch) => {
  dispatch({
    type: 'SET_SUBSCRIBED_TO_VIBER',
    isSubscribed,
  });
};
