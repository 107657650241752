import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { setIsDialogOpened } from '../../../../context/UserActions';
import { UserContext } from '../../../../context/UserContext';
import StyledDialogTitle from '../../../common/StyledDialogTitle/WhiteBackgroundDialogTitle/WhiteBackgroundDialogTitle';
import { updateNotificationsLanguage } from '../../../../api/UserCalls';
import Transition from '../../../common/TransitionComponent/TransitionComponent';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
    minWidth: '220px',
  },
  formControl: {
    width: '100%',
  },
  formHelperText: {
    maxWidth: '220px',
  },
}));

export default function ChangeNotificationsLanguageDialog({
  isOpen,
  setIsOpen,
  notificationsLanguage,
  setNotificationsLanguage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userDispatch } = useContext(UserContext);
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    errors,
    setError,
  } = useForm();
  const { hash } = useParams();

  const languages = [
    {
      key: 'bg',
      value: t('changeNotificationsLanguageDialog.languageOptionBG'),
    },
    {
      key: 'en',
      value: t('changeNotificationsLanguageDialog.languageOptionEN'),
    },
  ];

  const handleClose = () => {
    setIsOpen(false);
    setIsDialogOpened(false, userDispatch);
  };

  const handleSelectChange = (event) => {
    setValue('language', event.target.value);
    setNotificationsLanguage(event.target.value);
  };

  const handleUpdateLanguage = async () => {
    const chosenLanguage = getValues('language');

    const updateData = {
      language: chosenLanguage,
    };

    try {
      await updateNotificationsLanguage(hash, updateData);
      handleClose();
    } catch (error) {
      setError('language', { message: t('changeNotificationsLanguageDialog.errorMessage') });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    handleUpdateLanguage();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='change-notifications-language-dialog'
      TransitionComponent={Transition}
      open={isOpen}
      data-cy='change-notifications-language-dialog'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledDialogTitle
          id='change-notifications-language-dialog-title'
          onClose={handleClose}
        >
          {t('changeNotificationsLanguageDialog.dialogTitle')}
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <FormControl
            className={classes.formControl}
            error={!!errors.language}
          >
            <InputLabel shrink>
              {t('changeNotificationsLanguageDialog.selectLabel')}
            </InputLabel>
            <Controller
            // eslint-disable-next-line no-unused-vars
              render={({ onChange, value }) => (
                <Select
                  value={notificationsLanguage}
                  onChange={handleSelectChange}
                  data-cy='change-notifications-language-dialog-select'
                >
                  {languages.map((option) => (
                    <MenuItem key={option.value} value={option.key} data-cy={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name='language'
              defaultValue={notificationsLanguage}
              control={control}
            />
            <FormHelperText className={classes.formHelperText}>
              {errors.language && errors.language.message}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('changeNotificationsLanguageDialog.cancelButton')}
          </Button>
          <Button variant='contained' color='primary' type='submit' data-cy='change-notifications-language-dialog-save-button'>
            {t('changeNotificationsLanguageDialog.saveButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ChangeNotificationsLanguageDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  notificationsLanguage: PropTypes.string.isRequired,
  setNotificationsLanguage: PropTypes.func.isRequired,
};
