import React from 'react';
import { CreateEventProvider } from './CreateEventContext';
import { SnackBarProvider } from './SnackBarContext';
import { EventsContextProvider } from './EventsContext';
import { DialogProvider } from './DialogContext';
/**
 * @file CombinedContextProvider - combines all contexts in one component
 */

/**
 * @description CombinedContextProvider component
 * @method CombinedContextProvider
 * @returns {'<CombinedContextProvider>'} <CombinedContextProvider>
 */
export default function CombinedContextProvider({ children }) {
  return (
    <SnackBarProvider>
      <DialogProvider>
        <EventsContextProvider>
          <CreateEventProvider>
            {children}
          </CreateEventProvider>
        </EventsContextProvider>
      </DialogProvider>
    </SnackBarProvider>
  );
}
