import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
  value: {
    fontSize: 20,
  },
  alignHorizontally: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoBox: {
    margin: '1rem',
  },
  alignStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    wordWrap: 'break-word',
    textAlign: 'start',

  },
  alignEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    wordWrap: 'break-word',
    textAlign: 'end',
  },
  alignToTopWithMargin: {
    marginLeft: '16px',
  },
  transactionCounter: {
    width: '30%',
  },
});

export default function DetailsContent({ transaction }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className={classes.alignHorizontally}>
        <div className={classes.infoBox}>
          <Typography className={`${classes.title} ${classes.alignStart}`} color='textSecondary' gutterBottom>
            {t('transactionCard.detailsContent.accumulated-spending')}
          </Typography>
          <Typography className={`${classes.value} ${classes.alignStart}`} variant='h5' component='h2'>
            {transaction.account_spending}
          </Typography>
        </div>

        <div className={classes.infoBox}>
          <Typography className={`${classes.title} ${classes.alignEnd}`} color='textSecondary' gutterBottom>
            {t('transactionCard.detailsContent.balance')}
          </Typography>
          <Typography className={`${classes.value} ${classes.alignEnd}`} variant='h5' component='h2'>
            {transaction.balance_prepaid}
          </Typography>
        </div>
      </div>
      <div className={classes.alignHorizontally}>
        <div className={`${classes.infoBox} ${classes.transactionCounter}`}>
          <Typography className={`${classes.title} ${classes.alignStart}`} color='textSecondary' gutterBottom>
            {t('transactionCard.detailsContent.transaction-number')}
          </Typography>
          <Typography className={`${classes.value} ${classes.alignStart}`} variant='h5' component='h2'>
            {transaction.transaction_counter}
          </Typography>
        </div>
        <div className={classes.infoBox}>
          <Typography className={`${classes.title} ${classes.alignEnd}`} color='textSecondary' gutterBottom>
            {t('transactionCard.detailsContent.staff')}
          </Typography>
          <Typography className={`${classes.value} ${classes.alignEnd}`} variant='h5' component='h2'>
            {
              transaction.staff[i18n.language]
                ? transaction.staff[i18n.language]
                : transaction.staff.external_id
            }
          </Typography>
        </div>
      </div>
      {/* <div className={classes.alignToTopWithMargin}>
        <Typography className={`${classes.title} ${classes.alignStart}`} color="textSecondary" gutterBottom>
        {t('transactionCard.detailsContent.accumulated-spending')}
        </Typography>
        <Typography className={`${classes.value} ${classes.alignStart}`} variant="h5" component="h2" gutterBottom>
        {transaction.account_spending}
        </Typography>
        <Typography className={`${classes.title} ${classes.alignStart}`} color="textSecondary" gutterBottom>
        {t('transactionCard.detailsContent.staff')}
        </Typography>
        <Typography className={`${classes.value} ${classes.alignStart}`} variant="h5" component="h2" gutterBottom>
          {{
              transaction.staff[i18n.language]
                ? transaction.staff[i18n.language]
                : transaction.staff.external_id
            }
        </Typography>
      </div> */}
    </div>
  );
}
