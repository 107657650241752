import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import UKFlag from '../../../../../common/Images/flags/uk.png';
import BGFlag from '../../../../../common/Images/flags/bg.png';

const useStyles = makeStyles({
  langIcon: {
    color: 'white',
  },
  flagImage: {
    width: '60px',
    height: '36px',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.13)',
    marginRight: '1rem',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listItemText: {
    marginRight: '0.5rem',
  },
});

export default function LongMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleENClick = () => {
    i18n.changeLanguage('en');
    setAnchorEl(null);
  };

  const handleBGClick = () => {
    i18n.changeLanguage('bg');
    setAnchorEl(null);
  };

  const isLanguageChosen = (langToCheck) => (
    i18n.language === langToCheck
      ? <CheckCircleIcon color='primary' />
      : null
  );

  return (
    <>
      <IconButton
        aria-label='choose-language'
        aria-controls='lang-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={classes.langIcon}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <List>
          <ListItem className={classes.listItem} button onClick={handleENClick}>
            <img
              className={classes.flagImage}
              src={UKFlag}
              alt='United Kingdom Flag'
            />
            <ListItemText
              primary='English'
              className={classes.listItemText}
            />
            {
              isLanguageChosen('en')
            }
          </ListItem>
          <ListItem className={classes.listItem} button onClick={handleBGClick}>
            <img
              className={classes.flagImage}
              src={BGFlag}
              alt='Bulgarian Flag'
            />
            <ListItemText
              className={classes.listItemText}
              primary='Български'
            />
            {
              isLanguageChosen('bg')
            }
          </ListItem>
        </List>
      </Menu>
    </>
  );
}
