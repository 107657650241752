import React from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  Dashboard as DashboardIcon, Storage as StorageIcon, Edit as EditIcon, Store as StoreIcon,
  AccountBalance as AccountBalanceIcon, People as PeopleIcon, GroupWork as GroupWorkIcon, MoneyOff as MoneyOffIcon,
} from '@material-ui/icons';
import TerminalIcon from '../../../../common/CustomIcons/TerminalIcon';

export default function NavigationListItems({ eventId }) {
  const history = useHistory();

  const handleClickDashboard = () => history.push('/events');
  const handleClickEditEvent = () => history.push(`/edit/${eventId}`);
  const handleClickDatabase = () => history.push(`/edit/${eventId}/database`);
  const handleClickGroups = () => history.push(`/edit/${eventId}/groups`);
  const handleClickBranches = () => history.push(`/edit/${eventId}/branches`);
  const handleClickTerminals = () => history.push(`/edit/${eventId}/terminals`);
  const handleClickStaff = () => history.push(`/edit/${eventId}/staff`);
  const handleClickPayments = () => history.push(`/edit/${eventId}/payments`);

  function handleClickRefunds() {
    history.push(`/edit/${eventId}/refunds`);
  }

  const menuItems = [
    {
      text: 'Dashboard',
      icon: <DashboardIcon />,
      id: 'nav-go-to-dashboard-button',
      clickHandler: handleClickDashboard,
    },
    {
      text: 'Edit Event',
      icon: <EditIcon />,
      id: 'nav-edit-base-details',
      clickHandler: handleClickEditEvent,
    },
    {
      text: 'Database',
      icon: <StorageIcon />,
      id: 'nav-edit-database-button',
      clickHandler: handleClickDatabase,
    },
    {
      text: 'Staff',
      icon: <PeopleIcon />,
      id: 'nav-edit-staff-button',
      clickHandler: handleClickStaff,
    },
    {
      text: 'Terminals',
      icon: <TerminalIcon />,
      id: 'edit-terminals-nav-button',
      clickHandler: handleClickTerminals,
    },
    {
      text: 'Branches',
      icon: <StoreIcon />,
      id: 'edit-branches-nav-button',
      clickHandler: handleClickBranches,
    },
    {
      text: 'Groups',
      icon: <GroupWorkIcon />,
      id: 'edit-groups-nav-button',
      clickHandler: handleClickGroups,
    },
    {
      text: 'Refunds',
      icon: <MoneyOffIcon />,
      id: 'edit-refunds-nav-button',
      clickHandler: handleClickRefunds,
    },
    {
      text: 'Payments',
      icon: <AccountBalanceIcon />,
      id: 'edit-payments-nav-button',
      clickHandler: handleClickPayments,
    },
  ];

  const getListItem = (listItemInfo) => {
    const {
      text, icon, id, clickHandler,
    } = listItemInfo;
    return (
      <ListItem
        button
        onClick={clickHandler}
        id={id}
        key={id}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text}
        />
      </ListItem>
    );
  };

  return (
    menuItems.map((item) => getListItem(item))
  );
}
