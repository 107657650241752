/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for GroupsContext
 */

export const addGroupsData = (groups, dispatch) => {
  dispatch({
    type: 'ADD_GROUPS_DATA',
    groups,
  });
};

export const removeGroupsData = (dispatch) => {
  dispatch({
    type: 'REMOVE_GROUPS_DATA',
  });
};

export const changeEditGroupId = (editGroupId, dispatch) => {
  dispatch({
    type: 'CHANGE_EDIT_GROUP_ID',
    editGroupId,
  });
};

export const addAvailableBranches = (branches, dispatch) => {
  dispatch({
    type: 'ADD_AVAILABLE_BRANCHES',
    branches,
  });
};

export const addSelectedBranches = (branches, dispatch) => {
  dispatch({
    type: 'ADD_SELECTED_BRANCHES',
    branches,
  });
};

export const addAllBranchesForEvent = (branches, dispatch) => {
  dispatch({
    type: 'ADD_ALL_BRANCHES_FOR_EVENT',
    branches,
  });
};

export const addGroupToEdit = (group, dispatch) => {
  dispatch({
    type: 'ADD_GROUP_TO_EDIT',
    group,
  });
};
