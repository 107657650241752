/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description getBranches event api call
 * @async
 * @method getBranches
 * @returns {Object} response object
 */
export async function getBranches() {
  const response = await loggedInAxios.get('/branches/');
  return response;
}

/**
 * @description Get Branch event api call
 * @async
 * @method getBranch
 * @param  {Integer} branchId - event id
 * @returns {Object} response object
 */
export async function getBranch(branchId) {
  const response = await loggedInAxios.get(`/branches/${branchId}/`);
  return response;
}

/**
 * @description Add Branch event api call
 * @async
 * @method addBranch
 * @param  {JSON} postData
 * @returns {Object} response object
 */
export async function addBranch(postData) {
  const response = await loggedInAxios.post('/branches/', postData);
  return response;
}

/**
 * @description Update Branch event api call
 * @async
 * @param  {Integer, JSON} branchId, postData
 * @method updateBranch
 * @returns {Object} response object
 */
export async function updateBranch(branchId, patchData) {
  const response = await loggedInAxios.patch(`/branches/${branchId}/`, patchData);
  return response;
}

/**
 * @description Delete Branch event api call
 * @async
 * @method deleteBranch
 * @param  {Integer} branchId
 * @returns {Object} response object
 */
export async function deleteBranch(branchId) {
  const response = await loggedInAxios.delete(`/branches/${branchId}/`);
  return response;
}
