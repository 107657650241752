import React from 'react';
import BranchCard from './BranchCard/BranchCard';

export default function BranchesCards({ branchesWithTerminals }) {
  return (
    Object.values(branchesWithTerminals)
      .map(
        (branch) => (
          <BranchCard
            branch={branch}
            key={branch[0].branch.external_id}
          />
        ),
      )
  );
}
