import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';
import { updateBranch } from '../../../../../../../api/BranchesCalls';

export default function EditBranchModal({
  openDialog,
  setOpenDialog,
  branchIDEdit,
  branchToEdit,
  setBranchToEdit,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };
  const { openSnackBar } = useContext(SnackBarContext);
  async function handleEditBranch() {
    const patchData = {
      external_id: branchToEdit.external_id,
      name_bg: branchToEdit.name_bg,
      name_en: branchToEdit.name_en,
    };
    try {
      await updateBranch(branchIDEdit, patchData);
      openSnackBar('success', `Branch with id ${branchIDEdit} updated.`, 'update-branch-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong', 'edit-branch-fail-popup');
    }
    setOpenDialog(false);
  }

  function handleIDChange(event) {
    setBranchToEdit({
      ...branchToEdit,
      external_id: event.target.value,
    });
  }

  function handleNameBGChange(event) {
    setBranchToEdit({
      ...branchToEdit,
      name_bg: event.target.value,
    });
  }

  function handleNameENChange(event) {
    setBranchToEdit({
      ...branchToEdit,
      name_en: event.target.value,
    });
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        scroll='body'
      >
        <DialogTitle id='form-dialog-title'>Edit Branch</DialogTitle>
        <DialogContent>
          <Grid style={{ marginBottom: '1rem' }} container display='flex' direction='column'>
            <TextField
              margin='dense'
              id='edit-branch-dialog-id'
              label='ID'
              type='text'
              onChange={handleIDChange}
              value={branchToEdit.external_id}
            />
            <TextField
              margin='dense'
              id='edit-branch-dialog-name-bg'
              label='Name BG'
              type='text'
              onChange={handleNameBGChange}
              value={branchToEdit.name_bg}
            />
            <TextField
              margin='dense'
              id='edit-branch-dialog-name-en'
              label='Name EN'
              type='text'
              onChange={handleNameENChange}
              value={branchToEdit.name_en}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '16px 20px' }}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            id='edit-branch-modal-button'
            variant='contained'
            onClick={handleEditBranch}
            color='primary'
          >
            Edit Branch
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
