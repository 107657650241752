import React, { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import SpeedDial from '@material-ui/lab/SpeedDial';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { useParams } from 'react-router-dom';
import ArchiveIcon from '@material-ui/icons/Archive';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GroupsAccordionStack from './GroupsAccordionStack/GroupsAccordionStack';
import AddGroupModal from './AddGroupModal/AddGroupModal';
import EditGroupModal from './EditGroupModal/EditGroupModal';
import { GroupsContext } from '../../../../../../context/GroupsContext';
import { DialogContext } from '../../../../../../context/DialogContext';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import GroupsCards from './GroupsCards/GroupsCards';
import {
  getGroups, getGroup, deleteGroup, downloadGroupsQRCodesArchive, downloadGroupsQRCodesCSV,
} from '../../../../../../api/GroupsCalls';
import { getBranch } from '../../../../../../api/BranchesCalls';
import { download } from '../../../../../../utils/utils';

import {
  addGroupsData,
  addAvailableBranches,
  changeEditGroupId,
  addAllBranchesForEvent,
  addSelectedBranches,
  addGroupToEdit,
} from '../../../../../../context/GroupsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export default function Groups({ event }) {
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [openNewGroupDialog, setOpenNewGroupDialog] = useState(false);
  const [openEditGroupDialog, setOpenEditGroupDialog] = useState(false);
  const { groupsState, groupsDispatch } = useContext(GroupsContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  const classes = useStyles();
  let { id } = useParams();
  id = Number(id);
  async function addBranchesToArrayWithIDs(branchesArray) {
    return Promise.all(
      branchesArray.map(async (branchId) => {
        const branchDetails = await getBranch(branchId);
        return {
          id: branchDetails.data.id,
          external_id: branchDetails.data.external_id,
        };
      }),
    );
  }

  useEffect(() => {
    async function addData() {
      const groupsForEvent = await getGroups(id);
      const filteredGroups = groupsForEvent.data.filter((group) => group.is_default === false);
      addGroupsData(filteredGroups, groupsDispatch);
      const availableBranches = groupsForEvent.data.find((group) => group.is_default === true);
      addAllBranchesForEvent(availableBranches.branches, groupsDispatch);
    }
    addData();
  }, [groupsDispatch, id, openNewGroupDialog, openEditGroupDialog]);

  const handleDelete = (groupID) => {
    const deleteHandlerForForm = async () => {
      try {
        await deleteGroup(groupID);
        const groupsForEvent = await getGroups(id);
        const filteredGroups = groupsForEvent.data.filter((group) => group.is_default === false);
        addGroupsData(filteredGroups, groupsDispatch);
        openSnackBar('info', `Group with ID ${groupID} deleted.`, 'delete-group-success-popup');
      } catch (error) {
        openSnackBar('error', 'Something went wrong!', 'delete-group-fail-popup');
      }
      closeDialog();
    };
    openDialog('error', 'Delete', `Group with ID ${groupID} will be deleted!`, 'Are you sure?', 'delete-group-modal-button', deleteHandlerForForm);
  };

  async function handleEdit(groupID) {
    changeEditGroupId(groupID, groupsDispatch);
    const groupData = await getGroup(groupID);
    const chosenBranches = await addBranchesToArrayWithIDs(groupData.data.branches);
    addSelectedBranches(chosenBranches, groupsDispatch);
    const filteredBranchesNotChosen = groupsState.allBranchesForEvent.filter((branch) => !groupData.data.branches.includes(branch));
    const notChosenBranches = await addBranchesToArrayWithIDs(filteredBranchesNotChosen);
    addAvailableBranches(notChosenBranches, groupsDispatch);
    addGroupToEdit(groupData.data, groupsDispatch);
    setOpenEditGroupDialog(true);
  }

  const handleAddSingleGroup = async () => {
    const notChosenBranches = await addBranchesToArrayWithIDs(groupsState.allBranchesForEvent);
    addAvailableBranches(notChosenBranches, groupsDispatch);
    setOpenNewGroupDialog(true);
  };

  const handleActionButtonClose = () => {
    setOpenSpeedDial(false);
  };

  const handleActionButtonOpen = () => {
    setOpenSpeedDial(true);
  };

  const downloadCodesZip = async () => {
    try {
      await download(downloadGroupsQRCodesArchive(event.id), '.zip', 'qr-images-zip');
    } catch (error) {
      openSnackBar('error', 'Something went wrong with the download!', 'download-zip-fail-popup');
    }
  };

  const downloadCodesCsv = async () => {
    try {
      await download(downloadGroupsQRCodesCSV(event.id), '.csv', 'qr-codes-csv');
    } catch (error) {
      openSnackBar('error', 'Something went wrong with the download!', 'download-csv-fail-popup');
    }
  };

  const speedDialActions = [
    {
      icon: <GroupAddIcon />,
      name: 'Add a Single Group',
      id: 'add-single-group',
      handler: handleAddSingleGroup,
    },
    {
      icon: <ArchiveIcon />,
      name: 'Download QR codes images ZIP',
      id: 'download-codes-images-zip',
      handler: downloadCodesZip,
    },
    {
      icon: <GetAppIcon />,
      name: 'Download QR codes CSV',
      id: 'download-codes-csv',
      handler: downloadCodesCsv,
    },
  ];

  return (
    <div className={classes.content}>
      <Container maxWidth='lg' className={classes.container}>
        {
          matchesDesktopScreen && (
          <Paper className={classes.root}>
            <GroupsAccordionStack
              panelsArray={groupsState.groups}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              defaultGroupBranches={groupsState.allBranchesForEvent}
            />
          </Paper>
          )
        }
        {
          matchesMobileScreen && (
            <GroupsCards
              cardsArray={groupsState.groups}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          )
        }
        <SpeedDial
          ariaLabel='SpeedDial example'
          className={classes.speedDial}
          icon={<MoreHorizIcon />}
          onClose={handleActionButtonClose}
          onOpen={handleActionButtonOpen}
          open={openSpeedDial}
          direction='up'
          id='speed-dial'
        >
          {
            speedDialActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
                id={action.id}
                data-cy={action.id}
              />
            ))
          }
        </SpeedDial>
      </Container>
      <AddGroupModal
        openDialog={openNewGroupDialog}
        setOpenDialog={setOpenNewGroupDialog}
        eventId={id}
      />
      <EditGroupModal
        openDialog={openEditGroupDialog}
        setOpenDialog={setOpenEditGroupDialog}
        eventId={id}
      />
    </div>
  );
}
