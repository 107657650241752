/* eslint-disable import/prefer-default-export */
import React, { createContext, useReducer } from 'react';

const initialState = {
  overview: {
    event: '',
    staff: '',
    total_deposited: '',
    total_withdrawn: '',
    total_spent: '',
    total_canceled_transactions: '',
    total_transactions: '',
    last_transaction_time: '',
    fetched_at: '',
  },
  terminalsOverview: {
    0: [
      {
        balance: {
          spent: '',
          canceled: '',
          deposited: '',
          withdrawn: '',
          total_transactions: '',
          last_transaction: '',
        },
        name: {
          external_id: '',
        },
        branch: {
          external_id: '',
          bg: '',
          en: '',
        },
      },
    ],
  },
};

export const StaffContext = createContext(initialState);

const addStaffOverview = (state, overview) => ({ ...state, overview });

const addTerminalsOverview = (state, terminalsOverview) => ({ ...state, terminalsOverview });

/**
 * @description Staff Reducer
 * @method staffReducer
 */
const staffReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_STAFF_OVERVIEW':
      return addStaffOverview(state, action.overview);
    case 'ADD_TERMINALS_OVERVIEW':
      return addTerminalsOverview(state, action.terminalsOverview);
    default:
      return state;
  }
};

/**
 * @description StaffContextProvider component
 * @method StaffContextProvider
 * @returns {'<StaffContextProvider>'} <StaffContextProvider>
 */
export const StaffContextProvider = ({ children }) => {
  const [staffState, staffDispatch] = useReducer(staffReducer, initialState);

  return (
    <StaffContext.Provider
      value={{ staffState, staffDispatch }}
    >
      {children}
    </StaffContext.Provider>
  );
};
