/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {
  Add as AddIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { isValueInRange, isValueEmpty, isStartOfRangeSmallerThanEnd, isInArray, overlaps } from '../../../../../../../utils/festclubSubrangeValidations';
import { disableNextButton, enableNextButton } from '../../../../../../../context/Actions';
import { CreateEventContext } from '../../../../../../../context/CreateEventContext';
import { v4 as uuidv4} from 'uuid'

const useStyles = makeStyles((theme) => ({
  subrangeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  subrangeButton: {
    margin: '0.5rem 0rem',
  },
  subrangeInputContainer: {
    textAlign: 'end',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('370')]: {
      flexDirection: 'column',
    },
  },
  subrangeTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightBlueColor: {
    color: '#3f51b5',
  },
  deleteButtonText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      textAlign: 'left',
    },
  },
  subrangeInputsContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
    },
    [theme.breakpoints.down('370')]: {
      width: '100%',
    },
  },
  subrangeInput: {
    marginTop: '0rem',
  },
}));

/**
 * @description FestclubSubranges component - Dynamic creation of start and end input for fest club subranges
 * @method FestclubSubranges
 * @returns {'<FestclubSubranges>'} <FestclubSubranges>
 */
export default function FestclubSubranges({ customerIDsAndNames, setCustomerIDsAndNames }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const { dispatch } = useContext(CreateEventContext);

  const addStartSubrangeError = (id, errorMessage) => {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      subranges: customerIDsAndNames.subranges.map(subrange => {
        if (subrange.id === id) {
          subrange.startErrorMessage = errorMessage;
        }
        return subrange;
      })
    })
  }

  const addEndSubrangeError = (id, errorMessage) => {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      subranges: customerIDsAndNames.subranges.map(subrange => {
        if (subrange.id === id) {
          subrange.endErrorMessage = errorMessage;
        }
        return subrange;
      })
    })
  }
  
  const removeStartSubrangeError = (id) => {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      subranges: customerIDsAndNames.subranges.map(subrange => {
        if (subrange.id === id) {
          subrange.startErrorMessage = '';
        }
        return subrange;
      })
    })
  }

  const removeEndSubrangeError = (id) => {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      subranges: customerIDsAndNames.subranges.map(subrange => {
        if (subrange.id === id) {
          subrange.endErrorMessage = '';
        }
        return subrange;
      })
    })
  }

  const removeAllSubrangeErrors = (id) => {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      subranges: customerIDsAndNames.subranges.map(subrange => {
        if (subrange.id === id) {
          subrange.startErrorMessage = "";
          subrange.endErrorMessage = "";
        }
        return subrange;
      })
    })
  }

  const addNewSubrange = () => (
    setCustomerIDsAndNames(
      {...customerIDsAndNames, 
        subranges: [...customerIDsAndNames.subranges, {
          id: uuidv4(),
          start: '',
          end: '',
          startErrorMessage: '',
          endErrorMessage: ''
        }]
      }
    )
  )

  const deleteSubrange = (id) => {
    removeAllSubrangeErrors(id);
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      subranges: customerIDsAndNames.subranges.filter(subrange => id !== subrange.id)
    })
  };

  const validateStartSubrange = (subrange, startCustomerId, endCustomerId, id) => {
    const startSubrange = subrange.start;
    const endSubrange = subrange.end;

    const isStartInRange = isValueInRange(startSubrange, startCustomerId, endCustomerId);
    const isStartFieldEmpty = isValueEmpty(startSubrange, 'start');
    const isStartSmaller = isStartOfRangeSmallerThanEnd(startSubrange, endSubrange);
    const doesSubrangeExist = isInArray(customerIDsAndNames.subranges, startSubrange, endSubrange, id);
    const doesSubrangeOverlap = overlaps(customerIDsAndNames.subranges, startSubrange, endSubrange, id);
    const isEndError = customerIDsAndNames.subranges.some(subrange => !!subrange.endErrorMessage && subrange.id === id);

    if (isStartFieldEmpty) {
      addStartSubrangeError(id, 'Please fill in start subrange.');
    } else if (!isStartInRange) {
      addStartSubrangeError(id, `Start Subrange must be between ${startCustomerId} and ${endCustomerId}.`);
    } else if (!isStartSmaller && endSubrange !== '') {
      addStartSubrangeError(id, `Start Subrange cannot exceed ${endSubrange}.`)
    } else if (doesSubrangeOverlap) {
      addStartSubrangeError(id, 'This subrange has been used in another subrange.')
    } else if (doesSubrangeExist) {
      addStartSubrangeError(id, 'The given subrange already exists!')
    } else {
      removeStartSubrangeError(id);
      if (isEndError) {
        validateEndSubrange(subrange, startCustomerId, endCustomerId, id);
      }
    }
  }

  const validateEndSubrange = (subrange, startCustomerId, endCustomerId, id) => {
    const startSubrange = subrange.start;
    const endSubrange = subrange.end;
    
    const isEndInRange = isValueInRange(endSubrange, startSubrange, endCustomerId);
    const isStartFieldEmpty = isValueEmpty(startSubrange, 'end');
    const isEndFieldEmpty = isValueEmpty(endSubrange, 'end');
    const doesSubrangeExist = isInArray(customerIDsAndNames.subranges, startSubrange, endSubrange, id);
    const doesSubrangeOverlap = overlaps(customerIDsAndNames.subranges, startSubrange, endSubrange, id);
    const isStartError = customerIDsAndNames.subranges.some(subrange => !!subrange.startErrorMessage && subrange.id === id);

    if (isEndFieldEmpty) {
      addEndSubrangeError(id, 'Please fill in end subrange.');
    } else if (isStartFieldEmpty) {
      addEndSubrangeError(id, 'Please fill in start subrange.');
    } else if (!isEndInRange) {
      addEndSubrangeError(id, `End Subrange must be between ${startSubrange} and ${endCustomerId}.`);
    } else if (doesSubrangeOverlap) {
      addEndSubrangeError(id, 'This subrange has been used in another subrange.');
    } else if (doesSubrangeExist) {
      addEndSubrangeError(id, 'The given subrange already exists!');
    } else {
      removeEndSubrangeError(id);
      if (isStartError) {
        validateStartSubrange(subrange, startCustomerId, endCustomerId, id);
      }
    }
  }

  const handleStartSubrangeChange = (start, id) => (
    setCustomerIDsAndNames(
      {...customerIDsAndNames, 
        subranges: customerIDsAndNames
        .subranges
        .map(subrange => {
          if(subrange.id === id) {
            subrange.start = start;
            validateStartSubrange(subrange, customerIDsAndNames.start_customer_id, customerIDsAndNames.end_customer_id, id)
          }
          return subrange;
        })
      }
    )
  )

  const handleEndSubrangeChange = (end, id) => (
    setCustomerIDsAndNames(
      {...customerIDsAndNames, 
        subranges: customerIDsAndNames
        .subranges
        .map(subrange => {
          if(subrange.id === id) {
            subrange.end = end;
            validateEndSubrange(subrange, customerIDsAndNames.start_customer_id, customerIDsAndNames.end_customer_id, id)
          }
          return subrange;
        })
      }
    )
  )

  useEffect(() => {
    function checkCutomerIDsRangeAvailability() {
      const startCustomerId = Number(customerIDsAndNames.start_customer_id);
      const endCustomerId = Number(customerIDsAndNames.end_customer_id);
      if (startCustomerId !== 0 && endCustomerId !== 0 && endCustomerId > startCustomerId) {
        setIsAddButtonDisabled(false);
      } else {
        setIsAddButtonDisabled(true);
      }
    }
    checkCutomerIDsRangeAvailability();
  }, [customerIDsAndNames.start_customer_id, customerIDsAndNames.end_customer_id]);

  useEffect(() => {
    const areSubrangesEmpty = () => {
      const areSubrangesAvailable = _.isEmpty(customerIDsAndNames.subranges);
      areSubrangesAvailable && enableNextButton(dispatch);
    }

    const checkSubrangesForErrors = () => {
      const isErrorInSubranges = customerIDsAndNames.subranges.some(subrange => !!subrange.startErrorMessage || !!subrange.endErrorMessage || subrange.end === '')
        if (isErrorInSubranges) {
          disableNextButton(dispatch);
        } else {
          enableNextButton(dispatch);
        }
    }
    
    checkSubrangesForErrors();
    areSubrangesEmpty();
  },[dispatch, customerIDsAndNames.subranges]);

  const isErrorInSubrange = (id, error) => {
    const isError = customerIDsAndNames.subranges.some(subrange => {
      if (id === subrange.id) {
        return !!subrange[error]
      }
      return false;
    })
    return isError;
  }

  const startInputValue = (id) => {
    const value = customerIDsAndNames.subranges.filter((subrange) => {
      if (id === subrange.id) {
        return subrange.start
      }
      return ''
    })
    return value[0]?.start ? value[0]?.start : '';
  }

  const endInputValue = (id) => {
    const value =customerIDsAndNames.subranges.filter((subrange) => {
      if (id === subrange.id) {
        return subrange.end
      }
      return ''
    })
    return value[0]?.end ? value[0]?.end : '';
  }

  const showErrorMessage = (id, error) => {
    const message = customerIDsAndNames.subranges.map(subrange => {
      if (id === subrange.id) {
        return subrange[error]
      }
      return ''
    })
    return message;
  }

  return (
    <>
      {customerIDsAndNames.subranges?.map((subrange, index) => (
        <Zoom in key={`${subrange}${index}`}>
            <div key={subrange.id} className={classes.subrangeInputContainer}>
                <div className={`${classes.subrangeTitleContainer} ${classes.lightBlueColor}`}>
                    <IconButton
                    aria-label='delete'
                    className={classes.lightBlueColor}
                    onClick={() => deleteSubrange(subrange.id)}
                    data-cy='delete-subrange'
                    >
                    <HighlightOffIcon />
                    </IconButton>
                    <Typography className={classes.deleteButtonText}>
                    Festclub Subrange
                    #
                    {index + 1}
                    </Typography>
                </div>
                <div className={classes.subrangeInputsContainer}>
                    <TextField
                    required
                    name='start'
                    className={classes.subrangeInput}
                    label={matchesMobileScreen ? 'Start Subrange' : 'Subrange Start Customer ID (included)'}
                    type='number'
                    margin='normal'
                    value={startInputValue(subrange.id)}
                    error={isErrorInSubrange(subrange.id, 'startErrorMessage')}
                    helperText={showErrorMessage(subrange.id, 'startErrorMessage')}
                    onChange={(e) => handleStartSubrangeChange(e.target.value, subrange.id)}
                    />
                    <TextField
                    required
                    name='end'
                    label={matchesMobileScreen ? 'End Subrange' : 'Subrange End Customer ID (included)'}
                    type='number'
                    margin='normal'
                    className={classes.subrangeInput}
                    value={endInputValue(subrange.id)}
                    error={isErrorInSubrange(subrange.id, 'endErrorMessage')}
                    helperText={showErrorMessage(subrange.id, 'endErrorMessage')}
                    onChange={(e) => handleEndSubrangeChange(e.target.value, subrange.id)}
                    />
                </div>
            </div>
        </Zoom>
        )) }
        <div className={classes.subrangeButtonContainer}>
        <Tooltip title={isAddButtonDisabled ? 'Please add Customer IDs' : 'Add Festclub subrange'} placement='top'>
            <span>
            <Button
                color='primary'
                variant='outlined'
                disabled={isAddButtonDisabled}
                className={classes.subrangeButton}
                startIcon={<AddIcon style={{ fontSize: '1.5rem' }} />}
                onClick={addNewSubrange}
                data-cy='add-festclub'
            >
                Add a festclub subrange
            </Button>
            </span>
        </Tooltip>
        </div>
    </>
  );
}

FestclubSubranges.propTypes = {
  customerIDsAndNames: PropTypes.object.isRequired,
  setCustomerIDsAndNames: PropTypes.func.isRequired,
};
