import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import BranchesOverviewTable from '../../common/OverviewTable/OverviewTable';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    borderRadius: '1rem',
    boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
     0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
       0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
         0 100px 80px rgba(0, 0, 0, 0)`,
    marginBottom: '2rem',
  },
  cardActions: {
    boxShadow: 'inset 0 0 10px #0000001f',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    justifyContent: 'space-around',
    wordWrap: 'break-word',
  },
  dynamicHeight: {
    height: 'calc(100% - 64px)',
  },
  scrollableTable: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    backgroundColor: '#ececec',
  },
}));

/**
 * @description Displays overview using card.
 * @function OverviewDetailsMobile
 * @param  {staffOverviewSummary, staffOverviewDetails} props - all of props from the parent.
 * @returns {'<OverviewDetailsMobile>'}
 */
export default function OverviewDetailsMobile({ staffOverviewSummary, staffOverviewDetails }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getCardContentTypography = (leftSide, rightSide) => (
    <Typography
      className={classes.title}
      name='card-content-title'
      gutterBottom
      variant='h5'
      component='h5'
      key={leftSide}
    >
      {leftSide}
      {' '}
      {rightSide}
    </Typography>
  );

  return (
    <Card className={`${classes.card} custom-rounded-paper`}>
      <CardActionArea
        className={classes.dynamicHeight}
      >
        <CardContent onClick={handleExpandClick}>
          {
           Object.entries(staffOverviewSummary)
             .map((row) => getCardContentTypography(row[0], row[1]))
          }
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <IconButton
          className={classes.button}
          id='preview-group-branches-button'
          color='primary'
          onClick={handleExpandClick}
        >
          {
              expanded
                ? <ExpandLessIcon />
                : <ExpandMoreIcon />
            }
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent className={classes.scrollableTable}>
          <BranchesOverviewTable overviewDataObject={staffOverviewDetails} />
        </CardContent>
      </Collapse>
    </Card>
  );
}
