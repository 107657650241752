/* eslint-disable camelcase */

import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { updateDatabaseEvent } from '../../../../../../api/EventCalls';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import { normalizeDatabaseUpdateData } from '../../../../../../utils/normalizationFunctions';

const useStyles2 = makeStyles({
  centerWithPadding: {
    marginTop: '4rem',
    width: '80%',
  },
  alignButton: {
    marginLeft: 'auto',
    marginTop: '2rem',
  },
  iconInButton: {
    marginLeft: '5px',
  },
  textField: {
    fontSize: '6rem!important',
  },
  passRevealButtonDisabled: {
    color: 'gray',
  },
  passRevealButton: {
    color: 'black',
  },
});

export default function DBEditForm({
  eventState, setEventState, eventId, isDisabled, setIsDisabled,
}) {
  const classes = useStyles2();
  const { openSnackBar } = useContext(SnackBarContext);
  const [isShowPassword, setShowPassword] = useState(false);
  const [dbError, setDbError] = useState({
    text: '',
    isItWrong: false,
  });
  const initialDbHost = eventState.db_host;
  const [dbHost, setDbHost] = useState(initialDbHost);

  useEffect(() => {
    setEventState(eventState);
  }, [eventState, setEventState]);

  function handleDBHostChange(event) {
    // eslint-disable-next-line
    const webAddressExpr = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
    if (event.target.value.match(webAddressExpr)) {
      setDbError({
        text: '',
        isItWrong: false,
      });
      setDbHost(event.target.value);
      setEventState({
        ...eventState,
        db_host: event.target.value,
      });
    } else if (!event.target.value.match(webAddressExpr)) {
      setDbError({
        text: 'Please input a valid URL.',
        isItWrong: true,
      });
      setDbHost(event.target.value);
      setEventState({
        ...eventState,
        db_host: '',
      });
    }
  }

  function handleDBPasswordChange(event) {
    setEventState({
      ...eventState,
      db_password: event.target.value,
    });
  }

  function handleDBNameChange(event) {
    setEventState({
      ...eventState,
      db_name: event.target.value,
    });
  }

  function handleDBPortChange(event) {
    setEventState({
      ...eventState,
      db_port: event.target.value,
    });
  }

  function handleDBUserChange(event) {
    setEventState({
      ...eventState,
      db_user: event.target.value,
    });
  }

  const handleSaveButtonClick = async () => {
    try {
      const formData = normalizeDatabaseUpdateData(eventState);
      await updateDatabaseEvent(formData, eventId);
      openSnackBar('success', 'Database configuration saved', 'dbconfig-success-popup');
      setIsDisabled(!isDisabled);
    } catch (error) {
      openSnackBar('error', 'Ooops, something went wrong!', 'dbconfig-fail-popup');
    }
  };

  function handleEditButtonClick() {
    setIsDisabled(!isDisabled);
  }

  const changeShowPassword = () => setShowPassword(!isShowPassword);

  return (
    <Grid className={classes.centerWithPadding} justify='center' direction='column' container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-host'
            label='Database Host'
            type='text'
            margin='normal'
            value={dbHost}
            onChange={handleDBHostChange}
            disabled={isDisabled}
            className={classes.textField}
            error={dbError.isItWrong}
            helperText={dbError.text}
          />
        </Grid>
        <Grid item xs={12} md={6}>

          <TextField
            component='span'
            required
            fullWidth
            id='db-password'
            label='Database Password'
            type={isShowPassword ? 'text' : 'password'}
            margin='normal'
            value={eventState.db_password}
            onChange={handleDBPasswordChange}
            disabled={isDisabled}
            className={classes.textField}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button onClick={changeShowPassword}>
                    {
                        isDisabled
                        && (
                          isShowPassword ? (<VisibilityOffIcon className={classes.passRevealButtonDisabled} />)
                            : (<VisibilityIcon className={classes.passRevealButtonDisabled} />)
                        )
                      }
                    {
                        !isDisabled
                        && (
                          isShowPassword ? (<VisibilityOffIcon className={classes.passRevealButton} />)
                            : (<VisibilityIcon className={classes.passRevealButton} />)
                        )
                      }
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-name'
            label='Database Name'
            type='text'
            margin='normal'
            value={eventState.db_name}
            onChange={handleDBNameChange}
            disabled={isDisabled}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-port'
            label='Database Port'
            type='number'
            margin='normal'
            value={eventState.db_port}
            onChange={handleDBPortChange}
            disabled={isDisabled}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-user'
            label='Database User'
            type='text'
            margin='normal'
            value={eventState.db_user}
            onChange={handleDBUserChange}
            disabled={isDisabled}
            className={classes.textField}
          />
        </Grid>
      </Grid>
      <div className={classes.alignButton}>
        {isDisabled ? (
          <Button id='edit-event-database-button' variant='contained' color='primary' onClick={handleEditButtonClick}>
            Edit
            <EditIcon className={classes.iconInButton} />
          </Button>
        ) : (
          <Button id='save-event-database-button' variant='contained' color='primary' onClick={handleSaveButtonClick}>
            Save
            <SaveIcon className={classes.iconInButton} />
          </Button>
        )}
      </div>
    </Grid>

  );
}
