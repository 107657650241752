/* eslint-disable import/prefer-default-export */
import React, { createContext, useReducer } from 'react';

/**
 * @file CreateEventContext - context used for event creation
 */

const initialState = {
  isFormOpened: false,
  step: 0,
  isNextButtonDisabled: false,
};

export const CreateEventContext = createContext(initialState);

const openForm = (state) => ({ ...state, isFormOpened: true });

const closeForm = (state) => ({ ...state, isFormOpened: false, step: 0 });

const changeStep = (state, step) => ({ ...state, step });

const disableNextButton = (state) => ({ ...state, isNextButtonDisabled: true });

const enableNextButton = (state) => ({ ...state, isNextButtonDisabled: false });

/**
 * @description Reducer for creating the event
 * @method formReducer
 */
const formReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_FORM':
      return openForm(state);
    case 'CLOSE_FORM':
      return closeForm(state);
    case 'CHANGE_STEP':
      return changeStep(state, action.step);
    case 'DISABLE_NEXT_BUTTON':
      return disableNextButton(state);
    case 'ENABLE_NEXT_BUTTON':
      return enableNextButton(state);
    default:
      return state;
  }
};

/**
 * @description CreateEventProvider component
 * @method CreateEventProvider
 * @returns {'<CreateEventProvider>'} <CreateEventProvider>
 */
export const CreateEventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  return (
    <CreateEventContext.Provider
      value={{ state, dispatch }}
    >
      {children}
    </CreateEventContext.Provider>
  );
};
