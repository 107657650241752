/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  makeStyles,
  Dialog,
  IconButton,
  Typography,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { setIsDialogOpened } from '../../../../context/UserActions';
import { UserContext } from '../../../../context/UserContext';
import Transition from '../../../common/TransitionComponent/TransitionComponent';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#00468E',
    boxShadow: 'inset 0 0 100px #000000',
    color: 'white',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const useStyles = makeStyles({
  imageModal: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '1rem',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#00468E',
    boxShadow: 'inset 0 0 100px #000000',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function MapDialog({ isOpen, setIsOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userState, userDispatch } = useContext(UserContext);

  const handleClose = () => {
    setIsOpen(false);
    setIsDialogOpened(false, userDispatch);
  };

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      onClose={handleClose}
      aria-labelledby='map-dialog'
      TransitionComponent={Transition}
      open={isOpen}
    >
      <DialogTitle id='map-dialog-title' onClose={handleClose}>
        {t('mapDialog.dialogTitle')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <img
          className={classes.imageModal}
          src={userState.overview.event_map}
          alt='Event Map'
        />
      </DialogContent>
    </Dialog>
  );
}

MapDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
