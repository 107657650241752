import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import ChipsArray from './ChipsArray/ChipsArray';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';
import {
  addAlertsData,
  updateAlertsData,
  getAlertsData,
} from '../../../../../../../api/AlertCalls';
import IOSToggleSwitch from '../../../../../../common/IOSToggleSwitch/IOSToggleSwitch';

export default function AlertsModal({ openDialog, setOpenDialog, eventId }) {
  const { openSnackBar } = useContext(SnackBarContext);
  const [chipData, setChipData] = useState([]);
  const [hasInitialAlertData, setHasInitialAlertData] = useState(false);
  const [viberUrl, setViberUrl] = useState('');
  const [fetchError, setFetchError] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    getValues,
    setValue,
    control,
  } = useForm();

  const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const intervalRegex = /^[1-9][0-9]*$/;

  const emailsList = chipData.map((chip) => chip.label);

  const closeDialog = () => setOpenDialog(false);

  const isEmailValid = (mail) => emailRegex.test(mail);

  const isEmailInArray = (mail) => chipData.find((chip) => chip.label === mail);

  const addNewChip = (mail) => setChipData([...chipData, { label: mail }]);

  const addNewEmailToChips = () => {
    const email = getValues('email');

    if (!isEmailValid(email)) {
      setError('email', { message: 'Email is not valid.' });
    } else if (isEmailInArray(email)) {
      setError('email', { message: 'Email is already in the list.' });
    } else {
      addNewChip(email);
      setValue('email', '');
      clearErrors('email');
    }
  };

  const handleAddEmailButtonClick = () => addNewEmailToChips();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addNewEmailToChips();
    }
  };

  const handleSubscribeButton = () => {
    if (fetchError) {
      openSnackBar(
        'error',
        'Cannot redirect you to viber.',
        'viber-redirect-fail-popup',
      );
      closeDialog();
    } else {
      window.location.href = viberUrl;
      closeDialog();
    }
  };

  const handleAddAlertsData = async () => {
    const passiveInterval = getValues('passiveInterval');
    const noConnectionInterval = getValues('noConnectionInterval');
    const areEmailsAllowed = getValues('enabled');

    const postData = {
      event: eventId,
      emails: emailsList,
      passive_interval: passiveInterval,
      no_connection_interval: noConnectionInterval,
      enabled: areEmailsAllowed,
    };

    try {
      await addAlertsData(postData);
      openSnackBar(
        'success',
        'Your data for terminal alerts is saved.',
        'added-alerts-data-success-popup',
      );
    } catch (error) {
      openSnackBar(
        'error',
        'Something went wrong.',
        'added-alerts-data-fail-popup',
      );
    }
  };

  const handleUpdateAlertsData = async () => {
    const passiveInterval = getValues('passiveInterval');
    const noConnectionInterval = getValues('noConnectionInterval');
    const areEmailsAllowed = getValues('enabled');

    const patchData = {
      emails: emailsList,
      passive_interval: passiveInterval,
      no_connection_interval: noConnectionInterval,
      enabled: areEmailsAllowed,
    };

    try {
      await updateAlertsData(patchData, eventId);
      openSnackBar(
        'success',
        'Your data for terminal alerts is updated.',
        'updated-alerts-success-popup',
      );
    } catch (error) {
      openSnackBar(
        'error',
        'Something went wrong.',
        'updated-alerts-fail-popup',
      );
    }
  };

  const sendRequestData = async () => {
    if (hasInitialAlertData) {
      await handleUpdateAlertsData();
    } else {
      await handleAddAlertsData();
    }
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    closeDialog();
    await sendRequestData();
  };

  const setAlertsDataInDialog = useCallback(
    (backendResponse) => {
      if (backendResponse.data.passive_interval) {
        setHasInitialAlertData(true);

        const emailsFromResponse = backendResponse.data.emails;
        setChipData(emailsFromResponse.map((email) => ({ label: email })));
        setValue('passiveInterval', backendResponse.data.passive_interval);
        setValue('noConnectionInterval', backendResponse.data.no_connection_interval);
        setValue('enabled', backendResponse.data.enabled);
      }
    },
    [setValue],
  );

  useEffect(() => {
    const getViberAlertsData = async () => {
      try {
        const response = await getAlertsData(eventId);
        setFetchError(false);
        setViberUrl(response.data.message_bot_url);
        setAlertsDataInDialog(response);
      } catch (error) {
        setFetchError(true);
      }
    };
    getViberAlertsData();
  }, [eventId, openDialog, setValue, setAlertsDataInDialog]);

  return (
    <Dialog open={openDialog} onClose={closeDialog} scroll='body'>
      <DialogTitle>Alerts</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid
            style={{ marginBottom: '1rem' }}
            container
            display='flex'
            direction='column'
          >
            <Grid item style={{ textAlign: 'center', marginBottom: '1rem' }}>
              <Button
                variant='outlined'
                color='primary'
                size='small'
                id='alerts-dialog-subscribe-to-viber-button'
                onClick={handleSubscribeButton}
              >
                Subscribe to Viber
              </Button>
            </Grid>
            <Divider />
            <Grid item style={{ maxWidth: '343px' }}>
              <ChipsArray array={chipData} setArray={setChipData} />
            </Grid>
            <Divider />
            <Grid item>
              <TextField
                fullWidth
                margin='dense'
                name='email'
                id='alerts-dialog-email'
                label='Email'
                error={!!errors.email}
                helperText={errors?.email?.message}
                inputRef={register()}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      id='add-email-button'
                      onClick={handleAddEmailButtonClick}
                    >
                      Add
                    </Button>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                margin='dense'
                name='passiveInterval'
                id='alerts-dialog-passive-interval'
                label='Passive Interval ( Minutes )'
                inputRef={register({
                  pattern: {
                    value: intervalRegex,
                    message: 'This field should be a valid number.',
                  },
                })}
                error={!!errors.passiveInterval}
                helperText={errors?.passiveInterval?.message}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                margin='dense'
                name='noConnectionInterval'
                id='alerts-dialog-no-connection-interval'
                label='No Connection Interval ( Minutes )'
                inputRef={register({
                  pattern: {
                    value: intervalRegex,
                    message: 'This field should be a valid number.',
                  },
                })}
                error={!!errors.noConnectionInterval}
                helperText={errors?.noConnectionInterval?.message}
              />
            </Grid>
            <Grid>
              <Controller
                name='enabled'
                control={control}
                render={({ onChange, value }) => (
                  <IOSToggleSwitch
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    margin='normal'
                    label='Notifications'
                    labelPlacement='start'
                    id='alerts-dialog-switch'
                  />
                )}
                defaultValue
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '16px 20px' }}>
          <Button onClick={closeDialog} color='primary'>
            Cancel
          </Button>
          <Button
            id='save-button'
            variant='contained'
            type='submit'
            color='primary'
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

AlertsModal.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
};
