/* eslint-disable import/prefer-default-export */
import { loginAxios } from './LoginCalls';

/**
 * @description user overview api call
 * @async
 * @method getOverview
 * @param  {String} hash - card hash string
 * @returns {Object} response object
 */
export async function getOverview(hash) {
  const response = await loginAxios.get(`/users/${hash}/overview`);
  return response;
}

/**
 * @description Get user transactions api call
 * @async
 * @method getTransactions
 * @param  {String} hash - card hash string
 * @returns {Object} response object
 */
export async function getTransactions(hash) {
  const response = await loginAxios.get(`/users/${hash}/transactions`);
  return response;
}

/**
 * @description Update notifications language for user transactions api call
 * @async
 * @method updateNotificationsLanguage
 * @param  {String} hash, patchData
 * @returns {Object} response object
 */
export async function updateNotificationsLanguage(hash, patchData) {
  const response = await loginAxios.patch(`/viber/${hash}/`, patchData);
  return response;
}

/**
 * @description Get user viber subscription api call
 * @async
 * @method getUserSubscription
 * @param  {String} hash - card hash string
 * @returns {Object} response object
 */
export async function getUserSubscription(hash) {
  const response = await loginAxios.get(`/viber/${hash}/`);
  return response;
}

/**
 * @description Get amounts of reloading options api call
 * @async
 * @method getReloadOptions
 * @param  {String} hash - card hash string
 * @returns {Object} response object
 */
export async function getReloadOptions(hash) {
  const response = await loginAxios.get(`/users/${hash}/payment_options`);
  return response;
}

/**
 * @description Post reloading amount api call
 * @async
 * @method sendReloadRequest
 * @param  {String} hash, reloadData
 * @returns {Object} response object
 */
export async function sendReloadRequest(hash, reloadData) {
  const response = await loginAxios.post(`/users/${hash}/payments`, reloadData);
  return response;
}
/**
 * @description Post refund request api call
 * @async
 * @method sendRefundRequest
 * @param  {String} hash - card hash string
 * @returns {Object} response object
 */
export async function sendRefundRequest(hash) {
  const response = await loginAxios.post(`/users/${hash}/refunds`);
  return response;
}
