import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  image: {
    maxWidth: '50%',
    maxHeight: '50%',
    borderRadius: '4px',
    boxShadow: '0 8px 6px -6px black',
    alignSelf: 'center',
    marginBottom: '2rem',
  },
}));

const getDisplayPenniesAnswer = (isItOn) => (
  isItOn ? 'Yes' : 'No'
);

/**
 * @description ReviewStep component - Forth step in create event form
 * @method ReviewStep
 * @returns {'<ReviewStep>'} <ReviewStep>
 */
export default function ReviewStep({ eventGeneralInfo, customerIDsAndNames, dBConfig }) {
  const classes = useStyles();
  const noImage = {
    // eslint-disable-next-line global-require
    image: require('../../../../../common/Images/no-image-icon.png'),
  };
  let mapPhotoURL = noImage.image;
  if (customerIDsAndNames.map_photo !== '') {
    mapPhotoURL = URL.createObjectURL(customerIDsAndNames.map_photo);
  }
  let eventPhotoURL = noImage.image;
  if (customerIDsAndNames.event_photo !== '') {
    eventPhotoURL = URL.createObjectURL(customerIDsAndNames.event_photo);
  }
  let inactiveEventPhotoURL = noImage.image;
  if (customerIDsAndNames.not_active_image !== '') {
    inactiveEventPhotoURL = URL.createObjectURL(customerIDsAndNames.not_active_image);
  }

  const firstStepDetails = [
    { name: 'Event Name EN', desc: 'Event name in English', value: eventGeneralInfo.name_en },
    { name: 'Event Name BG', desc: 'Event name in Bulgarian', value: eventGeneralInfo.name_bg },
    { name: 'Currency', desc: 'Event currency', value: eventGeneralInfo.currency },
    { name: 'Display Pennies', desc: 'Display Pennies for event', value: getDisplayPenniesAnswer(eventGeneralInfo.display_pennies) },
    { name: 'Start Date', desc: 'The date when the event starts', value: format(Date.parse(eventGeneralInfo.start_time), 'yyyy-MM-dd HH:mm:ss') },
    { name: 'End Date', desc: 'The date when the event ends', value: format(Date.parse(eventGeneralInfo.end_time), 'yyyy-MM-dd HH:mm:ss') },
  ];

  const secondStepDetails = [
    { name: 'Start Customer ID', desc: 'Starting ID for customers', value: customerIDsAndNames.start_customer_id },
    { name: 'End Customer ID', desc: 'Ending ID for customers', value: customerIDsAndNames.end_customer_id },
  ];

  const thirdStepDetails = [
    { name: 'Database Host', desc: 'IP address of the host', value: dBConfig.db_host },
    { name: 'Database Name', desc: 'Name of the database', value: dBConfig.db_name },
    { name: 'Database User', desc: '', value: dBConfig.db_user },
    { name: 'Database Password', desc: '', value: dBConfig.db_password },
    { name: 'Database Port', desc: '', value: dBConfig.db_port },
  ];

  return (
    <>
      <Typography className={classes.title} component='h6' variant='h6' gutterBottom>
        Event Details
      </Typography>
      <Divider light />
      <List disablePadding>
        {firstStepDetails.map((field) => (
          <ListItem className={classes.listItem} key={field.name}>
            <ListItemText primary={field.name} secondary={field.desc} />
            <Typography component='span' variant='body2'>{field.value}</Typography>
          </ListItem>
        ))}

      </List>
      <Divider light />
      <List disablePadding id='second-step-details'>
        {secondStepDetails.map((field) => (
          <ListItem className={classes.listItem} key={field.name}>
            <ListItemText primary={field.name} secondary={field.desc} />
            <Typography component='span' variant='body2'>{field.value}</Typography>
          </ListItem>
        ))}
        {customerIDsAndNames.subranges.map((field, index) => (
          <ListItem className={classes.listItem} key={uuidv4()}>
            <ListItemText primary={`Festclub Subrange #${index + 1}`} secondary='Starting and Ending IDs for Festclub members' />
            <Typography component='span' variant='body2'>
              {`IDs: ${field.start} to ${field.end}`}
            </Typography>
          </ListItem>
        ))}
      </List>
      <Divider light />
      <List disablePadding>
        {thirdStepDetails.map((field) => (
          <ListItem className={classes.listItem} key={field.name}>
            <ListItemText primary={field.name} secondary={field.desc} />
            <Typography component='span' variant='body2'>{field.value}</Typography>
          </ListItem>
        ))}
      </List>
      <Divider light />
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Typography component='h6' className={classes.title} variant='h6' align='center' gutterBottom>
          Map photo:
        </Typography>
        <img className={classes.image} src={mapPhotoURL} alt='random' />
      </Box>
      <Divider light />
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Typography component='h6' className={classes.title} variant='h6' align='center' gutterBottom>
          Event Photo:
        </Typography>
        <img className={classes.image} src={eventPhotoURL} alt='random' />
      </Box>
      <Divider light />
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Typography component='h6' className={classes.title} variant='h6' align='center' gutterBottom>
          Inactive Event Photo:
        </Typography>
        <img className={classes.image} src={inactiveEventPhotoURL} alt='random' />
      </Box>
      <Divider light />
    </>
  );
}
