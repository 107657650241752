/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description Add data for terminal elerts api call
 * @async
 * @method addAlertsData
 * @param  {JSON} postData
 * @returns {Object} response object
 */
export async function addAlertsData(postData) {
  const response = await loggedInAxios.post('/alerts/', postData);
  return response;
}

export async function updateAlertsData(patchData, eventId) {
  const response = await loggedInAxios.patch(`/alerts/${eventId}/`, patchData);
  return response;
}

export async function getAlertsData(eventId) {
  const response = await loggedInAxios.get(`/alerts/${eventId}/`);
  return response;
}
