import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  Details as DetailsIcon,
  Map as MapIcon,
  CreditCard as CreditCardIcon,
  NotificationsActive as NotificationsActiveIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Info as InfoIcon,
  AccountBalance as AccountBalanceIcon,
  MoneyOff as MoneyOffIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import OverviewCard from '../OverviewCard/OverviewCard';
import BalanceOverviewCard from '../BalanceOverviewCard/BalanceOverviewCard';
import AboutCard from '../AboutCard/AboutCard';
import { setIsDialogOpened } from '../../../../../context/UserActions';
import { UserContext } from '../../../../../context/UserContext';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#00468E',
    color: 'white',
  },
  card: {
    borderRadius: '1rem',
    width: '90%',
    maskImage:
      '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
});

export default function MenuDialog({
  setCardToFlipBack,
  setCardToFlipFront,
  setIsTransactionsDialogOpen,
  setIsMapDialogOpen,
  handleNotificationsClick,
  handleAddMoneyClick,
  handleRefundMoneyClick,
}) {
  const classes = useStyles();
  const { userState, userDispatch } = useContext(UserContext);
  const { t } = useTranslation();

  const handleDetailsClick = () => {
    setCardToFlipBack(<OverviewCard />);
    setCardToFlipFront(<OverviewCard />);
  };

  const handleBalanceClick = () => {
    setCardToFlipFront(<BalanceOverviewCard />);
    setCardToFlipBack(<BalanceOverviewCard />);
  };

  const handleAboutUsClick = () => {
    setCardToFlipFront(<AboutCard />);
    setCardToFlipBack(<AboutCard />);
  };

  const handleTransactionsClick = () => {
    setIsDialogOpened(true, userDispatch);
    setIsTransactionsDialogOpen(true);
  };

  const handleMapClick = () => {
    setIsDialogOpened(true, userDispatch);
    setIsMapDialogOpen(true);
  };

  const isVposAvailable = userState?.overview?.vpos;
  const isFestclubMember = userState?.overview?.fest_club;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <List>
          <ListItem button onClick={handleBalanceClick}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <AccountBalanceWalletIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('menuDialog.balance-only')} />
          </ListItem>
          <ListItem button onClick={handleDetailsClick}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <DetailsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('menuDialog.more-details')} />
          </ListItem>
          <ListItem button onClick={handleTransactionsClick}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <CreditCardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('menuDialog.transactions')} />
          </ListItem>
          {isVposAvailable && (
            <ListItem button onClick={handleAddMoneyClick} id='add-money-button'>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t('menuDialog.add-money')} />
            </ListItem>
          )}
          {
            isVposAvailable && isFestclubMember && (
              <ListItem button onClick={handleRefundMoneyClick} id='refunds-button'>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <MoneyOffIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('menuDialog.refund-money')} />
              </ListItem>
            )
          }
          <ListItem button onClick={handleNotificationsClick} data-cy='notifications-button'>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <NotificationsActiveIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('menuDialog.notifications')} />
          </ListItem>
          <ListItem button onClick={handleMapClick}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <MapIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('menuDialog.event-map')} />
          </ListItem>
          <ListItem button onClick={handleAboutUsClick}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <InfoIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('menuDialog.about-us')} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

MenuDialog.propTypes = {
  setCardToFlipBack: PropTypes.func.isRequired,
  setCardToFlipFront: PropTypes.func.isRequired,
  setIsTransactionsDialogOpen: PropTypes.func.isRequired,
  setIsMapDialogOpen: PropTypes.func.isRequired,
  handleAddMoneyClick: PropTypes.func.isRequired,
  handleNotificationsClick: PropTypes.func.isRequired,
  handleRefundMoneyClick: PropTypes.func.isRequired,
};
