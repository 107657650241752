import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TransactionCard from '../TransactionCard/TransactionCard';
import './TransactionsCards.css';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardGrow: {
    display: 'flex',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: 'green',
    color: 'white',
  },
}));

export default function TransactionsCards({
  transactionsArray, chipData, handleChipDelete,
}) {
  const classes = useStyles();
  return (
    <>
      <div id='cards-container' className={classes.cardsContainer}>
        <div className={classes.chipContainer}>
          {chipData.map((data) => (
            <Chip
              key={data.key}
              label={data.label}
              onDelete={handleChipDelete(data)}
              className={classes.chip}
              size='medium'
            />
          ))}
        </div>
        {transactionsArray.map((singleTransaction) => (
          <TransactionCard key={singleTransaction.id} transaction={singleTransaction} />
        ))}
      </div>
    </>
  );
}
