/**
 * @description Method used for downloading given data
 * @method createDownloadLink
 * @param  {Object} data - Data for downloading
 * @param  {String} fileName - File name
 * @param {String} extension - File extension
 */
export const createDownloadLink = (data, fileName, extension) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `${fileName}${extension}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * @description Takes api call function, filetype and file name and downloads it
 * @async
 * @method download
 * @param  {Function} apiCallFunction - Api call function
 * @param  {String} extension - File extension, i.e '.txt'
 * @param {String} keyWord - File name
 * @returns {Object} response object
 */
export const download = async (apiCallFunction, extension, keyWord) => {
  const response = await apiCallFunction;
  const { data } = response;
  createDownloadLink(data, keyWord, extension);
  return response;
};

/**
 * @description Takes hours, minutes, seconds and returns today date with the time which is given
 * @method getDateWithHour
 * @param  {Number} hours - hours
 * @param  {Number} minutes - minutes
 * @param {Number} seconds - seconds
 * @returns {Object} Date object
 */
export const getDateWithHour = (hours, minutes, seconds) => {
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  return date;
};
