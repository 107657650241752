/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion, AccordionSummary, Typography, IconButton,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import './CustomAccordionStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  iconButton: {
    padding: '8px',
  },
  accordion: {
    alignItems: 'center',
  },
}));

export default function ControlledAccordions({
  id,
  external_id,
  nameBG,
  nameEN,
  handleDelete,
  handleEdit,
  editButtonID,
  deleteButtonID,
  cypressID,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>{external_id}</Typography>
          <Typography className={classes.heading}>{nameBG}</Typography>
          <Typography className={classes.heading} data-cy={cypressID}>{nameEN}</Typography>
          <IconButton
            onClick={() => handleEdit(id)}
            className={classes.iconButton}
            id={editButtonID}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(id, external_id)}
            className={classes.iconButton}
            id={deleteButtonID}
          >
            <DeleteIcon />
          </IconButton>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}

ControlledAccordions.propTypes = {
  id: PropTypes.number.isRequired,
  external_id: PropTypes.string.isRequired,
  nameBG: PropTypes.string.isRequired,
  nameEN: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  editButtonID: PropTypes.string.isRequired,
  deleteButtonID: PropTypes.string.isRequired,
};
