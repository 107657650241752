/* eslint consistent-return:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as NoConnectionIcon,
  Warning as PassiveIcon,
  CheckCircle as ActiveIcon,
} from '@material-ui/icons';
import './CustomAccordionStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '26%',
    flexShrink: 0,
  },
  status: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '12%',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  accordion: {
    alignItems: 'center',
  },
  fullWidthBorder: {
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  passiveIcon: {
    color: 'orange',
  },
  noConnectionIcon: {
    color: 'red',
  },
  activeIcon: {
    color: 'green',
  },
}));

export default function TerminalsAccordion({
  id,
  externalId,
  nameBG,
  nameEN,
  state,
  timeLastTransaction,
  timeLastVisit,
  handleDelete,
  handleEdit,
  showButtons,
  cypressID,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const formatDate = (date) => format(Date.parse(date), 'MMM do yyyy, HH:mm:ss');

  const handleChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return <ActiveIcon className={classes.activeIcon} />;
      case 'PASSIVE':
        return <PassiveIcon className={classes.passiveIcon} />;
      case 'NO_CONNECTION':
        return <NoConnectionIcon className={classes.noConnectionIcon} />;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>{externalId}</Typography>
          <Typography className={classes.heading}>{nameBG}</Typography>
          <Typography className={classes.heading} data-cy={cypressID}>{nameEN}</Typography>
          <Typography className={classes.status}>{handleStatus(state)}</Typography>
          {showButtons && (
            <>
              <IconButton
                onClick={() => handleEdit(id)}
                className={classes.iconButton}
                id='edit-terminal-button'
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(id, externalId)}
                className={classes.iconButton}
                id='delete-terminal-button'
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Table border={0} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Time Last Transaction</TableCell>
                <TableCell>Time Last Visit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{timeLastTransaction ? formatDate(timeLastTransaction) : 'N/A'}</TableCell>
                <TableCell>{timeLastVisit ? formatDate(timeLastVisit) : 'N/A'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

TerminalsAccordion.propTypes = {
  id: PropTypes.number.isRequired,
  externalId: PropTypes.string.isRequired,
  nameBG: PropTypes.string.isRequired,
  nameEN: PropTypes.string.isRequired,
  timeLastTransaction: PropTypes.string,
  timeLastVisit: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  showButtons: PropTypes.bool.isRequired,
};
