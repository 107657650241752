import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CombinedContextProvider from './context/CombinedContextProvider';

import Routes from './components/routes/routes';

/**
 * @description Main App component
 * @method App
 * @returns {'<CombinedContextProvider>'} <AppBar>
 */
function App() {
  return (
    <CombinedContextProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </CombinedContextProvider>
  );
}
export default App;
