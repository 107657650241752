import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StyledPaper from '../../../../../common/StyledPaper/StyledPaper';
import DBEditForm from './EditDatabaseForm';
import DBEditFormMobile from './EditDatabaseFormMobile';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  alignTextCenter: {
    textAlign: 'center',
  },
}));

export default function EditDatabaseDetails({ event }) {
  const [eventState, setEventState] = useState(event);
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);

  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth='lg' className={classes.container}>
        { eventState
          && (
          <Grid container spacing={3} direction='row' justify='center'>
            <Grid item xs={12} md={8} lg={9}>
              {
                  matchesDesktopScreen
                  && (
                    <StyledPaper>
                      <DBEditForm
                        eventState={eventState}
                        setEventState={setEventState}
                        eventId={event.id}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                      />
                    </StyledPaper>
                  )
                }
              {
                  matchesMobileScreen
                  && (
                    <Paper>
                      <DBEditFormMobile
                        eventState={eventState}
                        setEventState={setEventState}
                        eventId={event.id}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                      />
                    </Paper>
                  )
                }

            </Grid>
          </Grid>
          )}
      </Container>
    </div>
  );
}
