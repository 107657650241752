/* eslint consistent-return:0 */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

useInterval.propTypes = {
  callback: PropTypes.func.isRequired,
  delay: PropTypes.number.isRequired,
};
