import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

/**
 * @description DBConfigStep component - Third step in create event form
 * @method DBConfigStep
 * @returns {'<DBConfigStep>'} <DBConfigStep>
 */
export default function DBConfigStep({ dBConfig, setDBConfig }) {
  const [dbError, setDbError] = useState({
    text: '',
    isItWrong: false,
  });
  const [dbHost, setDbHost] = useState('');

  function handleDBHostChange(event) {
    // eslint-disable-next-line
    const webAddressExpr = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
    if (event.target.value.match(webAddressExpr)) {
      setDbError({
        text: '',
        isItWrong: false,
      });
      setDbHost(event.target.value);
      setDBConfig({
        ...dBConfig,
        db_host: event.target.value,
      });
    } else if (!event.target.value.match(webAddressExpr)) {
      setDbError({
        text: 'Please input a valid DB address.',
        isItWrong: true,
      });
      setDbHost(event.target.value);
    }
  }

  function handleDBPasswordChange(event) {
    setDBConfig({
      ...dBConfig,
      db_password: event.target.value,
    });
  }

  function handleDBNameChange(event) {
    setDBConfig({
      ...dBConfig,
      db_name: event.target.value,
    });
  }

  function handleDBPortChange(event) {
    setDBConfig({
      ...dBConfig,
      db_port: event.target.value,
    });
  }

  function handleDBUserChange(event) {
    setDBConfig({
      ...dBConfig,
      db_user: event.target.value,
    });
  }

  return (
    <>
      <Typography component='h6' variant='h6' gutterBottom>
        Add Database Configuration
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-host'
            label='Database Host'
            type='text'
            margin='normal'
            value={dbHost}
            onChange={handleDBHostChange}
            error={dbError.isItWrong}
            helperText={dbError.text}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-password'
            label='Database Password'
            type='password'
            margin='normal'
            value={dBConfig.db_password}
            onChange={handleDBPasswordChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-name'
            label='Database Name'
            type='text'
            margin='normal'
            value={dBConfig.db_name}
            onChange={handleDBNameChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-port'
            label='Database Port'
            type='number'
            margin='normal'
            value={dBConfig.db_port}
            onChange={handleDBPortChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            component='span'
            required
            fullWidth
            id='db-user'
            label='Database User'
            type='text'
            margin='normal'
            value={dBConfig.db_user}
            onChange={handleDBUserChange}
          />
        </Grid>

      </Grid>

    </>
  );
}
