import React, {
  useEffect, useContext, useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import EditEventNavigation from './Navigation/EditEventNavigation';
import EditDatabaseDetails from './Components/DatabaseEdit/EditDatabaseDetails';
import Groups from './Components/Groups/Groups';
import BaseDetails from './Components/InitialEdit/BaseDetails';
import Branches from './Components/Branches/Branches';
import Terminals from './Components/Terminals/Terminals';
import { EventsContext } from '../../../../context/EventsContext';
import { GroupsContextProvider } from '../../../../context/GroupsContext';
import './EditEvent.css';
import { getEvents } from '../../../../api/EventCalls';
import { addEventsData } from '../../../../context/EventsActions';
import Staff from './Components/Staff/Staff';
import Payments from './Components/Payments/Payments';
import Refunds from './Components/Refunds/Refunds';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  baseDetails: {
    marginTop: '20rem',
  },
}));

export default function EditEvent({ event }) {
  const [eventState, setEventState] = useState(event);
  const classes = useStyles();
  const currentURL = useLocation();
  let { id } = useParams();
  id = Number(id);
  const { eventsDispatch } = useContext(EventsContext);
  function getComponentsByPath() {
    switch (currentURL.pathname) {
      case `/edit/${id}`:
        return <BaseDetails className={classes.baseDetails} event={eventState} />;
      case `/edit/${id}/database`:
        return <EditDatabaseDetails className={classes.baseDetails} event={eventState} />;
      case `/edit/${id}/groups`:
        return (
          <GroupsContextProvider>
            <Groups className={classes.baseDetails} event={eventState} />
          </GroupsContextProvider>
        );
      case `/edit/${id}/branches`:
        return (
          <Branches className={classes.baseDetails} event={eventState} />
        );
      case `/edit/${id}/terminals`:
        return (
          <Terminals className={classes.baseDetails} event={eventState} />
        );
      case `/edit/${id}/staff`:
        return (
          <Staff className={classes.baseDetails} event={eventState} />
        );
      case `/edit/${id}/payments`:
        return (
          <Payments className={classes.baseDetails} eventId={eventState.id} eventCurrency={eventState.currency} />
        );
      case `/edit/${id}/refunds`:
        return (
          <Refunds className={classes.baseDetails} />
        );
      default:
        return (
          <></>
        );
    }
  }

  useEffect(() => {
    async function addData() {
      const events = await getEvents();
      addEventsData(events.data.reverse(), eventsDispatch);
      setEventState(events.data.reverse().find((a) => a.id === Number(id)));
    }
    addData();
  }, [eventsDispatch, id]);

  return (
    <div className={classes.root}>
      <EditEventNavigation eventId={id} />
      { eventState && getComponentsByPath()}
    </div>
  );
}
