import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  alignToTheSides: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
    boxShadow: '0 8px 6px -6px #3f51b508',
  },
  centerWithPadding: {
    width: '75%',
    marginTop: '4rem',
  },
}));

export default function OverviewDetails({ ownerOverviewSummary, ownerOverviewDetails }) {
  const classes = useStyles();

  const getStylisedRow = (leftField, rightField) => (
    <div key={leftField} className={classes.alignToTheSides}>
      <Typography component='h5' variant='h5'>
        {leftField}
      </Typography>
      <Typography component='h5' variant='h5'>
        {rightField}
      </Typography>
    </div>
  );

  return (
    <Grid
      className={classes.centerWithPadding}
      justify='center'
      direction='column'
      container
    >
      {
        Object.entries(ownerOverviewSummary)
          .map((row) => getStylisedRow(row[0], row[1]))
      }
      {
        Object.entries(ownerOverviewDetails)
          .map((row) => getStylisedRow(row[0], row[1]))
      }
    </Grid>
  );
}
