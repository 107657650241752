import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@material-ui/core';
import TerminalOverviewTable from '../../../../common/OverviewTable/OverviewTable';
import './AccordionStack.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    borderRadius: '0px!important',
    alignItems: 'center',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0),0px 0px 1px 0px rgba(0, 0, 0, 0),0px 1px 2px 0px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: 'inset 0 0 10px #00000014',
    },
  },
  accordionDetails: {
    boxShadow: 'inset 0 0 10px #0000006b',
  },
  expandedShadow: {
    boxShadow: 'inset 0 0 5px #00000014',
  },
}));

export default function StaffAccordion({ singleTerminal }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const overviewData = {
    [t('staff.loaded')]: singleTerminal.balance.deposited || '',
    [t('staff.spent')]: singleTerminal.balance.spent || '',
    [t('staff.withdrawn')]: singleTerminal.balance.withdrawn || '',
    [t('staff.undone')]: singleTerminal.balance.canceled || '',
    [t('staff.count')]: singleTerminal.balance.total_transactions || '',
    [t('staff.lastdate')]: singleTerminal.balance.last_transaction.replace('T', ' ') || '',
  };
  const handleChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        className={classes.accordion}
        expanded={expanded === true}
        onChange={handleChange(true)}
      >
        <AccordionSummary
          elevation={0}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          className={expanded ? classes.expandedShadow : ''}
        >
          <Typography className={classes.heading}>{ singleTerminal.name.external_id }</Typography>
          <Typography className={classes.heading}>
            {
              singleTerminal.name.bg || ''
            }
          </Typography>
          <Typography className={classes.heading}>
            {
              singleTerminal.name.en || ''
            }
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <TerminalOverviewTable overviewDataObject={overviewData} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
