/* eslint-disable import/prefer-default-export */
import { loginAxios } from './LoginCalls';

/**
 * @description Get Group Overview for event api call
 * @async
 * @method getGroupOverview
 * @param  {String} groupHash
 * @returns {Object} response object
 */
export async function getGroupOverview(groupHash) {
  const response = await loginAxios.get(`/owners/${groupHash}/overview`);
  return response;
}

/**
 * @description Get Branches Overview for group api call
 * @async
 * @method getBranchesOverview
 * @param  {String} groupHash
 * @returns {Object} response object
 */
export async function getBranchesOverview(groupHash) {
  const response = await loginAxios.get(`/owners/${groupHash}/branches`);
  return response;
}

/**
 * @description Get Terminals Overview for group api call
 * @async
 * @method getTerminalsOverview
 * @param  {String} groupHash
 * @returns {Object} response object
 */
export async function getTerminalsOverview(groupHash) {
  const response = await loginAxios.get(`/owners/${groupHash}/terminals`);
  return response;
}

/**
 * @description Get Overview for group filtered by date api call
 * @async
 * @method getFilteredOverview
 * @param  {String} groupHash, startDate, endDate
 * @returns {Object} response object
 */
export async function getFilteredOverview(groupHash, startDate, endDate) {
  const response = await loginAxios.get(`/owners/${groupHash}/overview?start=${startDate}&end=${endDate}`);
  return response;
}

/**
 * @description Get Filtered Terminals Overview for group api call
 * @async
 * @method getFilteredTerminalsOverview
 * @param  {String} groupHash, startDate, endDate
 * @returns {Object} response object
 */
export async function getFilteredTerminalsOverview(groupHash, startDate, endDate) {
  const response = await loginAxios.get(`/owners/${groupHash}/terminals?start=${startDate}&end=${endDate}`);
  return response;
}

/**
 * @description Get Filtered Branches Overview for group api call
 * @async
 * @method getFilteredBranchesOverview
 * @param  {String} groupHash, startDate, endDate
 * @returns {Object} response object
 */
export async function getFilteredBranchesOverview(groupHash, startDate, endDate) {
  const response = await loginAxios.get(`/owners/${groupHash}/branches?start=${startDate}&end=${endDate}`);
  return response;
}
