import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import BranchesOverviewTable from '../../common/OverviewTable/OverviewTable';
import './BranchesCard.css';

const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: '1rem',
    boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
     0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
       0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
         0 100px 80px rgba(0, 0, 0, 0)`,
    marginBottom: '2rem',
  },
  media: {
    height: 140,
  },
  cardActions: {
    boxShadow: 'inset 0 0 10px #0000001f',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    justifyContent: 'space-around',
    wordWrap: 'break-word',
  },
  dynamicHeight: {
    height: 'calc(100% - 64px)',
  },
  scrollableTable: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    backgroundColor: '#ececec',
  },
});

/**
 * @description Displays branch details using card.
 * @function BranchesCard
 * @param  {props} props - all of props from the parent.
 * @returns {'<BranchesCard>'} - Card component
 */
export default function BranchesCard({ singleBranch }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const overviewData = {
    [t('owners.loaded')]: singleBranch.total_deposited || '',
    [t('owners.spent')]: singleBranch.total_spent || '',
    [t('owners.withdrawn')]: singleBranch.total_withdrawn || '',
    [t('owners.undone')]: singleBranch.total_canceled_transactions || '',
    [t('owners.count')]: singleBranch.total_transactions || '',
    [t('owners.lastdate')]: singleBranch.last_transaction_time.replace('T', ' ') || '',
  };

  const handleExpandClick = async () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={`${classes.card} custom-rounded-paper`}>
      <CardActionArea
        className={classes.dynamicHeight}
      >
        <CardContent onClick={handleExpandClick}>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            {t('owners.branch_name')}
            {' '}
            ID:
            {' '}
            {singleBranch.branch.external_id}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            {t('owners.name')}
            {' '}
            BG:
            {' '}
            {singleBranch.branch.bg}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            {t('owners.name')}
            {' '}
            EN:
            {' '}
            {singleBranch.branch.en}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <IconButton
          className={classes.button}
          id='preview-group-branches-button'
          color='primary'
          onClick={handleExpandClick}
        >
          {
              expanded
                ? <ExpandLessIcon />
                : <ExpandMoreIcon />
            }
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent className={classes.scrollableTable}>
          <BranchesOverviewTable overviewDataObject={overviewData} />
        </CardContent>
      </Collapse>
    </Card>

  );
}
