import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { getTerminalsOverview, getFilteredTerminalsOverview } from '../../../../../../api/StaffCalls';
import { addTerminalsOverview } from '../../../../../../context/StaffActions';
import { StaffContext } from '../../../../../../context/StaffContext';
import BranchCardsMobile from './BranchesCardsMobile/BranchesCardsMobile';
import BranchCardsDesktop from './BranchesCardsDesktop/BranchesCardsDesktop';
import FilterByDateDialog from '../common/FilterByDateDialog/FilterByDateDialog';
import CustomSpeedDial from '../common/CustomSpeedDial/CustomSpeedDial';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import ChipContainer from '../common/ChipContainer/ChipContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
}));

export default function TerminalsOverview() {
  const classes = useStyles();
  const { staffState, staffDispatch } = useContext(StaffContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation();

  const { hash } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [chipData, setChipData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterDialogOpened, setIsFilterDialogOpened] = useState(false);

  const filterByDateHandler = async (startDate, endDate) => {
    const start = format(Date.parse(startDate), "yyyy-MM-dd'T'HH:mm:ss");
    const end = format(Date.parse(endDate), "yyyy-MM-dd'T'HH:mm:ss");
    try {
      const filteredTerminals = await getFilteredTerminalsOverview(hash, start, end);
      const groupedTerminals = _.groupBy(filteredTerminals.data, 'branch.external_id');
      addTerminalsOverview(groupedTerminals, staffDispatch);
      setIsFilterApplied(true);
      setIsFilterDialogOpened(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        openSnackBar(
          'error',
          error.response.data.message,
          'filter-transactions-fail-popup',
        );
      } else {
        openSnackBar(
          'error',
          'Failed to filter transactions.',
          'filter-transactions-terminals-fail-popup',
        );
      }
    }
  };

  const speedDialActions = [
    {
      icon: <DateRangeIcon />,
      name: 'Filter By Date',
      id: 'filter-by-date',
      handler: () => { setIsFilterDialogOpened(true); },
    },
  ];

  const handleChipDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    if (chipToDelete.label === t('transactionsDialog.chips.dateFilter')) {
      setIsFilterApplied(false);
    }
  };

  useEffect(
    () => {
      if (isFilterApplied) {
        setChipData((c) => [...c, {
          key: 0,
          label: t('transactionsDialog.chips.dateFilter'),
        }]);
      }
    },
    [isFilterApplied, t],
  );

  useEffect(() => {
    async function addData() {
      try {
        if (!isFilterApplied) {
          const terminalsOverview = await getTerminalsOverview(hash);
          const groupedTerminals = _.groupBy(terminalsOverview.data, 'branch.external_id');
          addTerminalsOverview(groupedTerminals, staffDispatch);
        }
      } catch (error) {
        if (error.response.status === 400) {
          if (error.response.data.not_active_image) history.push(`/staff/${hash}/event-not-active`);
          else {
            openSnackBar(
              'error',
              'Something went wrong.',
              'get-terminals-overview-fail-popup',
            );
          }
        } else {
          history.push('/404');
        }
      }
    }
    addData();
  }, [history, isFilterApplied, hash, staffDispatch, openSnackBar]);

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth='lg' className={classes.container}>
        <ChipContainer
          className={classes.chipContainer}
          chipData={chipData}
          handleChipDelete={handleChipDelete}
        />
        <Grid container spacing={3} direction='row' justify='center'>
          <Grid item xs={12} md={8} lg={9}>
            {
                matchesDesktopScreen
              && (
                <BranchCardsDesktop
                  branchesWithTerminals={staffState.terminalsOverview}
                  className={classes.root}
                />
              )
            }
            {
                matchesMobileScreen
              && (
                <BranchCardsMobile
                  branchesWithTerminals={staffState.terminalsOverview}
                />
              )
            }
          </Grid>
        </Grid>
      </Container>
      <FilterByDateDialog
        isFilterDialogOpened={isFilterDialogOpened}
        setIsFilterDialogOpened={setIsFilterDialogOpened}
        filterHandler={filterByDateHandler}
      />
      <CustomSpeedDial speedDialActions={speedDialActions} />
    </div>
  );
}
