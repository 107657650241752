/* eslint-disable global-require */
/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  card: {
    borderRadius: '1rem',
    width: '90%',
    maskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
  header: {
    boxShadow: '0 2px 7px -6px black',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    justifyContent: 'center',
    fontSize: '1.2rem',
  },
  alignLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: '1rem',
  },
});

export default function BalanceOverviewCard() {
  const classes = useStyles();
  const images = {
    // eslint-disable-next-line global-require
    altscale: require('../../../../common/Images/altscale-logo.png'),
    comitex: require('../../../../common/Images/comitex-logo.png'),
  };
  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.header}
        title='Powered by:'
      />
      <CardContent
        className={classes.cardContent}
      >
        <a className={classes.alignLogo} href='https://altscale.com/en/' target='blank'>
          <img style={{ width: '100%' }} src={images.altscale} alt='Altscale logo' />
        </a>
        <a className={classes.alignLogo} href='https://comitex.net/' target='blank'>
          <img style={{ width: '100%' }} src={images.comitex} alt='Comitex logo' />
        </a>
      </CardContent>
    </Card>
  );
}
