import React from 'react';
import GroupCard from './GroupCard';

export default function GroupsCards({ cardsArray, handleEdit, handleDelete }) {
  return (
    <div>
      {cardsArray.map((card) => (
        <GroupCard
          id={card.id}
          nameBG={card.name_bg}
          nameEN={card.name_en}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          branchesArray={card.branches}
          key={card.id}
        />
      ))}
    </div>
  );
}
