import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import MainContent from './CardContents/MainContent';
import DetailsContent from './CardContents/DetailsContent';

import './TransactionCard.css';

const useStyles = makeStyles({
  card: {
    margin: '1rem',
    alignSelf: 'center',
    width: '88%',
    minHeight: '300px',
    borderRadius: '1rem',
    maskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
  switchButton: {
    borderRadius: '100vh',
    width: '100px',
    boxShadow: 'none',
  },
  alignCenterButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default function TransactionCard({ transaction }) {
  const classes = useStyles();
  const [buttonClicked, setButtonClicked] = useState('main');
  const [buttonVariants, setButtonVariants] = useState({
    main: 'contained',
    details: 'text',
  });
  const { t } = useTranslation();
  const handleDetailsContentClick = () => {
    setButtonVariants({
      main: 'text',
      details: 'contained',
    });
    setButtonClicked('details');
  };
  const handleMainContentClick = () => {
    setButtonVariants({
      main: 'contained',
      details: 'text',
    });
    setButtonClicked('main');
  };
  return (
    <Card id='transaction-card' className={classes.card}>
      <CardContent>
        <div className={classes.alignCenterButtons}>
          <Button
            className={classes.switchButton}
            size='large'
            color='primary'
            variant={buttonVariants.main}
            onClick={handleMainContentClick}
          >
            {t('transactionCard.buttons.main')}
          </Button>
          <Button
            className={classes.switchButton}
            size='large'
            color='primary'
            onClick={handleDetailsContentClick}
            variant={buttonVariants.details}
          >
            {t('transactionCard.buttons.details')}
          </Button>
        </div>
        {buttonClicked === 'main'
          ? <MainContent transaction={transaction} />
          : <DetailsContent transaction={transaction} /> }
      </CardContent>
    </Card>
  );
}
