/* eslint-disable camelcase */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import Papa from 'papaparse';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PlusOneRoundedIcon from '@material-ui/icons/PlusOneRounded';
import NoteAddRoundedIcon from '@material-ui/icons/NoteAddRounded';
import { useParams } from 'react-router-dom';
import AddBranchModal from './AddBranchModal/AddBranchModal';
import EditBranchModal from './EditBranchModal/EditBranchModal';
import AccordionStackBranches from '../../../../../common/AccordionStack/AccordionStack';
import { DialogContext } from '../../../../../../context/DialogContext';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import { getGroups } from '../../../../../../api/GroupsCalls';
import { getBranch, deleteBranch, addBranch } from '../../../../../../api/BranchesCalls';
import { checkForDuplicatedIDs } from '../../../../../../utils/csvFilesIDsValidation/csvFilesIDsValidation';
import BranchesCards from './BranchesCards/BranchesCards';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export default function Branches() {
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [openNewBranchDialog, setOpenNewBranchDialog] = useState(false);
  const [openEditBranchDialog, setOpenEditBranchDialog] = useState(false);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [branchToEdit, setBranchToEdit] = useState({});
  const classes = useStyles();
  let { id } = useParams();
  id = Number(id);
  const [branches, setBranches] = useState([]);
  const [branchIDEdit, setBranchIDEdit] = useState('');
  const [csvAdded, setCsvAdded] = useState(false);
  const inputCSVRef = useRef(null);

  async function addBranchesToArray(branchesArray) {
    return Promise.all(
      branchesArray.map(async (branchId) => {
        const branchDetails = await getBranch(branchId);
        return branchDetails.data;
      }),
    );
  }

  useEffect(() => {
    async function addData() {
      const groupsForEvent = await getGroups(id);
      const defaultGroup = groupsForEvent.data.find((group) => group.is_default === true);
      const branchesFromGroup = await addBranchesToArray(defaultGroup.branches);
      setBranches(branchesFromGroup);
    }
    addData();
  }, [openNewBranchDialog, openEditBranchDialog, id, csvAdded]);

  const handleDelete = (branchID, external_id) => {
    const deleteHandlerForForm = async () => {
      try {
        await deleteBranch(branchID);
        const groupsForEvent = await getGroups(id);
        const defaultGroup = groupsForEvent.data.find((group) => group.event_id === id && group.is_default === true);
        const branchesFromGroup = await addBranchesToArray(defaultGroup.branches);
        setBranches(branchesFromGroup);
        openSnackBar('info', `Branch with ID ${external_id} deleted.`, 'delete-branch-success-popup');
      } catch (error) {
        openSnackBar('error', 'Something went wrong!', 'delete-branch-fail-popup');
      }
      closeDialog();
    };
    openDialog(
      'error',
      'Delete',
      `Branch with ID ${external_id} will be deleted!`,
      'Are you sure?',
      'delete-branch-modal-button',
      deleteHandlerForForm,
    );
  };

  async function handleEdit(branchID) {
    const branch = await getBranch(branchID);
    setBranchToEdit(branch.data);
    setBranchIDEdit(branchID);
    setOpenEditBranchDialog(true);
  }

  const handleActionButtonClose = () => {
    setOpenSpeedDial(false);
  };

  const handleActionButtonOpen = () => {
    setOpenSpeedDial(true);
  };

  const handleAddSingleBranch = () => {
    setOpenNewBranchDialog(true);
  };

  const handleAddCVSForBranches = () => {
    inputCSVRef.current.click();
  };

  async function deleteAllBranches() {
    try {
      const groupsForEvent = await getGroups(id);
      const defaultGroup = groupsForEvent.data.find((group) => group.event_id === id && group.is_default === true);
      Promise.all(
        defaultGroup.branches.map(async (branchID) => { await deleteBranch(branchID); }),
      );
    } catch (error) {
      openSnackBar('error', 'Something went wrong!', 'delete-branches-fail-popup');
    }
  }

  async function addBranchesFromFile(branchesArray) {
    return Promise.all(
      branchesArray.map(async (branch) => {
        // eslint-disable-next-line no-param-reassign
        branch.event_id = id;
        await addBranch(branch);
      }),
    );
  }

  const arrayToBranchObject = (values) => {
    const keys = ['external_id', 'name_bg', 'name_en'];
    return keys.reduce((o, k, i) => ({ ...o, [k]: values[i] }), {});
  };

  function createBranchesArray(multipleBranchesArray) {
    return multipleBranchesArray.map((singleBranch) => arrayToBranchObject(singleBranch));
  }

  const handleCSVInputChange = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      async complete(results) {
        results.data.pop();
        const normalisedBranches = createBranchesArray(results.data);
        const isDuplicatedId = checkForDuplicatedIDs(normalisedBranches);
        if (isDuplicatedId) {
          openSnackBar('error', 'There are duplicated IDs, please check your csv file!', 'duplicated-ids-fail-terminal-popup');
          return;
        }
        try {
          await deleteAllBranches();
          await addBranchesFromFile(normalisedBranches);
          setCsvAdded(!csvAdded);
          openSnackBar('success', 'Branches succesfully added from file.', 'add-branches-from-file-success-popup');
        } catch (error) {
          openSnackBar('error', 'Something went wrong!', 'add-branches-from-file-fail-popup');
        }
      },
    });
  };

  const speedDialActions = [
    {
      icon: <PlusOneRoundedIcon />,
      name: 'Add a Single Branch',
      id: 'add-single-branch',
      handler: handleAddSingleBranch,
    },
    {
      icon: <NoteAddRoundedIcon />,
      name: 'Upload CSV With Branches',
      id: 'add-csv-branches',
      handler: handleAddCVSForBranches,
    },
  ];

  return (
    <div className={classes.content}>
      <Container maxWidth='lg' className={classes.container}>
        {
          matchesDesktopScreen && (
            <Paper className={classes.root}>
              <AccordionStackBranches
                headingID='Branch ID'
                panelsArray={branches}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                editButtonID='edit-branch-button'
                deleteButtonID='delete-branch-button'
                cypressID='branch-name-en'
              />
            </Paper>
          )
        }
        {
          matchesMobileScreen && (
            <BranchesCards
              cardsArray={branches}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          )
        }
        <SpeedDial
          ariaLabel='SpeedDial example'
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleActionButtonClose}
          onOpen={handleActionButtonOpen}
          open={openSpeedDial}
          direction='up'
          id='speed-dial'
        >
          {
            speedDialActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
                id={action.id}
                data-cy={action.id}
                htmlFor={action.id}
              />
            ))
          }
        </SpeedDial>
      </Container>
      <AddBranchModal
        openDialog={openNewBranchDialog}
        setOpenDialog={setOpenNewBranchDialog}
        eventId={id}
      />
      <EditBranchModal
        openDialog={openEditBranchDialog}
        setOpenDialog={setOpenEditBranchDialog}
        eventId={id}
        branchToEdit={branchToEdit}
        setBranchToEdit={setBranchToEdit}
        branchIDEdit={branchIDEdit}
      />
      <input
        ref={inputCSVRef}
        type='file'
        id='csv-branches-file'
        accept='.csv'
        onChange={handleCSVInputChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
