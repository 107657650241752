import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { trackPromise } from 'react-promise-tracker';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';
import { addStaffMember } from '../../../../../../../api/StaffCalls';

export default function AddStaffMemberModal({
  openDialog,
  setOpenDialog,
  eventId,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };
  const { openSnackBar } = useContext(SnackBarContext);
  const [nameBG, setNameBG] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [staffMemberID, setStaffMemberID] = useState('');
  async function handleAddMember() {
    const postData = {
      external_id: staffMemberID,
      name_bg: nameBG,
      name_en: nameEN,
      event_id: eventId,
    };
    await trackPromise(
      addStaffMember(postData)
        .then(() => openSnackBar('success', `Staff member with name ${nameEN} added.`, 'add-staff-member-success-popup'))
        .catch(() => openSnackBar('error', 'Something went wrong', 'add-staff-member-fail-popup')),
    );
    setNameBG('');
    setNameEN('');
    setStaffMemberID('');
    setOpenDialog(false);
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        scroll='body'
      >
        <DialogTitle id='form-dialog-title'>Add Staff</DialogTitle>
        <DialogContent>
          <Grid style={{ marginBottom: '1rem' }} container display='flex' direction='column'>
            <TextField
              margin='dense'
              id='add-staff-member-dialog-id'
              label='ID'
              type='text'
              onChange={(e) => { setStaffMemberID(e.target.value); }}
            />
            <TextField
              margin='dense'
              id='add-staff-member-dialog-name-bg'
              label='Name BG'
              type='text'
              onChange={(e) => { setNameBG(e.target.value); }}
            />
            <TextField
              margin='dense'
              id='add-staff-member-dialog-name-en'
              label='Name EN'
              type='text'
              onChange={(e) => { setNameEN(e.target.value); }}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '16px 20px' }}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            id='add-staff-member-modal-button'
            variant='contained'
            onClick={handleAddMember}
            color='primary'
          >
            Add Staff
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
