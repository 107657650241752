/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for OwnersContext
 */

export const addOwnerOverview = (overview, dispatch) => {
  dispatch({
    type: 'ADD_OWNER_OVERVIEW',
    overview,
  });
};

export const addBranchesOverview = (branchesOverview, dispatch) => {
  dispatch({
    type: 'ADD_BRANCHES_OVERVIEW',
    branchesOverview,
  });
};

export const addTerminalsOverview = (terminalsOverview, dispatch) => {
  dispatch({
    type: 'ADD_TERMINALS_OVERVIEW',
    terminalsOverview,
  });
};
