/* eslint-disable import/prefer-default-export */
import React, { createContext, useReducer } from 'react';

const initialState = {
  overview: {
    event: {
      en: '',
      bg: '',
    },
    event_start_time: '',
    event_end_time: '',
    event_picture: '',
    event_map: '',
    current_balance: 0,
    last_transaction_time: '',
    customer: {
      name_en: '',
      name_bg: '',
      external_id: '',
    },
    card_id: '',
    loaded_amount: 0,
    account_spending: 0,
    undo_reload: 0,
    undo_card_purchase: 0,
    bonus: 0,
    generated: '',
  },

  transactions: [
    {
      id: 0,
      event_id_id: 0,
      customer_external_id: '',
      card_external_id: '',
      transaction_counter: 0,
      amount: 0,
      account_spending: 0,
      balance_prepaid: 0,
      log_date_time_terminal: '',
      transaction_type: {
        en: '',
        bg: '',
      },
      black_list_version: '',
      fetched_at: '',
      terminal: {
        external_id: '',
      },
      staff: {
        external_id: '',
      },
      branch: {
        external_id: '',
      },
    },
  ],
  isDialogOpened: false,
  isFirstVisit: false,
  viberUrl: '',
  isSubscribedToViber: false,
};

export const UserContext = createContext(initialState);

const addUserData = (state, userData) => ({ ...state, overview: userData });
const addTransactionsData = (state, transactionsData) => ({ ...state, transactions: transactionsData });
const setIsDialogOpened = (state, isOpened) => ({ ...state, isDialogOpened: isOpened });
const setIsFirstVisit = (state, hasVisitted) => ({ ...state, isFirstVisit: hasVisitted });
const setViberUrl = (state, url) => ({ ...state, viberUrl: url });
const setSubscribedToViber = (state, isSubscribed) => ({ ...state, isSubscribedToViber: isSubscribed });

/**
 * @description User Reducer
 * @method userReducer
 */
const userReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_USER_DATA':
      return addUserData(state, action.userData);
    case 'ADD_TRANSACTIONS_DATA':
      return addTransactionsData(state, action.transactionsData);
    case 'SET_IS_DIALOG_OPENED':
      return setIsDialogOpened(state, action.isOpened);
    case 'SET_IS_FIRST_VISIT':
      return setIsFirstVisit(state, action.hasVisitted);
    case 'SET_VIBER_URL':
      return setViberUrl(state, action.url);
    case 'SET_SUBSCRIBED_TO_VIBER':
      return setSubscribedToViber(state, action.isSubscribed);
    default:
      return state;
  }
};

/**
 * @description UserContextProvider component
 * @method UserContextProvider
 * @returns {'<UserContextProvider>'} <UserContextProvider>
 */
export const UserContextProvider = ({ children }) => {
  const [userState, userDispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider
      value={{ userState, userDispatch }}
    >
      {children}
    </UserContext.Provider>
  );
};
