import React, { useEffect, useCallback } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { setBackgroundImage } from '../../utils/setBackgroundImage';
import { getGroupOverview } from '../../api/OwnersCalls';
import { getOverview as getUserOverview } from '../../api/UserCalls';

export default function EventNotActivePage() {
  const { hash } = useParams();
  const currentURL = useLocation();
  const history = useHistory();

  const handleEventNotActive = useCallback(
    async (userInterfaceName, apiCallback) => {
      try {
        await apiCallback(hash);
        history.push(`/${userInterfaceName}/${hash}`);
      } catch (error) {
        if (error.response.status === 400) {
          setBackgroundImage(
            error.response.data.not_active_image,
            'cover',
            'transparent',
            'center',
            'multiply',
          );
        }
      }
    },
    [hash, history],
  );

  useEffect(() => {
    async function addData() {
      switch (currentURL.pathname) {
        case `/users/${hash}/event-not-active`:
          await handleEventNotActive('users', getUserOverview);
          break;
        case `/owners/${hash}/event-not-active`:
          await handleEventNotActive('owners', getGroupOverview);
          break;
        default:
          break;
      }
    }
    addData();
  }, [currentURL.pathname, handleEventNotActive, hash]);
  return (
    <div />
  );
}
