import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { format } from 'date-fns';
import StyledPaper from '../../../../../common/StyledPaper/StyledPaper';
import OverviewDetails from './OverviewDetails/OverviewDetails';
import OverviewDetailsMobile from './OverviewDetailsMobile/OverviewDetailsMobile';
import { getGroupOverview, getFilteredOverview } from '../../../../../../api/OwnersCalls';
import { addOwnerOverview } from '../../../../../../context/OwnersActions';
import { OwnersContext } from '../../../../../../context/OwnersContext';
import OverviewImageDialog from './OverviewImageDialog/OverviewImageDialog';
import CustomSpeedDial from '../common/CustomSpeedDial/CustomSpeedDial';
import ChipContainer from '../common/ChipContainer/ChipContainer';
import FilterByDateDialog from '../common/FilterByDateDialog/FilterByDateDialog';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
  media: {
    height: 300,
  },
  chipContainer: {
    marginBottom: '2rem',
  },
}));

export default function Overview() {
  const classes = useStyles();
  const { ownersState, ownersDispatch } = useContext(OwnersContext);
  const { hash } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const { openSnackBar } = useContext(SnackBarContext);

  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isImageDialogOpened, setIsImageDialogOpened] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterDialogOpened, setIsFilterDialogOpened] = useState(false);

  const filterByDateHandler = async (startDate, endDate) => {
    const start = format(Date.parse(startDate), "yyyy-MM-dd'T'HH:mm:ss");
    const end = format(Date.parse(endDate), "yyyy-MM-dd'T'HH:mm:ss");
    try {
      const filteredOverview = await getFilteredOverview(hash, start, end);
      addOwnerOverview(filteredOverview.data, ownersDispatch);
      setIsFilterApplied(true);
      setIsFilterDialogOpened(false);
    } catch (error) {
      openSnackBar(
        'error',
        'Failed to filter transactions.',
        'filter-transactions-fail-popup',
      );
    }
  };

  const speedDialActions = [
    {
      icon: <DateRangeIcon />,
      name: 'Filter By Date',
      id: 'filter-by-date',
      handler: () => { setIsFilterDialogOpened(true); },
    },
  ];

  useEffect(
    () => {
      if (isFilterApplied) {
        setChipData((c) => [...c, {
          key: 0,
          label: t('transactionsDialog.chips.dateFilter'),
        }]);
      }
    },
    [isFilterApplied, t],
  );

  useEffect(() => {
    async function addData() {
      if (!isFilterApplied) {
        try {
          const ownerOverview = await getGroupOverview(hash);
          addOwnerOverview(ownerOverview.data, ownersDispatch);
        } catch (error) {
          if (error?.response?.status === 400) {
            if (error.response.data.not_active_image) history.push(`/owners/${hash}/event-not-active`);
            else {
              openSnackBar(
                'error',
                'Something went wrong.',
                'get-group-overview-fail-popup',
              );
            }
          } else {
            history.push('/404');
          }
        }
      }
    }
    addData();
  }, [history, isFilterApplied, hash, ownersDispatch, openSnackBar]);

  const ownerOverviewDetails = {
    [t('owners.loaded')]: ownersState.overview.total_deposited || '',
    [t('owners.withdrawn')]: ownersState.overview.total_withdrawn || '',
    [t('owners.spent')]: ownersState.overview.total_spent || '',
    [t('owners.undone')]: ownersState.overview.total_canceled_transactions || '',
    [t('owners.count')]: ownersState.overview.total_transactions || '',
    [t('owners.lastdate')]: ownersState.overview.last_transaction_time.replace('T', ' ') || '',
    [t('owners.fetched_at')]: ownersState.overview.fetched_at.replace('T', ' ') || '',
  };

  const ownerOverviewSummary = {
    [t('owners.event')]: ownersState.overview.event[i18n.language] || '',
    [t('owners.group')]: ownersState.overview.group[i18n.language] || '',
  };

  const handleMapClick = () => {
    setIsImageDialogOpened(true);
  };

  const handleChipDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    if (chipToDelete.label === t('transactionsDialog.chips.dateFilter')) {
      setIsFilterApplied(false);
    }
  };
  return (
    <>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg' className={classes.container}>
          <ChipContainer
            className={classes.chipContainer}
            chipData={chipData}
            handleChipDelete={handleChipDelete}
          />
          <Grid container spacing={3} direction='row' justify='center'>
            <Grid item xs={12} md={8} lg={9}>
              {
                matchesDesktopScreen
              && (
              <StyledPaper>
                <OverviewDetails
                  ownerOverviewDetails={ownerOverviewDetails}
                  ownerOverviewSummary={ownerOverviewSummary}
                />
              </StyledPaper>
              )
              }
              {
                matchesMobileScreen
              && (
                <OverviewDetailsMobile
                  ownerOverviewSummary={ownerOverviewSummary}
                  ownerOverviewDetails={ownerOverviewDetails}
                />
              )
              }
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              {
            ownersState.overview.event_map
            && (
            <Card className={`${classes.card} custom-rounded-paper`}>
              <CardHeader
                title={t('owners.map')}
                className={classes.alignTextCenter}
              />
              <CardActionArea onClick={handleMapClick}>
                <CardMedia
                  className={classes.media}
                  image={ownersState.overview.event_map}
                />
              </CardActionArea>
            </Card>
            )
            }
            </Grid>
          </Grid>
        </Container>
        <OverviewImageDialog
          isDialogOpened={isImageDialogOpened}
          setIsDialogOpened={setIsImageDialogOpened}
          image={ownersState.overview.event_map}
        />
        <FilterByDateDialog
          isFilterDialogOpened={isFilterDialogOpened}
          setIsFilterDialogOpened={setIsFilterDialogOpened}
          filterHandler={filterByDateHandler}
        />
        <CustomSpeedDial speedDialActions={speedDialActions} />
      </div>
    </>
  );
}
