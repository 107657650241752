/* eslint-disable camelcase */
import React, {
  useState, useContext, useEffect,
} from 'react';
import { isEmpty } from 'lodash';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhoto from '@material-ui/icons/AddPhotoAlternate';
import CardHeader from '@material-ui/core/CardHeader';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ArchiveIcon from '@material-ui/icons/Archive';
import GetAppIcon from '@material-ui/icons/GetApp';
import SyncIcon from '@material-ui/icons/Sync';
import { trackPromise } from 'react-promise-tracker';
import ImageDialog from './ImageDialog';
import StyledPaper from '../../../../../common/StyledPaper/StyledPaper';
import EditForm from './EditForm';
import VPOSConnectivity from './VPOSConnectivity/VPOSConnectivity';
import {
  getEvent,
  downloadQRCodesArchive,
  downloadQRCodesCSV,
  refetchAllTransactions,
} from '../../../../../../api/EventCalls';
// import { addEventsData } from '../../../../../../context/EventsActions';
import { EventsContext } from '../../../../../../context/EventsContext';
import EditFormMobile from './EditFormMobile';
import QRScanIcon from '../../../../../common/CustomIcons/QRScanIcon';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import { createDownloadLink } from '../../../../../../utils/utils';
import EditCardSubranges from './EditCardSubranges/EditCardSubranges';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 187,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  alignTextCenter: {
    textAlign: 'center',
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  subrangesPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  subrangesVposAndMapGrid: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

export default function BaseDetails() {
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [eventState, setEventState] = useState({});
  const [previews, setPreviews] = useState({});
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);
  const { eventsDispatch } = useContext(EventsContext);
  const { openSnackBar } = useContext(SnackBarContext);
  let { id } = useParams();
  id = Number(id);
  const [openImageDialog, setOpenImageDialog] = useState({
    isOpened: false,
    image: '',
  });
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  const handleActionButtonClose = () => {
    setOpenSpeedDial(false);
  };

  const handleActionButtonOpen = () => {
    setOpenSpeedDial(true);
  };

  const handlePictureClick = () => {
    setOpenImageDialog({
      isOpened: true,
      image: previews.picture,
    });
  };

  const handleMapClick = () => {
    setOpenImageDialog({
      isOpened: true,
      image: previews.map,
    });
  };

  const handleInactiveImageClick = () => {
    setOpenImageDialog({
      isOpened: true,
      image: previews.not_active_image,
    });
  };

  const handleInactiveImageUpload = (e) => {
    setEventState({
      ...eventState,
      not_active_image: e.target.files[0],
    });
    setPreviews({
      ...previews,
      not_active_image: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleMapUpload = (e) => {
    setEventState({
      ...eventState,
      map: e.target.files[0],
    });
    setPreviews({
      ...previews,
      map: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handlePictureUpload = (e) => {
    setEventState({
      ...eventState,
      picture: e.target.files[0],
    });
    setPreviews({
      ...previews,
      picture: URL.createObjectURL(e.target.files[0]),
    });
  };

  useEffect(() => {
    async function addData() {
      const currentEvent = await getEvent(id);
      setEventState(currentEvent.data);
      setPreviews(currentEvent.data);
    }
    addData();
  }, [eventsDispatch, id]);

  const downloadWithHandler = async (callback, name, type) => {
    await trackPromise(
      callback
        .then((response) => {
          if (response.status === 202) {
            const { data } = response;
            const decoder = new TextDecoder('utf8');
            const jsonData = JSON.parse(decoder.decode(new DataView(data)));
            openSnackBar(
              'warning',
              `${jsonData.details}`,
              `download-${name}-loading-popup`,
            );
          } else {
            createDownloadLink(response.data, name, type);
          }
        })
        .catch(() => {
          openSnackBar(
            'error',
            'Something went wrong with the download!',
            `download-${name}-fail-popup`,
          );
        }),
    );
  };

  const downloadCodesZip = async () => {
    await downloadWithHandler(
      downloadQRCodesArchive(id),
      'qr-images-archive',
      '.zip',
    );
  };

  const downloadLinksCSV = async () => {
    await downloadWithHandler(downloadQRCodesCSV(id), '.csv', 'qr-links');
  };

  const refetchTransactions = async () => {
    await trackPromise(
      refetchAllTransactions(id)
        .then((response) => {
          openSnackBar(
            'success',
            `${response.data.total_transactions_updated} transactions were refetched!`,
            'refetch-transactions-success-popup',
          );
        })
        .catch((error) => {
          if (error.response.status === 423) {
            openSnackBar(
              'error',
              'The event is already over. Cannot refetch transactions!',
              'refetch-transactions-fail-popup',
            );
          } else if (error.response.status >= 400) {
            openSnackBar(
              'error',
              'Something went wrong with the refetch!',
              'refetch-transactions-fail-popup',
            );
          }
        }),
    );
  };

  const speedDialActions = [
    {
      icon: <ArchiveIcon />,
      name: 'Download QRCodes Images Zip',
      id: 'download-codes-zip',
      handler: downloadCodesZip,
    },
    {
      icon: <GetAppIcon />,
      name: 'Download QRCodes Links CSV',
      id: 'download-codes-csv',
      handler: downloadLinksCSV,
    },
    {
      icon: <SyncIcon />,
      name: 'Refetch All Transactions',
      id: 'refetch-transactions',
      handler: refetchTransactions,
    },
  ];

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth='lg' className={classes.container}>
        {!isEmpty(eventState) && !isEmpty(previews)
        && (
          <>
            <Grid container spacing={3} direction='row' justify='center'>
              <Grid item xs={12} md={8} lg={9}>
                {
                  matchesDesktopScreen
                  && (
                    <StyledPaper>
                      <EditForm
                        eventState={eventState}
                        setEventState={setEventState}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                        id={eventState.id}
                      />
                    </StyledPaper>
                  )
                }
                {
                  matchesMobileScreen
                  && (
                    <Paper>
                      <EditFormMobile
                        eventState={eventState}
                        setEventState={setEventState}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                        id={eventState.id}
                      />
                    </Paper>
                  )
                }
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <Grid container direction='row' spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Card className={classes.card}>
                      <CardHeader
                        title='Event Photo'
                        className={classes.alignTextCenter}
                      />
                      <CardActionArea onClick={handlePictureClick}>
                        <CardMedia
                          className={classes.media}
                          image={previews.picture}
                        />
                      </CardActionArea>
                      <CardActions>
                        <input
                          accept='image/*'
                          className={classes.input}
                          style={{ display: 'none' }}
                          id='event-file'
                          multiple
                          type='file'
                          onChange={handlePictureUpload}
                        />
                        <label htmlFor='event-file'>
                          {!isDisabled
                            && (
                              <Button size='small' color='primary' variant='outlined' component='span' className={classes.button}>
                                <AddPhoto color='primary' className={classes.extendedIcon} />
                                Event Photo
                              </Button>
                            )}
                        </label>
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Card className={classes.card}>
                      <CardHeader
                        title='Inactive Event Photo'
                        className={classes.alignTextCenter}
                      />
                      <CardActionArea onClick={handleInactiveImageClick}>
                        <CardMedia
                          className={classes.media}
                          image={previews.not_active_image}
                        />
                      </CardActionArea>
                      <CardActions>
                        <input
                          accept='image/*'
                          className={classes.input}
                          style={{ display: 'none' }}
                          id='inactive-event-file'
                          multiple
                          type='file'
                          onChange={handleInactiveImageUpload}
                        />
                        <label htmlFor='inactive-event-file'>
                          {!isDisabled
                            && (
                              <Button size='small' color='primary' variant='outlined' component='span' className={classes.button}>
                                <AddPhoto color='primary' className={classes.extendedIcon} />
                                Inactive Event Photo
                              </Button>
                            )}
                        </label>
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} />
                </Grid>
              </Grid>
              <ImageDialog openImageDialog={openImageDialog} setOpenImageDialog={setOpenImageDialog} />
            </Grid>
            <Grid container spacing={3} direction='row' justify='center' className={classes.subrangesVposAndMapGrid}>
              {/* Event FanClub Subranges */}
              <Grid item xs={12} md={4} lg={6}>
                <EditCardSubranges
                  startCustomerId={Number(eventState.start_customer_id)}
                  endCustomerId={Number(eventState.end_customer_id)}
                  isDisabled={isDisabled}
                  eventState={eventState}
                  setEventState={setEventState}
                />
              </Grid>
              {/* VPOS connectivity */}
              <Grid item xs={12} md={4} lg={3}>
                <VPOSConnectivity
                  eventState={eventState}
                  setEventState={setEventState}
                  isDisabled={isDisabled}
                />
              </Grid>
              {/* Event Not Active photo */}
              <Grid item xs={12} md={4} lg={3}>
                <Card className={classes.card}>
                  <CardHeader
                    title='Map'
                    className={classes.alignTextCenter}
                  />
                  <CardActionArea onClick={handleMapClick}>
                    <CardMedia
                      className={classes.media}
                      image={previews.map}
                    />
                  </CardActionArea>
                  <CardActions>
                    <input
                      accept='image/*'
                      className={classes.input}
                      style={{ display: 'none' }}
                      id='map-file'
                      multiple
                      type='file'
                      onChange={handleMapUpload}
                    />
                    <label htmlFor='map-file'>
                      {!isDisabled
                    && (
                      <Button size='small' color='primary' variant='outlined' component='span' className={classes.button}>
                        <CloudUploadIcon color='primary' className={classes.extendedIcon} />
                        Map Photo
                      </Button>
                    )}
                    </label>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      <SpeedDial
        ariaLabel='SpeedDial example'
        className={classes.speedDial}
        icon={<QRScanIcon />}
        onClose={handleActionButtonClose}
        onOpen={handleActionButtonOpen}
        open={openSpeedDial}
        direction='up'
        id='speed-dial'
      >
        {
          speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.handler}
              id={action.id}
              data-cy={action.id}
            />
          ))
        }
      </SpeedDial>
    </div>
  );
}
