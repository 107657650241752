/* eslint-disable camelcase */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import Papa from 'papaparse';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PlusOneRoundedIcon from '@material-ui/icons/PlusOneRounded';
import NoteAddRoundedIcon from '@material-ui/icons/NoteAddRounded';
import { useParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import ArchiveIcon from '@material-ui/icons/Archive';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddStaffMemberModal from './AddStaffMemberModal/AddStaffMemberModal';
import EditStaffMemberModal from './EditStaffMemberModal/EditStaffMemberModal';
import AccordionStackStaff from '../../../../../common/AccordionStack/AccordionStack';
import { DialogContext } from '../../../../../../context/DialogContext';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import {
  getStaff, deleteStaffMember, addStaffMember, getStaffMember,
  downloadStaffQRCodesCSV, downloadStaffQRCodesArchive,
} from '../../../../../../api/StaffCalls';
import { checkForDuplicatedIDs } from '../../../../../../utils/csvFilesIDsValidation/csvFilesIDsValidation';
import StaffCards from './StaffCards/StaffCards';
import { download } from '../../../../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export default function Staff({ event }) {
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [openNewStaffDialog, setOpenNewStaffDialog] = useState(false);
  const [openEditStaffDialog, setOpenEditStaffDialog] = useState(false);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [staffMemberToEdit, setStaffMemberToEdit] = useState({});
  const classes = useStyles();
  let { id } = useParams();
  id = Number(id);
  const [staff, setStaff] = useState([]);
  const [staffIDEdit, setStaffIDEdit] = useState('');
  const [csvAdded, setCsvAdded] = useState(false);
  const inputCSVRef = useRef(null);

  useEffect(() => {
    async function addData() {
      const staffForEvent = await getStaff(id);
      setStaff(staffForEvent.data);
    }
    addData();
  }, [id, csvAdded, openNewStaffDialog, openEditStaffDialog]);

  const handleDelete = (staffMemberID, external_id) => {
    const deleteHandlerForForm = async () => {
      try {
        await deleteStaffMember(staffMemberID);
        const staffForEvent = await getStaff(id);
        setStaff(staffForEvent.data);
        openSnackBar('info', `Staff with ID ${external_id} deleted.`, 'delete-staff-member-success-popup');
      } catch (error) {
        openSnackBar('error', 'Something went wrong!', 'delete-staff-member-fail-popup');
      }
      closeDialog();
    };
    openDialog('error', 'Delete', `Staff with ID ${external_id} will be deleted!`, 'Are you sure?', 'delete-staff-member-modal-button', deleteHandlerForForm);
  };

  async function handleEdit(staffMemberID) {
    const member = await getStaffMember(staffMemberID);
    setStaffMemberToEdit(member.data);
    setStaffIDEdit(staffMemberID);
    setOpenEditStaffDialog(true);
  }

  const handleActionButtonClose = () => {
    setOpenSpeedDial(false);
  };

  const handleActionButtonOpen = () => {
    setOpenSpeedDial(true);
  };

  const handleAddSingleStaff = () => {
    setOpenNewStaffDialog(true);
  };

  const handleAddCVSForStaff = () => {
    inputCSVRef.current.click();
  };

  async function deleteAllStaff() {
    try {
      const staffForEvent = await getStaff(id);
      await Promise.all(
        staffForEvent.data.map(async (staffMember) => { await deleteStaffMember(staffMember.id); }),
      );
    } catch (error) {
      openSnackBar('error', 'Something went wrong!', 'delete-staff-fail-popup');
    }
  }

  async function addMembersFromFile(staffArray) {
    return Promise.all(
      staffArray.map(async (staffMember) => {
        // eslint-disable-next-line
        staffMember.event_id = id;
        await addStaffMember(staffMember);
      }),
    );
  }

  const arrayToStaffObject = (values) => {
    const keys = ['external_id', 'name_bg', 'name_en'];
    return keys.reduce((o, k, i) => ({ ...o, [k]: values[i] }), {});
  };

  function createStaffArray(multipleStaffArray) {
    return multipleStaffArray.map((singleMember) => arrayToStaffObject(singleMember));
  }

  const handleCSVInputChange = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      async complete(results) {
        results.data.pop();
        const normalisedStaff = createStaffArray(results.data);
        const isDuplicatedId = checkForDuplicatedIDs(normalisedStaff);
        if (isDuplicatedId) {
          openSnackBar('error', 'There are duplicated IDs, please check your csv file!', 'duplicated-ids-fail-terminal-popup');
          return;
        }
        try {
          await deleteAllStaff();
          await trackPromise(
            addMembersFromFile(normalisedStaff)
              .then(setCsvAdded(!csvAdded))
              .then(openSnackBar('success', 'Staff succesfully added from file.', 'add-staff-from-file-success-popup')),
          );
        } catch (error) {
          openSnackBar('error', 'Something went wrong!', 'add-staff-from-file-fail-popup');
        }
      },
    });
  };

  const downloadCodesZip = async () => {
    try {
      await download(downloadStaffQRCodesArchive(event.id), '.zip', 'qr-images-zip');
    } catch (error) {
      openSnackBar('error', 'Something went wrong with the download!', 'download-zip-fail-popup');
    }
  };

  const downloadCodesCsv = async () => {
    try {
      await download(downloadStaffQRCodesCSV(event.id), '.csv', 'qr-codes-csv');
    } catch (error) {
      openSnackBar('error', 'Something went wrong with the download!', 'download-csv-fail-popup');
    }
  };

  const speedDialActions = [
    {
      icon: <PlusOneRoundedIcon />,
      name: 'Add a Single Member',
      id: 'add-single-staff-member',
      handler: handleAddSingleStaff,
    },
    {
      icon: <NoteAddRoundedIcon />,
      name: 'Upload CSV With Staff',
      id: 'add-csv-staff',
      handler: handleAddCVSForStaff,
    },
    {
      icon: <ArchiveIcon />,
      name: 'Download QR codes images ZIP',
      id: 'download-codes-images-zip',
      handler: downloadCodesZip,
    },
    {
      icon: <GetAppIcon />,
      name: 'Download QR codes CSV',
      id: 'download-codes-csv',
      handler: downloadCodesCsv,
    },
  ];

  return (
    <div className={classes.content}>
      <Container maxWidth='lg' className={classes.container}>
        {
            matchesDesktopScreen && (
            <Paper className={classes.root}>
              <AccordionStackStaff
                headingID='Staff ID'
                panelsArray={staff}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                editButtonID='edit-staff-member-button'
                deleteButtonID='delete-staff-member-button'
                cypressID='staff-name-en'
              />
            </Paper>
            )
          }
        {
            matchesMobileScreen && (
              <StaffCards
                cardsArray={staff}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            )
          }
        <SpeedDial
          ariaLabel='SpeedDial example'
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleActionButtonClose}
          onOpen={handleActionButtonOpen}
          open={openSpeedDial}
          direction='up'
          id='speed-dial'
        >
          {
              speedDialActions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.handler}
                  id={action.id}
                  data-cy={action.id}
                />
              ))
            }
        </SpeedDial>
      </Container>
      <AddStaffMemberModal
        openDialog={openNewStaffDialog}
        setOpenDialog={setOpenNewStaffDialog}
        eventId={id}
      />
      <EditStaffMemberModal
        openDialog={openEditStaffDialog}
        setOpenDialog={setOpenEditStaffDialog}
        eventId={id}
        staffMemberToEdit={staffMemberToEdit}
        setMemberToEdit={setStaffMemberToEdit}
        staffMemberIDEdit={staffIDEdit}
      />
      <input
        ref={inputCSVRef}
        type='file'
        id='csv-staff-file'
        accept='.csv'
        onChange={handleCSVInputChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
