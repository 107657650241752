import React from 'react';
import StaffCard from './StaffCard';

export default function TerminalsCards({ cardsArray, handleEdit, handleDelete }) {
  return (
    <div>
      {cardsArray.map((card) => (
        <StaffCard
          id={card.id}
          external_id={card.external_id}
          nameBG={card.name_bg}
          nameEN={card.name_en}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          key={card.id}
        />
      ))}
    </div>
  );
}
