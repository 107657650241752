/* eslint-disable import/prefer-default-export */
import { loginAxios } from './LoginCalls';
import { loggedInAxios } from './LoggedInCalls';

export async function getStaff(eventId) {
  const response = await loggedInAxios.get(`/staff/?event_id=${eventId}`);
  return response;
}

export async function getStaffMember(memberId) {
  const response = await loggedInAxios.get(`/staff/${memberId}/`);
  return response;
}

export async function addStaffMember(postData) {
  const response = await loggedInAxios.post('/staff/', postData);
  return response;
}

export async function updateStaffMember(memberId, patchData) {
  const response = await loggedInAxios.patch(`/staff/${memberId}/`, patchData);
  return response;
}

export async function deleteStaffMember(memberId) {
  const response = await loggedInAxios.delete(`/staff/${memberId}/`);
  return response;
}

export async function downloadStaffQRCodesArchive(eventId) {
  const response = await loggedInAxios.get(`/staff/archive?event_id=${eventId}`, {
    responseType: 'arraybuffer',
  });
  return response;
}

export async function downloadStaffQRCodesCSV(eventId) {
  const response = await loggedInAxios.get(`/staff/qr_codes?event_id=${eventId}`, {
    responseType: 'arraybuffer',
  });
  return response;
}

export async function getStaffOverview(staffHash) {
  const response = await loginAxios.get(`/staff/${staffHash}/overview`);
  return response;
}

export async function getFilteredOverview(staffHash, startDate, endDate) {
  const response = await loginAxios.get(`/staff/${staffHash}/overview?start=${startDate}&end=${endDate}`);
  return response;
}

export async function getTerminalsOverview(staffHash) {
  const response = await loginAxios.get(`/staff/${staffHash}/terminals`);
  return response;
}

export async function getFilteredTerminalsOverview(staffHash, startDate, endDate) {
  const response = await loginAxios.get(`/staff/${staffHash}/terminals?start=${startDate}&end=${endDate}`);
  return response;
}
