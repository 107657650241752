import React, { useState, useEffect } from 'react';
import {
  Container, FormLabel, FormGroup, TextField,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { format } from 'date-fns';
import MUIDataTable from 'mui-datatables';
import { getCustomersRefundRequests } from '../../../../../../api/RefundsCalls';
import './Refunds.css';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '1rem',
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
  },
  container: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  tableCells: {
    [theme.breakpoints.down(321)]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
}));

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableFilter: {
      root: {
        minWidth: '13.5rem !important',
      },
    },
    MuiPaper: {
      root: {
        maxWidth: 'none !important',
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'column !important',
      },
    },
    MuiChip: {
      root: {
        marginBottom: '0.7rem !important',
      },
    },
  },
});

export default function Refunds() {
  const classes = useStyles();
  const [refundsData, setRefundsData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const result = await getCustomersRefundRequests(id);
      setRefundsData(result.data);
    };
    getData();
  }, [id]);

  const columns = [
    {
      name: 'customer_id',
      label: 'Customer ID',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          className: classes.tableCells,
        }),
      },
    },
    {
      name: 'date_of_creation',
      label: 'Request Date',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: classes.tableCells,
        }),
        customBodyRender: (value) => format(new Date(value), 'd LLLL yyyy, HH:mm:ss'),
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return `Start Date: ${v[0]}, End Date: ${v[1]}`;
            } if (v[0]) {
              return `Start Date: ${v[0]}`;
            } if (v[1]) {
              return `End Date: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos - 1, 2);
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          fullWidth: true,
          logic(date, filters) {
            const check = new Date(date);
            const from = new Date(filters[0]).setHours(0, 0, 0, 0);
            const to = new Date(filters[1]).setHours(23, 59, 59, 59);
            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } if (filters[0] && check >= to) {
              return true;
            } if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date Created</FormLabel>
              <FormGroup row>
                <TextField
                  id='startDate'
                  label='Start Date'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
                <TextField
                  id='endDate'
                  label='End Date'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    responsive: 'vertical',
    selectableRows: 'none',
    download: true,
    downloadOptions: {
      filename: 'Refunds.csv',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    viewColumns: false,
    print: false,
    pagination: true,
    rowsPerPageOptions: [10, 30, 50, 100],
    elevation: 1,
  };

  return (
    <div className={classes.content} id='refund-table-container'>
      <Container maxWidth='lg' className={classes.container}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title='Refund Requests'
            columns={columns}
            options={options}
            data={refundsData}
          />
        </MuiThemeProvider>
      </Container>
    </div>
  );
}
