/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for CreateEventContext
 */

export const openForm = (dispatch) => {
  dispatch({
    type: 'OPEN_FORM',
  });
};

export const closeForm = (dispatch) => {
  dispatch({
    type: 'CLOSE_FORM',
  });
};

export const changeStep = (step, dispatch) => {
  dispatch({
    type: 'CHANGE_STEP',
    step,
  });
};

export const disableNextButton = (dispatch) => {
  dispatch({
    type: 'DISABLE_NEXT_BUTTON',
  });
};

export const enableNextButton = (dispatch) => {
  dispatch({
    type: 'ENABLE_NEXT_BUTTON',
  });
};
