import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UserContextProvider } from '../../context/UserContext';
import { OwnersContextProvider } from '../../context/OwnersContext';
import { StaffContextProvider } from '../../context/StaffContext';
import UserLanding from '../User/pages/UserLanding/UserLanding';
import OwnersLanding from '../Owners/Pages/OwnersLanding/OwnersLanding';
import StaffLanding from '../Staff/Pages/StaffLanding/StaffLanding';
import EventNotActivePage from '../EventNotActivePage/EventNotActivePage';

export default function EventUsersEntryRouter() {
  const currentURL = useLocation();
  const { hash } = useParams();

  function getComponentsByPath() {
    switch (currentURL.pathname) {
      case `/users/${hash}`:
        return (
          <UserContextProvider>
            <UserLanding />
          </UserContextProvider>
        );
      case `/users/${hash}/event-not-active`:
        return <EventNotActivePage />;
      case `/owners/${hash}`:
      case `/owners/${hash}/branches`:
      case `/owners/${hash}/terminals`:
        return (
          <OwnersContextProvider>
            <OwnersLanding />
          </OwnersContextProvider>
        );
      case `/owners/${hash}/event-not-active`:
        return (
          <EventNotActivePage />
        );
      case `/staff/${hash}`:
      case `/staff/${hash}/branches`:
      case `/staff/${hash}/terminals`:
        return (
          <StaffContextProvider>
            <StaffLanding />
          </StaffContextProvider>
        );
      case `/staff/${hash}/event-not-active`:
        return (
          <EventNotActivePage />
        );
      default:
        return (
          <></>
        );
    }
  }

  return (
    getComponentsByPath()
  );
}
