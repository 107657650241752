import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: '100%',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide duration={1500} direction='up' ref={ref} {...props} />);

export default function OverviewImageDialog({ isDialogOpened, setIsDialogOpened, image }) {
  const classes = useStyles();

  const handleClose = () => {
    setIsDialogOpened(false);
  };

  return (
    <div>
      <Dialog
        open={isDialogOpened}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        scroll='body'
      >
        <DialogContent>
          <img
            className={classes.image}
            src={image}
            alt='map'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
