import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import './CustomAccordionStyles.css';
import { getBranch } from '../../../../../../../api/BranchesCalls';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    alignItems: 'center',
  },
  fullWidthBorder: {
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
}));

export default function GroupsAccordion({
  id,
  nameBG,
  nameEN,
  branchesArray,
  handleDelete,
  handleEdit,
  showButtons,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [branches, setBranches] = useState([]);

  async function addBranchesToArray() {
    return Promise.all(
      branchesArray.map(async (branchId) => {
        const branchDetails = await getBranch(branchId);
        return branchDetails.data;
      }),
    );
  }

  const handleChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    const tempBranches = await addBranchesToArray();
    setBranches(tempBranches);
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>{id}</Typography>
          <Typography className={classes.heading}>{nameBG}</Typography>
          <Typography className={classes.heading} data-cy={`group-${nameEN}`}>{nameEN}</Typography>
          {showButtons && (
            <>
              <IconButton
                onClick={() => handleEdit(id)}
                className={classes.iconButton}
                id='edit-group-button'
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(id)}
                className={classes.iconButton}
                id='delete-group-button'
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Table border={0} size='small'>
            <TableHead>
              <TableRow className={classes.fullWidthBorder}>
                <TableCell>
                  <Typography variant='h6'>
                    Branches
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch ID</TableCell>
                <TableCell>Name BG</TableCell>
                <TableCell>Name EN</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branches.map((branch) => (
                <TableRow key={branch.id}>
                  <TableCell>{branch.external_id}</TableCell>
                  <TableCell>
                    {branch.name_bg}
                  </TableCell>
                  <TableCell>{branch.name_en}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

GroupsAccordion.propTypes = {
  id: PropTypes.number,
  nameBG: PropTypes.string.isRequired,
  nameEN: PropTypes.string.isRequired,
  branchesArray: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  showButtons: PropTypes.bool.isRequired,
};
