import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import TerminalsAccordion from './TerminalsAccordion';
import './CustomAccordionStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '26%',
    flexShrink: 0,
  },
  statusHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '12%',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  accordion: {
    alignItems: 'center',
  },
}));

export default function TerminalsAccordionStack({
  headingID,
  panelsArray,
  handleEdit,
  handleDelete,
  cypressID,
}) {
  const classes = useStyles();

  return (
    <div>
      <Accordion
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>{headingID}</Typography>
          <Typography className={classes.heading}>Name BG</Typography>
          <Typography className={classes.heading}>Name EN</Typography>
          <Typography className={classes.statusHeading}>Status</Typography>
        </AccordionSummary>
      </Accordion>
      {
        panelsArray.map((panel) => (
          <TerminalsAccordion
            id={panel.id}
            externalId={panel.external_id}
            nameBG={panel.name_bg}
            nameEN={panel.name_en}
            state={panel.state}
            timeLastTransaction={panel.time_last_transaction}
            timeLastVisit={panel.time_last_visit}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            key={panel.id}
            cypressID={cypressID}
            showButtons
          />
        ))
}
    </div>
  );
}

TerminalsAccordionStack.propTypes = {
  headingID: PropTypes.string.isRequired,
  panelsArray: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
