import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';
import { updateTerminal } from '../../../../../../../api/TerminalsCalls';

export default function EditTerminalModal({
  openDialog,
  setOpenDialog,
  terminalIDEdit,
  terminalToEdit,
  setTerminalToEdit,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };
  const { openSnackBar } = useContext(SnackBarContext);
  async function handleEditTerminal() {
    const patchData = {
      external_id: terminalToEdit.external_id,
      name_bg: terminalToEdit.name_bg,
      name_en: terminalToEdit.name_en,
    };
    try {
      await updateTerminal(terminalIDEdit, patchData);
      openSnackBar('success', `Terminal with id ${terminalIDEdit} updated.`, 'update-terminal-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong', 'edit-terminal-fail-popup');
    }
    setOpenDialog(false);
  }

  function handleIDChange(event) {
    setTerminalToEdit({
      ...terminalToEdit,
      external_id: event.target.value,
    });
  }

  function handleNameBGChange(event) {
    setTerminalToEdit({
      ...terminalToEdit,
      name_bg: event.target.value,
    });
  }

  function handleNameENChange(event) {
    setTerminalToEdit({
      ...terminalToEdit,
      name_en: event.target.value,
    });
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        scroll='body'
      >
        <DialogTitle id='form-dialog-title'>Edit Terminal</DialogTitle>
        <DialogContent>
          <Grid style={{ marginBottom: '1rem' }} container display='flex' direction='column'>
            <TextField
              margin='dense'
              id='edit-terminal-dialog-id'
              label='ID'
              type='text'
              onChange={handleIDChange}
              value={terminalToEdit.external_id}
            />
            <TextField
              margin='dense'
              id='edit-terminal-dialog-name-bg'
              label='Name BG'
              type='text'
              onChange={handleNameBGChange}
              value={terminalToEdit.name_bg}
            />
            <TextField
              margin='dense'
              id='edit-terminal-dialog-name-en'
              label='Name EN'
              type='text'
              onChange={handleNameENChange}
              value={terminalToEdit.name_en}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '16px 20px' }}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            id='edit-terminal-modal-button'
            variant='contained'
            onClick={handleEditTerminal}
            color='primary'
          >
            Edit Terminal
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
