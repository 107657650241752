import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BalanceOverviewCard from '../BalanceOverviewCard/BalanceOverviewCard';
import BGFlag from '../../../../common/Images/flags/bg.png';
import UKFlag from '../../../../common/Images/flags/uk.png';
import './LanguageMenuDialog.css';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#00468E',
    color: 'white',
  },
  card: {
    borderRadius: '1rem',
    width: '90%',
    maskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
  flagImage: {
    width: '60px',
    height: '36px',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.13)',
    marginRight: '1rem',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(321)]: {
      padding: '8px 4px',
    },
    [theme.breakpoints.down(281)]: {
      padding: '8px 0px',
    },
  },
}));

export default function MenuDialog({
  setCardToFlipFront,
  setCardToFlipBack,
  setFlipped,
  flipped,
}) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const handleENClick = () => {
    setCardToFlipFront(<BalanceOverviewCard />);
    setCardToFlipBack(<BalanceOverviewCard />);
    setFlipped(!flipped);
    i18n.changeLanguage('en');
  };

  const handleBGClick = () => {
    setCardToFlipFront(<BalanceOverviewCard />);
    setCardToFlipBack(<BalanceOverviewCard />);
    setFlipped(!flipped);
    i18n.changeLanguage('bg');
  };

  const isLanguageChosen = (langToCheck) => (
    i18n.language === langToCheck
      ? <CheckCircleIcon color='primary' />
      : null
  );

  return (
    <Card className={classes.card}>
      <CardContent
        className={classes.cardContent}
      >
        <List>
          <ListItem className={classes.listItem} button onClick={handleENClick}>
            <img
              className={classes.flagImage}
              src={UKFlag}
              alt='United Kingdom Flag'
            />
            <ListItemText
              className='listItemText'
              primary='English'
            />
            {
              isLanguageChosen('en')
            }
          </ListItem>
          <ListItem className={classes.listItem} button onClick={handleBGClick}>
            <img
              className={classes.flagImage}
              src={BGFlag}
              alt='Bulgarian Flag'
            />
            <ListItemText
              className='listItemText'
              primary='Български'
            />
            {
              isLanguageChosen('bg')
            }
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
