import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { openForm } from '../../../../../context/Actions';
import { CreateEventContext } from '../../../../../context/CreateEventContext';

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: '240px',
    width: '100%',
    marginTop: theme.spacing(8),
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
      transitionTimingFunction: 'ease-in-out',
      transition: '0.5s',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  media: {
    height: 140,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * @description Create event card component
 * @function AddEventCard
 * @param  {props} props - all of props from the parent.
 * @returns {'<Card>'} - Card component
 */
export default function AddEventCard() {
  const classes = useStyles();
  const { dispatch } = useContext(CreateEventContext);

  const handleOpen = () => {
    openForm(dispatch);
  };
  return (
    <>
      <Card className={classes.card}>
        <Fab id='add-event-card-button' onClick={handleOpen} color='primary' aria-label='add' className={classes.fab} elementtype='button'>
          <AddIcon />
        </Fab>
      </Card>
    </>
  );
}
