/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description Get Groups event api call
 * @async
 * @method getGroups
 * @param  {Integer} eventId - event id
 * @returns {Object} response object
 */
export async function getGroups(eventId) {
  const response = await loggedInAxios.get(`/groups/?event_id=${eventId}`);
  return response;
}

/**
 * @description Get Single Group event api call
 * @async
 * @method getGroup
 * @param  {Integer} Group ID - group id
 * @returns {Object} response object
 */
export async function getGroup(groupId) {
  const response = await loggedInAxios.get(`/groups/${groupId}`);
  return response;
}

/**
 * @description Add Group for event api call
 * @async
 * @method addGroup
 * @param  {Object} postData
 * @returns {Object} response object
 */
export async function addGroup(postData) {
  const response = await loggedInAxios.post('/groups/', postData);
  return response;
}

/**
 * @description Delete Group for event api call
 * @async
 * @method deleteGroup
 * @param  {Integer} groupId
 * @returns {Object} response object
 */
export async function deleteGroup(groupId) {
  const response = await loggedInAxios.delete(`/groups/${groupId}/`);
  return response;
}

/**
 * @description Update Group for event api call
 * @async
 * @method updateGroup
 * @param  {Object} postData, groupId
 * @returns {Object} response object
 */
export async function updateGroup(groupId, postData) {
  const response = await loggedInAxios.patch(`/groups/${groupId}/`, postData);
  return response;
}

/**
 * @description Get groups csv file for event api call
 * @async
 * @method downloadGroupsQRCodesCSV
 * @param  {String} eventId
 * @returns {Object} response object
 */
export async function downloadGroupsQRCodesCSV(eventId) {
  const response = await loggedInAxios.get(`/groups/qr_codes?event_id=${eventId}`, {
    responseType: 'arraybuffer',
  });
  return response;
}
/**
 * @description Get groups qrcodes images for event api call
 * @async
 * @method downloadGroupsQRCodesArchive
 * @param  {String} eventId
 * @returns {Object} response object
 */
export async function downloadGroupsQRCodesArchive(eventId) {
  const response = await loggedInAxios.get(`/groups/archive?event_id=${eventId}`, {
    responseType: 'arraybuffer',
  });
  return response;
}
