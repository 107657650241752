import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon, Menu as MenuIcon } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TransactionsCards from './TransactionsCards/TransactionsCards';
import {
  setIsDialogOpened,
  addTransactionsData,
  setIsFirstVisit,
  setViberUrl,
} from '../../../../context/UserActions';
import { UserContext } from '../../../../context/UserContext';
import SortFilterMenuDialog from './SortFilterMenuDialog/SortFilterMenuDialog';
import FilterByDateDialog from './FilterByDateDialog/FilterByDateDialog';
import StyledDialogTitle from '../../../common/StyledDialogTitle/BlueBackgroundDialogTitle/BlueBackgroundDialogTitle';
import Transition from '../../../common/TransitionComponent/TransitionComponent';
import { getTransactions } from '../../../../api/UserCalls';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#00468E',
    boxShadow: 'inset 0 0 100px #000000',
    padding: theme.spacing(2),
  },
  whiteButton: {
    color: 'white',
  },
}));

export default function TransactionsDialog({ isOpen, setIsOpen }) {
  const classes = useStyles();
  const { hash } = useParams();
  const { userState, userDispatch } = useContext(UserContext);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isFilterDialogOpened, setIsFilterDialogOpened] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [chipData, setChipData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isFilterApplied) {
      setChipData((c) => [
        ...c,
        {
          key: 0,
          label: t('transactionsDialog.chips.dateFilter'),
        },
      ]);
    }
  }, [isFilterApplied, t]);

  useEffect(() => {
    async function addData() {
      if (!isFilterApplied) {
        const transactionsData = await getTransactions(hash);
        addTransactionsData(transactionsData?.data.transactions, userDispatch);
        setIsFirstVisit(transactionsData?.data.first_visit, userDispatch);
        setViberUrl(transactionsData?.data.viber_url, userDispatch);
      }
    }
    addData();
  }, [chipData, hash, isFilterApplied, userDispatch]);

  const handleClose = () => {
    setIsOpen(false);
    setIsDialogOpened(false, userDispatch);
  };

  const handleMenuOpen = () => {
    setIsMenuOpened(true);
  };

  const handleChipDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    if (chipToDelete.label === t('transactionsDialog.chips.dateFilter')) {
      setIsFilterApplied(false);
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <StyledDialogTitle
          onClose={handleClose}
          handleMenuOpen={handleMenuOpen}
        >
          <IconButton className={classes.whiteButton} onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6'>
            {t('transactionsDialog.dialogTitle')}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            className={classes.whiteButton}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <TransactionsCards
            transactionsArray={userState.transactions}
            chipData={chipData}
            handleChipDelete={handleChipDelete}
          />
        </DialogContent>
      </Dialog>
      <SortFilterMenuDialog
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        setIsFilterDialogOpened={setIsFilterDialogOpened}
      />
      <FilterByDateDialog
        isFilterDialogOpened={isFilterDialogOpened}
        setIsFilterDialogOpened={setIsFilterDialogOpened}
        isFilterApplied={isFilterApplied}
        setIsFilterApplied={setIsFilterApplied}
      />
    </>
  );
}

TransactionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
