/* eslint-disable camelcase */
/* eslint consistent-return:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Typography,
  Tooltip,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Collapse,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as NoConnectionIcon,
  Warning as PassiveIcon,
  CheckCircle as ActiveIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
  media: {
    height: 140,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    wordWrap: 'break-word',
    display: 'flex',
    alignItems: 'center',
  },
  dynamicHeight: {
    height: 'calc(100% - 64px)',
  },
  scrollableTable: {
    width: '100%',
    overflowX: 'auto',
  },
  passiveIcon: {
    color: 'orange',
  },
  noConnectionIcon: {
    color: 'red',
  },
  activeIcon: {
    color: 'green',
  },
}));

/**
 * @description Displays single terminal using card.
 * @function TerminalsCard
 * @param  {props} props - all of props from the parent.
 * @returns {'<TerminalsCard>'} - Card component
 */
export default function TerminalsCard({
  id,
  externalId,
  nameBG,
  nameEN,
  state,
  timeLastTransaction,
  timeLastVisit,
  handleDelete,
  handleEdit,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = async () => {
    setExpanded(!expanded);
  };

  const formatDate = (date) => format(Date.parse(date), 'MMM do yyyy, HH:mm:ss');

  const handleStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return <ActiveIcon className={classes.activeIcon} />;
      case 'PASSIVE':
        return <PassiveIcon className={classes.passiveIcon} />;
      case 'NO_CONNECTION':
        return <NoConnectionIcon className={classes.noConnectionIcon} />;
      default:
        break;
    }
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.dynamicHeight}
      >
        <CardContent onClick={handleExpandClick}>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            ID:
            {' '}
            {externalId}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Name BG:
            {' '}
            {nameBG}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Name EN:
            {' '}
            {nameEN}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Status:
            {' '}
            {handleStatus(state)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Tooltip title='Edit'>
          <IconButton id='edit-terminal-button' onClick={() => handleEdit(id)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton id='delete-terminal-button' onClick={() => handleDelete(id, externalId)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent className={classes.scrollableTable}>
          <Table border={0} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Time Last Transaction</TableCell>
                <TableCell>Time Last Visit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{timeLastTransaction ? formatDate(timeLastTransaction) : 'N/A'}</TableCell>
                <TableCell>{timeLastVisit ? formatDate(timeLastVisit) : 'N/A'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

TerminalsCard.propTypes = {
  id: PropTypes.number.isRequired,
  externalId: PropTypes.string.isRequired,
  nameBG: PropTypes.string.isRequired,
  nameEN: PropTypes.string.isRequired,
  timeLastTransaction: PropTypes.string,
  timeLastVisit: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
