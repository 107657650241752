import React from 'react';
import { Redirect, Route } from 'react-router-dom';

/**
 * @description Private route
 * @method PrivateRoute
 * @param  {Component} Component - Every component
 * @returns {'<Route>'} <Route> component
 */
// eslint-disable-next-line import/prefer-default-export
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      localStorage.getItem('access_token') ? <Component {...props} /> : <Redirect to='/' exact />
    )}
  />
);
