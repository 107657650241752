import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
  value: {
    fontSize: 20,
  },
  alignHorizontally: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoBox: {
    margin: '1rem',
  },
  alignStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    wordWrap: 'break-word',
    textAlign: 'start',

  },
  alignEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    wordWrap: 'break-word',
    textAlign: 'end',
  },
  alignToTopWithMargin: {
    marginLeft: '16px',
  },
});

export default function MainContent({ transaction }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className={classes.alignHorizontally}>
        <div className={classes.infoBox}>
          <Typography className={`${classes.title} ${classes.alignStart}`} color='textSecondary' gutterBottom>
            {t('transactionCard.mainContent.type')}
          </Typography>
          <Typography className={`${classes.value} ${classes.alignStart}`} variant='h5' component='h2'>
            {transaction.transaction_type[i18n.language]}
          </Typography>
        </div>
        <div className={classes.infoBox}>
          <Typography className={`${classes.title} ${classes.alignEnd}`} color='textSecondary' gutterBottom>
            {t('transactionCard.mainContent.change')}
          </Typography>
          <Typography className={`${classes.value} ${classes.alignEnd}`} variant='h5' component='h2'>
            {transaction.amount}
          </Typography>
        </div>
      </div>
      <div className={classes.alignToTopWithMargin}>
        <Typography className={`${classes.title} ${classes.alignStart}`} color='textSecondary' gutterBottom>
          {t('transactionCard.mainContent.date')}
        </Typography>
        <Typography className={`${classes.value} ${classes.alignStart}`} variant='h5' component='h2' gutterBottom>
          {transaction.log_date_time_terminal.replace('T', ' ')}
        </Typography>
        <Typography className={`${classes.title} ${classes.alignStart}`} color='textSecondary' gutterBottom>
          {t('transactionCard.mainContent.terminal')}
        </Typography>
        <Typography className={`${classes.value} ${classes.alignStart}`} variant='h5' component='h2' gutterBottom>
          {
            transaction.terminal[i18n.language]
              ? transaction.terminal[i18n.language]
              : transaction.terminal.external_id
          }
        </Typography>
      </div>
    </div>
  );
}
