/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';

export const normalizeEventUpdateData = (eventData) => {
  const {
    name_bg,
    name_en,
    currency,
    db_host,
    db_password,
    db_name,
    db_user,
    db_port,
    start_customer_id,
    end_customer_id,
    start_time,
    end_time,
    map,
    picture,
    display_pennies,
    not_active_image,
    subranges,
    vpos,
    card_brands,
  } = eventData;

  const formData = new FormData();
  formData.append('name_bg', name_bg);
  formData.append('name_en', name_en);
  formData.append('currency', currency);
  formData.append('db_host', db_host);
  formData.append('db_password', db_password);
  formData.append('db_name', db_name);
  formData.append('db_port', db_port);
  formData.append('db_user', db_user);
  formData.append('start_customer_id', start_customer_id);
  formData.append('end_customer_id', end_customer_id);
  formData.append('display_pennies', display_pennies);
  formData.append('start_time', format(Date.parse(start_time), "yyyy-MM-dd'T'HH:mm:ss"));
  formData.append('end_time', format(Date.parse(end_time), "yyyy-MM-dd'T'HH:mm:ss"));

  const subrangesToAppend = subranges.map((subrange) => ({ start: Number(subrange.start), end: Number(subrange.end) }));
  subrangesToAppend.forEach((subrange) => formData.append('subranges', JSON.stringify(subrange, null, 2).replace(/\\n/g, '')));

  card_brands.map((cardBrand) => formData.append('card_brands', cardBrand));

  if (typeof map !== 'string' && map !== null) {
    formData.append('map', map, map.name, map.type);
  }
  if (typeof picture !== 'string' && picture !== null) {
    formData.append('picture', picture, picture.name, picture.type);
  }
  if (typeof not_active_image !== 'string' && not_active_image !== null) {
    formData.append('not_active_image', not_active_image, not_active_image.name, not_active_image.type);
  }
  if (vpos !== 0 && vpos !== null) {
    formData.append('vpos', vpos);
  }

  return formData;
};

export const normalizeDatabaseUpdateData = (eventData) => {
  const {
    db_host,
    db_password,
    db_name,
    db_user,
    db_port,
  } = eventData;

  const formData = new FormData();
  formData.append('db_host', db_host);
  formData.append('db_password', db_password);
  formData.append('db_name', db_name);
  formData.append('db_port', db_port);
  formData.append('db_user', db_user);
  return formData;
};

export const normalizeEventCreationData = (eventGeneralInfo, customerIDsAndNames, dBConfig) => {
  const {
    name_bg,
    name_en,
    currency,
    start_time,
    end_time,
    display_pennies,
  } = eventGeneralInfo;

  const {
    start_customer_id, end_customer_id, map_photo, event_photo, not_active_image, subranges,
  } = customerIDsAndNames;

  const {
    db_user, db_password, db_host, db_port, db_name,
  } = dBConfig;

  const form_data = new FormData();
  form_data.append('name_bg', name_bg);
  form_data.append('name_en', name_en);
  form_data.append('currency', currency);
  form_data.append('db_host', db_host);
  form_data.append('db_password', db_password);
  form_data.append('db_user', db_user);
  form_data.append('db_port', db_port);
  form_data.append('db_name', db_name);
  form_data.append('start_customer_id', start_customer_id);
  form_data.append('end_customer_id', end_customer_id);

  const subrangesToAppend = subranges.map((subrange) => ({ start: Number(subrange.start), end: Number(subrange.end) }));

  subrangesToAppend.forEach((subrange) => form_data.append('subranges', JSON.stringify(subrange, null, 2).replace(/\\n/g, '')));

  form_data.append('display_pennies', display_pennies);
  form_data.append('start_time', format(Date.parse(start_time), "yyyy-MM-dd'T'HH:mm:ss"));
  form_data.append('end_time', format(Date.parse(end_time), "yyyy-MM-dd'T'HH:mm:ss"));
  if (map_photo !== '') {
    form_data.append('map', map_photo, map_photo.name, map_photo.type);
  } else {
    form_data.append('map', '');
  }
  if (event_photo !== '') {
    form_data.append('picture', event_photo, event_photo.name, event_photo.type);
  } else {
    form_data.append('picture', '');
  }
  if (not_active_image !== '') {
    form_data.append('not_active_image', not_active_image, not_active_image.name, not_active_image.type);
  } else {
    form_data.append('not_active_image', '');
  }

  return form_data;
};
