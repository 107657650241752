import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChipsArray({ array, setArray }) {
  const classes = useStyles();

  const handleDelete = (chipToDelete) => () => {
    setArray((chips) => chips.filter(
      (chip) => chip.label !== chipToDelete.label,
    ));
  };

  return (
    <>
      {array.map((data, index) => (
        <Chip
          id={data}
          key={index} // eslint-disable-line
          label={data.label}
          onDelete={handleDelete(data, index)}
          className={classes.chip}
        />
      ))}
    </>
  );
}

ChipsArray.propTypes = {
  array: PropTypes.array.isRequired,
  setArray: PropTypes.func.isRequired,
};
