/* eslint-disable camelcase */

import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { SnackBarContext } from '../../../../../../context/SnackBarContext';
import { updateDatabaseEvent } from '../../../../../../api/EventCalls';
import { normalizeDatabaseUpdateData } from '../../../../../../utils/normalizationFunctions';

const useStyles2 = makeStyles({
  mobileContainer: {
    justifyContent: 'center',
    padding: '1rem',
  },
  button: {
    marginTop: '2rem',
  },
  textField: {
    marginBottom: '2rem',
  },
  passRevealButtonDisabled: {
    color: 'gray',
  },
  passRevealButton: {
    color: 'black',
  },
});

export default function DBEditFormMobile({
  eventState, setEventState, eventId, isDisabled, setIsDisabled,
}) {
  const classes = useStyles2();
  const { openSnackBar } = useContext(SnackBarContext);
  const [isShowPassword, setShowPassword] = useState(false);
  const [dbError, setDbError] = useState({
    text: '',
    isItWrong: false,
  });
  const initialDbHost = eventState.db_host;
  const [dbHost, setDbHost] = useState(initialDbHost);
  useEffect(() => {
    setEventState(eventState);
  }, [eventState, setEventState]);

  function handleDBHostChange(event) {
    // eslint-disable-next-line
    const webAddressExpr = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
    if (event.target.value.match(webAddressExpr)) {
      setDbError({
        text: '',
        isItWrong: false,
      });
      setDbHost(event.target.value);
      setEventState({
        ...eventState,
        db_host: event.target.value,
      });
    } else if (!event.target.value.match(webAddressExpr)) {
      setDbError({
        text: 'Please input a valid URL.',
        isItWrong: true,
      });
      setDbHost(event.target.value);
    }
  }

  function handleDBPasswordChange(event) {
    setEventState({
      ...eventState,
      db_password: event.target.value,
    });
  }

  function handleDBNameChange(event) {
    setEventState({
      ...eventState,
      db_name: event.target.value,
    });
  }

  function handleDBPortChange(event) {
    setEventState({
      ...eventState,
      db_port: event.target.value,
    });
  }

  function handleDBUserChange(event) {
    setEventState({
      ...eventState,
      db_user: event.target.value,
    });
  }
  const handleSaveButtonClick = async () => {
    try {
      const formData = normalizeDatabaseUpdateData(eventState);
      await updateDatabaseEvent(formData, eventId);
      openSnackBar('success', 'Database configuration saved', 'dbconfig-success');
      setIsDisabled(!isDisabled);
    } catch (error) {
      openSnackBar('error', 'Ooops, something went wrong!', 'dbconfig-fail-popup');
    }
  };

  function handleEditButtonClick() {
    setIsDisabled(!isDisabled);
  }

  const changeShowPassword = () => setShowPassword(!isShowPassword);

  return (
    <Grid container className={classes.mobileContainer}>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Host:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} md={12}>
        <TextField
          component='span'
          required
          fullWidth
          id='db-host'
          type='text'
          margin='normal'
          value={dbHost}
          onChange={handleDBHostChange}
          className={classes.textField}
          disabled={isDisabled}
          error={dbError.isItWrong}
          helperText={dbError.text}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Database password:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} md={12}>
        <TextField
          component='span'
          required
          fullWidth
          id='db-password'
          type={isShowPassword ? 'text' : 'password'}
          margin='normal'
          value={eventState.db_password}
          onChange={handleDBPasswordChange}
          disabled={isDisabled}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Button onClick={changeShowPassword}>
                  {
                        isDisabled
                        && (
                          isShowPassword ? (<VisibilityOffIcon className={classes.passRevealButtonDisabled} />)
                            : (<VisibilityIcon className={classes.passRevealButtonDisabled} />)
                        )
                      }
                  {
                        !isDisabled
                        && (
                          isShowPassword ? (<VisibilityOffIcon className={classes.passRevealButton} />)
                            : (<VisibilityIcon className={classes.passRevealButton} />)
                        )
                      }
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Database name:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} md={12}>
        <TextField
          component='span'
          required
          fullWidth
          id='db-name'
          type='text'
          margin='normal'
          value={eventState.db_name}
          onChange={handleDBNameChange}
          className={classes.textField}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Database port:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} md={12}>
        <TextField
          component='span'
          required
          fullWidth
          id='db-port'
          type='number'
          margin='normal'
          value={eventState.db_port}
          onChange={handleDBPortChange}
          className={classes.textField}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item zeroMinWidth xs={12} sm={12}>
        <Typography component='h6' variant='h6'>
          Database user:
        </Typography>
      </Grid>
      <Grid item zeroMinWidth xs={12} md={12}>
        <TextField
          component='span'
          required
          fullWidth
          id='db-user'
          type='text'
          margin='normal'
          value={eventState.db_user}
          onChange={handleDBUserChange}
          className={classes.textField}
          disabled={isDisabled}
        />
      </Grid>
      {isDisabled ? (
        <Button id='edit-event-database-button' variant='contained' color='primary' className={classes.button} onClick={handleEditButtonClick}>
          <EditIcon />
          {' '}
          Edit
        </Button>
      ) : (
        <Button id='save-event-database-button' variant='contained' color='primary' className={classes.button} onClick={handleSaveButtonClick}>
          <SaveIcon />
          {' '}
          Save
        </Button>
      )}
    </Grid>
  );
}
