import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import StaffAccordion from './StaffAccordion';
import './AccordionStack.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    alignItems: 'center',
  },
}));

export default function AccordionStack({
  terminalsOverview,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Accordion
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>
            {t('staff.terminal_name')}
            {' '}
            ID
          </Typography>
          <Typography className={classes.heading}>
            {t('staff.name')}
            {' '}
            BG
          </Typography>
          <Typography className={classes.heading}>
            {t('staff.name')}
            {' '}
            EN
          </Typography>
        </AccordionSummary>
      </Accordion>
      {terminalsOverview ? terminalsOverview.map((singleTerminal) => (
        <StaffAccordion
          key={singleTerminal.name.external_id}
          singleTerminal={singleTerminal}
        />
      )) : ''}
    </>
  );
}
