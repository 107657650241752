import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { UserContext } from '../../../../../context/UserContext';
import { addTransactionsData } from '../../../../../context/UserActions';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#00468E',
    color: 'white',
  },
  rootStyle: {
    borderRadius: 15,
  },
});

function SortFilterMenuDialog({
  onClose,
  open,
  setIsFilterDialogOpened,
}) {
  const { userState, userDispatch } = useContext(UserContext);
  const [sortType, setSortType] = useState('asc');
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleOpenFilterDialog = () => {
    onClose();
    setIsFilterDialogOpened(true);
  };

  const handleSortNumber = () => {
    switch (sortType) {
      case 'desc':
        addTransactionsData(
          userState.transactions
            .sort((b, a) => a.transaction_counter - b.transaction_counter),
          userDispatch,
        );
        setSortType('asc');
        break;
      case 'asc':
        addTransactionsData(
          userState.transactions
            .sort((a, b) => a.transaction_counter - b.transaction_counter),
          userDispatch,
        );
        setSortType('desc');
        break;
      default:
        break;
    }
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='sort-filter-dialog'
      open={open}
      classes={{
        paper: classes.rootStyle,
      }}
    >
      <DialogTitle>
        {t('sortFilterMenuDialog.dialogTitle')}
      </DialogTitle>
      <List>
        <ListItem button onClick={handleOpenFilterDialog}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('sortFilterMenuDialog.filter-by-date')} />
        </ListItem>
        <ListItem button onClick={handleSortNumber}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              { sortType === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${t('sortFilterMenuDialog.sort-by-number')} ${t(`sortFilterMenuDialog.${sortType}`)}`} />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function Menu({
  handleSortByNumber,
  isMenuOpened,
  setIsMenuOpened,
  handleFilterByDate,
  setIsFilterDialogOpened,
}) {
  const handleMenuClose = () => {
    setIsMenuOpened(false);
  };
  return (
    <SortFilterMenuDialog
      handleSortByNumber={handleSortByNumber}
      open={isMenuOpened}
      onClose={handleMenuClose}
      handleFilterByDate={handleFilterByDate}
      setIsFilterDialogOpened={setIsFilterDialogOpened}
    />
  );
}
