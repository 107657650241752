import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 'auto',
    height: 'auto',
  },
  img: {
    margin: 'auto',
    display: 'block',
    // maxWidth: '100%',
    // maxHeight: '100%',
    width: '150%',
  },
  alignCenter: {
    display: 'flex',
    margin: 'auto',
    padding: '1rem',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  makeWider: {
    width: '50%',
  },
}));

/**
 * @description Page 500
 * @function Page500
 * @param  {props} props - all of props from the parent.
 * @returns {'<Page500>'} - 500 page
 */
export default function Page500(props) {
  const classes = useStyles();
  const image = {
    // eslint-disable-next-line global-require
    sorryImage: require('../../../common/Images/image500.png'),
  };
  const changeRoute = () => {
    props.history.push('/');
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Typography
          component='h2'
          className={classes.alignCenter}
          variant='h2'
          color='primary'
        >
          Uh no! Commi is sad!
        </Typography>
        <Grid item xs={12} md={12}>
          <div className={classes.alignCenter}>
            <ButtonBase
              className={classes.makeWider}
              onClick={changeRoute}
            >
              <img
                className={classes.img}
                alt='complex'
                src={image.sorryImage}
              />
            </ButtonBase>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component='h2'
            className={classes.alignCenter}
            variant='h2'
            color='primary'
          >
            Something bad just happened!
          </Typography>
        </Grid>
        <Button
          variant='contained'
          size='large'
          color='primary'
          className={classes.alignCenter}
          onClick={changeRoute}
        >
          Go back
        </Button>
      </Grid>
    </div>
  );
}
