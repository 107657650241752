/* eslint-disable camelcase */
import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '1rem',
    width: '90%',
    maskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.9)))',
  },
  header: {
    fontSize: '1.2rem',
    boxShadow: '0 2px 7px -6px black',
    textAlign: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    height: '50%',
    justifyContent: 'center',
    fontSize: '1.2rem',
  },
  balanceTitle: {
    alignSelf: 'center',
  },
  balance: {
    fontWeight: 'bold',
    [theme.breakpoints.down(281)]: {
      fontSize: '1.4rem',
    },
  },
  alignCenterBalance: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    margin: '10px',
  },
}));

export default function BalanceOverviewCard() {
  const classes = useStyles();
  const { userState, userDispatch } = useContext(UserContext);
  const [ballance, setBallance] = useState({
    currentBalance: userState.overview.current_balance || '',
    transactionTime: userState.overview.last_transaction_time.replace('T', ' '),
  });
  const { t } = useTranslation();

  useEffect(() => {
    const dateTime = userState.overview.last_transaction_time.replace('T', ' ');
    setBallance({
      currentBalance: userState.overview.current_balance,
      transactionTime: dateTime,
    });
  }, [userDispatch, userState.overview.current_balance, userState.overview.last_transaction_time]);

  const membership = userState?.overview?.fest_club ? 'Fest Club Member' : '';

  return (
    <Card className={classes.card}>
      <CardHeader
        data-cy='membership-type'
        className={classes.header}
        title={membership}
      />
      <CardContent
        className={classes.cardContent}
      >
        <div className={classes.alignCenterBalance}>
          <Typography
            className={classes.balance}
            variant='h4'
          >
            {ballance.currentBalance}
          </Typography>
        </div>
        <Typography
          className={classes.balanceTitle}
          variant='body1'
        >
          {t('balanceOverviewCard.balance-as-of')}
          {' '}
          {ballance.transactionTime}
        </Typography>
      </CardContent>
    </Card>
  );
}
