import React from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  fullWidthBorder: {
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  leftAlignedSums: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'end',
    },
  },
}));
export default function BranchesOverviewTable({ overviewDataObject }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const getTableRow = (leftCell, rightCell, index) => (
    <TableRow key={index}>
      <TableCell>{leftCell}</TableCell>
      <TableCell className={classes.leftAlignedSums}>{rightCell}</TableCell>
    </TableRow>
  );

  return (
    <Table
      border={0}
      size='small'
    >
      <TableHead>
        <TableRow className={classes.fullWidthBorder}>
          <TableCell component='th' scope='row'>
            <Typography variant='h6'>
              {t('owners.menu.overview')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          Object.entries(overviewDataObject)
            .map((row, index) => getTableRow(row[0], row[1], index))
        }
      </TableBody>
    </Table>
  );
}
