import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import DeleteIcon from '@material-ui/icons/Delete';
import PreviewIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import { getBranch } from '../../../../../../../api/BranchesCalls';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
  media: {
    height: 140,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    wordWrap: 'break-word',
  },
  dynamicHeight: {
    height: 'calc(100% - 64px)',
  },
  scrollableTable: {
    width: '100%',
    overflowX: 'auto',
  },
}));

/**
 * @description Displays single group using card.
 * @function GroupCard
 * @param  {props} props - all of props from the parent.
 * @returns {'<GroupCard>'} - Card component
 */
export default function GroupCard({
  id,
  nameBG,
  nameEN,
  branchesArray,
  handleDelete,
  handleEdit,
}) {
  const classes = useStyles();
  const [branches, setBranches] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  async function addBranchesToArray() {
    return Promise.all(
      branchesArray.map(async (branchId) => {
        const branchDetails = await getBranch(branchId);
        return branchDetails.data;
      }),
    );
  }

  const handleExpandClick = async () => {
    setExpanded(!expanded);
    const tempBranches = await addBranchesToArray();
    setBranches(tempBranches);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.dynamicHeight}
      >
        <CardContent onClick={handleExpandClick}>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            ID:
            {' '}
            {id}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Name BG:
            {' '}
            {nameBG}
          </Typography>
          <Typography
            className={classes.title}
            name='card-content-title'
            gutterBottom
            variant='h5'
            component='h5'
          >
            Name EN:
            {' '}
            {nameEN}
          </Typography>

        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <ToolTip title='Preview'>
          <IconButton id='preview-group-branches-button' color='primary' onClick={handleExpandClick}>
            <PreviewIcon />
          </IconButton>
        </ToolTip>
        <ToolTip title='Delete'>
          <IconButton id='edit-group-button' onClick={() => handleEdit(id)}>
            <EditIcon />
          </IconButton>
        </ToolTip>
        <ToolTip title='Delete'>
          <IconButton id='delete-group-button' onClick={() => handleDelete(id)}>
            <DeleteIcon />
          </IconButton>
        </ToolTip>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent className={classes.scrollableTable}>
          <Table border={0} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    Branches
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch ID</TableCell>
                <TableCell>Name BG</TableCell>
                <TableCell>Name EN</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branches.map((branch) => (
                <TableRow key={branch.id}>
                  <TableCell>{branch.external_id}</TableCell>
                  <TableCell>
                    {branch.name_bg}
                  </TableCell>
                  <TableCell>{branch.name_en}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>

  );
}
