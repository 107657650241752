/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import { getCardBrands } from '../../../../../../../api/CardBrandsCalls';
import { getVPOSOptions } from '../../../../../../../api/VPOSCalls';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '1rem',
  },
  radioGroup: {
    margin: '2rem',
  },
  title: {
    textAlign: 'center',
  },
  chooseCardText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    fontSize: '1rem',
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
  },
  cardBrand: {
    borderRadius: '1rem',
    maxWidth: '5rem',
    cursor: 'pointer',
    margin: '0.5rem',
  },
  cardImage: {
    width: '100%',
  },
  cardBrandsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardBorder: {
    padding: '5px',
    border: '5px solid #3f51b5',
  },
  disabled: {
    cursor: 'not-allowed',
  },
}));

export default function VPOSConnectivity({ eventState, setEventState, isDisabled }) {
  const classes = useStyles();

  const [vposOptions, setVposOptions] = useState(null);
  const [cardBrands, setCardBrands] = useState([]);

  useEffect(() => {
    async function addData() {
      const vposOptionsData = await getVPOSOptions();
      setVposOptions(vposOptionsData?.data);
      const cardBrandsData = await getCardBrands();
      setCardBrands(cardBrandsData?.data.map(
        (cardBrand) => ({
          ...cardBrand,
          chosen: eventState
            .card_brands
            ?.some(
              (chosenCardId) => chosenCardId === cardBrand.id,
            ),
        }),
      ));
    }
    if (isDisabled) addData();
  }, [eventState, isDisabled]);

  const handleChange = (event) => {
    if (event.target.value === 0) {
      setEventState({ ...eventState, vpos: null });
    } else {
      setEventState({ ...eventState, vpos: Number(event.target.value) });
    }
  };

  const handleImageClick = (cardId) => {
    if (!isDisabled) {
      setCardBrands(cardBrands.map((card) => {
        if (card.id === cardId) {
          return {
            ...card,
            chosen: !card.chosen,
          };
        }
        return card;
      }));
    }
  };

  useEffect(() => {
    if (!isDisabled) {
      setEventState({
        ...eventState,
        card_brands: cardBrands.reduce((acc, curr) => {
          if (curr.chosen) {
            acc.push(curr.id);
          }
          return acc;
        }, []),
      });
    }
  }, [cardBrands]);

  return (
    <Paper className={classes.paper}>

      {/* Title */}
      <Typography className={classes.title} component='h5' variant='h5'>
        VPOS Connectivity
      </Typography>

      {/* VPOS radio group  */}
      <FormControl className={classes.radioGroup} component='fieldset'>
        <FormLabel component='legend'>Choose your bank</FormLabel>
        <RadioGroup id='bank-choosing' aria-label='bank-choosing' name='bank' value={eventState?.vpos ? eventState?.vpos : 0} onChange={handleChange}>
          {vposOptions?.map((option) => (
            <FormControlLabel
              disabled={isDisabled}
              key={option.id}
              value={option.id}
              control={<Radio />}
              label={option.type}
            />
          ))}
          <FormControlLabel
            disabled={isDisabled}
            value={0}
            control={<Radio />}
            label='None'
          />
        </RadioGroup>
      </FormControl>

      {/* Card Brands */}
      <Typography className={classes.chooseCardText} component='span'>
        Choose supported cards
      </Typography>
      <Box className={classes.cardBrandsContainer} data-cy='card-brands-container'>
        {cardBrands.map((card) => (
          <Box
            key={card.id}
            className={
                clsx({
                  [classes.cardBrand]: true,
                  [classes.cardBorder]: card.chosen,
                  [classes.disabled]: isDisabled,
                })
            }
            onClick={() => handleImageClick(card.id)}
          >
            <img
              className={classes.cardImage}
              src={card.image}
              alt={card.name}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
}
