/* eslint-disable import/prefer-default-export */
import React, { createContext, useReducer } from 'react';

const initialState = {
  overview: {
    event: '',
    group: '',
    total_deposited: '',
    total_withdrawn: '',
    total_spent: '',
    total_canceled_transactions: '',
    total_transactions: '',
    last_transaction_time: '',
    fetched_at: '',
  },
  branchesOverview: [
    {
      branch: {
        bg: '',
        en: '',
        external_id: '',
      },
      total_deposited: '',
      total_spent: '',
      total_withdrawn: '',
      total_canceled_transactions: '',
      total_transactions: 0,
      last_transaction_time: '',
    },
  ],
  terminalsOverview: {
    0: [
      {
        balance: {
          spent: '',
          canceled: '',
          deposited: '',
          withdrawn: '',
          total_transactions: '',
          last_transaction: '',
        },
        name: {
          external_id: '',
        },
        branch: {
          external_id: '',
          bg: '',
          en: '',
        },
      },
    ],
  },
};

export const OwnersContext = createContext(initialState);

const addOwnerOverview = (state, overview) => ({ ...state, overview });

const addBranchesOverview = (state, branchesOverview) => ({ ...state, branchesOverview });

const addTerminalsOverview = (state, terminalsOverview) => ({ ...state, terminalsOverview });

/**
 * @description Owners Reducer
 * @method ownersReducer
 */
const ownersReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_OWNER_OVERVIEW':
      return addOwnerOverview(state, action.overview);
    case 'ADD_BRANCHES_OVERVIEW':
      return addBranchesOverview(state, action.branchesOverview);
    case 'ADD_TERMINALS_OVERVIEW':
      return addTerminalsOverview(state, action.terminalsOverview);
    default:
      return state;
  }
};

/**
 * @description OwnersContextProvider component
 * @method OwnersContextProvider
 * @returns {'<OwnersContextProvider>'} <OwnersContextProvider>
 */
export const OwnersContextProvider = ({ children }) => {
  const [ownersState, ownersDispatch] = useReducer(ownersReducer, initialState);

  return (
    <OwnersContext.Provider
      value={{ ownersState, ownersDispatch }}
    >
      {children}
    </OwnersContext.Provider>
  );
};
