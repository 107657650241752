import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@material-ui/core';
import BranchesOverviewTable from '../../common/OverviewTable/OverviewTable';
import './AccordionStack.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    alignItems: 'center',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0),0px 0px 1px 0px rgba(0, 0, 0, 0),0px 1px 2px 0px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: 'inset 0 0 10px #00000014',
    },
  },
  accordionDetails: {
    boxShadow: 'inset 0 0 10px #0000006b',
  },
  expandedShadow: {
    boxShadow: 'inset 0 0 5px #00000014',
  },
}));

export default function OwnersAccordion({ singleBranch }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const overviewData = {
    [t('owners.loaded')]: singleBranch.total_deposited || '',
    [t('owners.spent')]: singleBranch.total_spent || '',
    [t('owners.withdrawn')]: singleBranch.total_withdrawn || '',
    [t('owners.undone')]: singleBranch.total_canceled_transactions || '',
    [t('owners.count')]: singleBranch.total_transactions || '',
    [t('owners.lastdate')]: singleBranch.last_transaction_time.replace('T', ' ') || '',
  };

  const handleChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        className={classes.accordion}
        expanded={expanded === true}
        onChange={handleChange(true)}
      >
        <AccordionSummary
          elevation={0}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          className={expanded ? classes.expandedShadow : ''}
        >
          <Typography className={classes.heading}>{ singleBranch.branch.external_id }</Typography>
          <Typography className={classes.heading}>{ singleBranch.branch.bg }</Typography>
          <Typography className={classes.heading}>{ singleBranch.branch.en }</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <BranchesOverviewTable overviewDataObject={overviewData} />
        </AccordionDetails>
      </Accordion>
    </div>

  );
}
