import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import CustomAccordion from './CustomAccordion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    alignItems: 'center',
  },
}));

export default function AccordionStack({
  headingID,
  editButtonID,
  deleteButtonID,
  panelsArray,
  handleEdit,
  handleDelete,
  cypressID,
}) {
  const classes = useStyles();

  return (
    <div>
      <Accordion
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>{headingID}</Typography>
          <Typography className={classes.heading}>Name BG</Typography>
          <Typography className={classes.heading}>Name EN</Typography>
        </AccordionSummary>
      </Accordion>
      {panelsArray.map((panel) => (
        <CustomAccordion
          id={panel.id}
          external_id={panel.external_id}
          nameBG={panel.name_bg}
          nameEN={panel.name_en}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          key={panel.id}
          editButtonID={editButtonID}
          deleteButtonID={deleteButtonID}
          cypressID={cypressID}
        />
      ))}
    </div>
  );
}

AccordionStack.propTypes = {
  headingID: PropTypes.string.isRequired,
  editButtonID: PropTypes.string.isRequired,
  deleteButtonID: PropTypes.string.isRequired,
  panelsArray: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
