import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import DeleteIcon from '@material-ui/icons/Delete';
import PreviewIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { SnackBarContext } from '../../../../../context/SnackBarContext';
import { DialogContext } from '../../../../../context/DialogContext';
import { EventsContext } from '../../../../../context/EventsContext';
import { addEventsData, changeEditEventId } from '../../../../../context/EventsActions';
import { deleteEvent, getEvents } from '../../../../../api/EventCalls';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
  media: {
    height: 140,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    wordWrap: 'break-word',
  },
  dynamicHeight: {
    height: 'calc(100% - 64px)',
  },
}));

/**
 * @description Displays single event using card.
 * @function EventCard
 * @param  {props} props - all of props from the parent.
 * @returns {'<Card>'} - Card component
 */
export default function EventCard(props) {
  const classes = useStyles();
  const { title, image, eventId } = props;
  const { eventsDispatch } = useContext(EventsContext);
  const { openSnackBar } = useContext(SnackBarContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const history = useHistory();
  async function handleDeleteEvent(event) {
    try {
      event.preventDefault();
      await deleteEvent(eventId);
      const events = await getEvents();
      addEventsData(events.data.reverse(), eventsDispatch);
      openSnackBar('info', `${title} event deleted.`, 'delete-event-success-popup');
    } catch (error) {
      openSnackBar('error', 'Something went wrong!', 'delete-event-fail-popup');
    }
    closeDialog();
  }

  function handleDeleteClick() {
    openDialog('error', 'Delete', `Event ${title} will be deleted!`, 'Are you sure?', 'delete-event-modal-button', handleDeleteEvent);
  }

  function handleEditEventClick() {
    changeEditEventId(eventId, eventsDispatch);
    history.push(`/edit/${eventId}`);
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.dynamicHeight}
        onClick={handleEditEventClick}
      >
        {
          image && title && (
          <CardMedia
            className={classes.media}
            title='Event name'
            image={image}
          />
          )
        }
        <CardContent>
          <Typography className={classes.title} id='card-content-title' gutterBottom variant='h5' component='h5'>
            {/* Get text from store */}
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Tooltip TransitionComponent={Zoom} title='Preview'>
          <IconButton id='edit-event-card-button' onClick={handleEditEventClick} color='primary'>
            <PreviewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip TransitionComponent={Zoom} title='Delete'>
          <IconButton id='delete-event-button' onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>

  );
}
