/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  Map as MapIcon,
  AddPhotoAlternate as AddPhoto,
} from '@material-ui/icons/';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FestclubSubranges from './FestclubSubranges/FestclubSubranges';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

/**
 * @description CustomerIDsAndNames component - Second step in create event form
 * @method CustomerIDsAndFiles
 * @returns {'<CustomerIDsAndFiles>'} <CustomerIDsAndFiles>
 */
export default function CustomerIDsAndFiles({ customerIDsAndNames, setCustomerIDsAndNames }) {
  const classes = useStyles();

  useEffect(() => {
    setCustomerIDsAndNames(customerIDsAndNames);
  }, [customerIDsAndNames, setCustomerIDsAndNames]);

  function handleStartCustIDChange(event) {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      start_customer_id: event.target.value,
    });
  }

  function handleEndCustIDChange(event) {
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      end_customer_id: event.target.value,
    });
  }

  function handleMapUpload(event) {
    const file = event.target.files[0];
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      map_photo: file,
    });
  }

  function handleEventPhotoUpload(event) {
    const file = event.target.files[0];
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      event_photo: file,
    });
  }

  function handleInactiveEventPhotoUpload(event) {
    const file = event.target.files[0];
    setCustomerIDsAndNames({
      ...customerIDsAndNames,
      not_active_image: file,
    });
  }

  return (
    <>
      <Typography component='h6' variant='h6' gutterBottom>
        Add Customer IDs and Names
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            component='span'
            required
            fullWidth
            id='start-cust-id'
            label='Start Customer ID (included)'
            type='number'
            margin='normal'
            value={customerIDsAndNames.start_customer_id}
            onChange={handleStartCustIDChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            component='span'
            required
            fullWidth
            id='end-cust-id'
            label='End Customer ID (included)'
            type='number'
            margin='normal'
            value={customerIDsAndNames.end_customer_id}
            onChange={handleEndCustIDChange}
          />
        </Grid>

        <Grid item xs={12} md={12} id='subrange-container'>
          <FestclubSubranges customerIDsAndNames={customerIDsAndNames} setCustomerIDsAndNames={setCustomerIDsAndNames} />
        </Grid>

        <Grid
          item
          style={{ marginBottom: '1rem' }}
          container
          xs={12}
          spacing={1}
          direction='row'
          alignItems='center'
          justify='center'
        >
          <input
            accept='image/*'
            className={classes.input}
            style={{ display: 'none' }}
            id='map-file'
            multiple
            type='file'
            onChange={handleMapUpload}
          />
          <label htmlFor='map-file'>
            <Button variant='contained' component='span' className={classes.button}>
              <MapIcon className={classes.extendedIcon} />
              Map Photo
            </Button>
          </label>
          <input
            accept='image/*'
            className={classes.input}
            style={{ display: 'none' }}
            id='event-file'
            multiple
            type='file'
            onChange={handleEventPhotoUpload}
          />
          <label htmlFor='event-file'>
            <Button variant='contained' component='span' className={classes.button}>
              <AddPhoto className={classes.extendedIcon} />
              Event Photo
            </Button>
          </label>
          <input
            accept='image/*'
            className={classes.input}
            style={{ display: 'none' }}
            id='inactive-event-file'
            multiple
            type='file'
            onChange={handleInactiveEventPhotoUpload}
          />
          <label htmlFor='inactive-event-file'>
            <Button variant='contained' component='span' className={classes.button}>
              <AddPhoto className={classes.extendedIcon} />
              Inactive Event Photo
            </Button>
          </label>
        </Grid>
      </Grid>
    </>
  );
}

CustomerIDsAndFiles.propTypes = {
  customerIDsAndNames: PropTypes.object.isRequired,
  setCustomerIDsAndNames: PropTypes.func.isRequired,
};
