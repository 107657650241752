import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Typography, Chip, TextField, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import { SnackBarContext } from '../../../../../../../context/SnackBarContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  title: {
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem !important',
    },
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
  actionContainer: {
    display: 'flex',
    margin: '1rem',
    padding: '1rem',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      margin: '0rem',
      padding: '16px',
      alignItems: 'inherit',
      flexDirection: 'column',
    },
  },
  textfield: {
    marginLeft: "1rem",
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
  button: {
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
}));

export default function EditCardSubranges({
  startCustomerId,
  endCustomerId,
  eventState,
  setEventState,
  isDisabled,
}) {
  const classes = useStyles();

  const isNumberInRange = (number, start, end) => (number >= start && number <= end)

  const isSubrangeInRange = (subrange, range) => (
    isNumberInRange(subrange.start, range.start, range.end) && 
    isNumberInRange(subrange.end, range.start, range.end) 
  )
  
  const isRangeOverlapping = (subrange, range) => (
    (subrange.end < range.end && subrange.end > range.start) ||
    (subrange.start < range.end && subrange.start > range.start)
  )
  
  const areSubrangesEqual = (subrangeLeft, subrangeRight) => (
    subrangeLeft.start === subrangeRight.start &&
    subrangeLeft.end === subrangeRight.end
  )
  
  const validateInput = (
    rangeAllShouldBePartOf /* {start: number, end: number} */ /* The BIG range */,
    subrangeToValidate /* {start: number, end: number} */, 
    allSubranges /* [{start: number, end: number}, ...]*/ /* Subranges that currenly chosen  */
  ) => {
    if(!isSubrangeInRange(subrangeToValidate, rangeAllShouldBePartOf))
      return {
        hasError: true, 
        errorMessage: "Subrange values must be in the main range."
      }
    if (allSubranges.some(subrange => areSubrangesEqual(subrange, subrangeToValidate)))
      return {
        hasError: true, 
        errorMessage: "Duplicate entry"
      }
    if (allSubranges.some(subrange => isRangeOverlapping(subrangeToValidate, subrange)))
      return {
        hasError: true, 
        errorMessage: "Overlapping entry"
      }
    return {
      hasError: false, 
      errorMessage: ""
    }
  }
  const [subrangeStart, setSubrangeStart] = useState('');
  const [subrangeEnd, setSubrangeEnd] = useState('');

  const { openSnackBar } = useContext(SnackBarContext);

  const handleStartSubrangeChange = (event) => {
    setSubrangeStart(event.target.value);
  };

  const handleEndSubrangeChange = (event) => {
    setSubrangeEnd(event.target.value);
  };

  const handleDelete = (id) => {
    setEventState({
      ...eventState,
      subranges: eventState?.subranges.filter((subrange) => subrange.id !== id),
    });
  };

  const handleAddClick = (e) => {
    e.preventDefault() 
    const { hasError, errorMessage } = validateInput(
        {start: Number(startCustomerId), end: Number(endCustomerId)}, 
        {start: Number(subrangeStart), end: Number(subrangeEnd)}, 
        eventState.subranges
      );

    
    if (!hasError) {
      setEventState({
        ...eventState,
        subranges: [...eventState.subranges, {
          start: Number(subrangeStart),
          end: Number(subrangeEnd),
          id: uuidv4(),
        }],
      });
      setSubrangeStart('');
      setSubrangeEnd('');
    } else {
      openSnackBar(
        'warning',
        errorMessage,
        'invalid-subrange-popup',
      );
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' align='center' className={classes.title}>
        Event Festclub Card Subranges
      </Typography>
      <div className={classes.chipsContainer} id='chips-container'>
        {eventState?.subranges?.map((subrange) => (
          <Chip
            key={subrange.id}
            label={`${subrange.start} - ${subrange.end}`}
            onDelete={() => handleDelete(subrange.id)}
            className={classes.chip}
            disabled={!!isDisabled}
          />
        ))}
      </div>
      <div className={classes.actionContainer}>
        <Typography
          variant='subtitle1'
          align='center'
          className={classes.subtitle}
        >
          Subrange:
        </Typography>
        <TextField
          label='Start'
          type='number'
          className={classes.textfield}
          value={subrangeStart}
          onChange={handleStartSubrangeChange}
          disabled={!!isDisabled}
          data-cy='start-subrange'
        />
        <TextField
          label='End'
          type='number'
          className={classes.textfield}
          value={subrangeEnd}
          onChange={handleEndSubrangeChange}
          disabled={!!isDisabled}
          data-cy='end-subrange'
          onKeyDown={(e) => { if (e.key === 'Enter') handleAddClick(e)}}
        />
        {isDisabled ? null : (
          <Button
            variant='outlined'
            color='primary'
            className={classes.button}
            onClick={handleAddClick}
            data-cy='add-subrange-button'
          >
            Add
          </Button>
        )}
      </div>
    </Paper>
  );
}

EditCardSubranges.propTypes = {
  startCustomerId: PropTypes.number.isRequired,
  endCustomerId: PropTypes.number.isRequired,
  setEventState: PropTypes.func.isRequired,
  eventState: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
