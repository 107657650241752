/* eslint-disable import/prefer-default-export */
import React, { createContext, useReducer } from 'react';

const initialState = {
  editEventId: 0,
  events: [],
};

export const EventsContext = createContext(initialState);

const addEventsData = (state, events) => ({ ...state, events });

const removeEventsData = (state) => ({ ...state, events: [] });

const changeEditEventId = (state, editEventId) => ({ ...state, editEventId });

/**
 * @description eventsReducer
 * @method eventsReducer
 */
const eventsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_EVENTS_DATA':
      return addEventsData(state, action.events);
    case 'REMOVE_EVENTS_DATA':
      return removeEventsData(state);
    case 'CHANGE_EDIT_EVENT_ID':
      return changeEditEventId(state, action.editEventId);
    default:
      return state;
  }
};

/**
 * @description EventsContextProvider component
 * @method EventsContextProvider
 * @returns {'<EventsContextProvider>'} <EventsContextProvider>
 */
export const EventsContextProvider = ({ children }) => {
  const [eventsState, eventsDispatch] = useReducer(eventsReducer, initialState);

  return (
    <EventsContext.Provider
      value={{ eventsState, eventsDispatch }}
    >
      {children}
    </EventsContext.Provider>
  );
};
