/* eslint-disable import/prefer-default-export */
import { loggedInAxios } from './LoggedInCalls';

/**
 * @description getVPOSOptions api call
 * @async
 * @method getVPOSOptions
 * @returns {Object} response object
 */
export async function getVPOSOptions() {
  const response = await loggedInAxios.get('/vpos/');
  return response;
}
