import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, DialogTitle as MuiDialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function WhiteBackgroundDialogTitle({ children }) {
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {children}
    </MuiDialogTitle>
  );
}

WhiteBackgroundDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
