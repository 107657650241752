import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import OwnersAccordion from './OwnersAccordion';
import './AccordionStack.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    alignItems: 'center',
  },
}));

export default function AccordionStack({
  branchesOverview,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Accordion
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>
            {t('owners.branch_name')}
            {' '}
            ID
          </Typography>
          <Typography className={classes.heading}>
            {t('owners.name')}
            {' '}
            BG
          </Typography>
          <Typography className={classes.heading}>
            {t('owners.name')}
            {' '}
            EN
          </Typography>
        </AccordionSummary>
      </Accordion>
      {branchesOverview.map((singleBranch) => (
        <OwnersAccordion
          key={singleBranch.branch.external_id}
          singleBranch={singleBranch}
        />
      ))}
    </div>
  );
}
