export const isValueInRange = (number, start, end) => parseInt(number, 10) >= parseInt(start, 10) && parseInt(number, 10) <= parseInt(end, 10);

export const isValueEmpty = (value) => value === '';

export const areValuesEqual = (start, end) => start === end;

export const isStartOfRangeSmallerThanEnd = (start, end) => parseInt(start, 10) <= parseInt(end, 10);

export const isInArray = (ranges, start, end, id) => ranges.some((range) => parseInt(range.start, 10) === start && parseInt(range.end, 10) === end && range.id !== id);

export const overlaps = (ranges, start, end, id) => ranges.some((range) => {
  const parsedStart = parseInt(range.start, 10);
  const parsedEnd = parseInt(range.end, 10);

  return (parsedStart <= start && parsedEnd >= start && range.id !== id)
  || (parsedStart <= end && parsedEnd >= end && range.id !== id)
  || (parsedStart >= start && parsedEnd <= end && range.id !== id);
});
