import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Dialog from '@material-ui/core/Dialog';
import ComitexLogo from '../Images/comitex.png';
import './Spinner.css';

export default function Spinner() {
  const { promiseInProgress } = usePromiseTracker(
    { delay: 500 },
  );
  if (!promiseInProgress) return <></>;
  return (
    <Dialog
      open
    >
      <img className='spinner' src={ComitexLogo} alt='spinner' />
    </Dialog>
  );
}
