import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import StoreIcon from '@material-ui/icons/Store';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TerminalIcon from '../../../../common/CustomIcons/TerminalIcon';

export default function NavigationListItems() {
  const history = useHistory();
  const { hash } = useParams();
  const { t } = useTranslation();

  const handleClickDashboard = () => {
    history.push(`/owners/${hash}`);
  };

  const handleClickBranches = () => {
    history.push(`/owners/${hash}/branches`);
  };

  const handleClickTerminals = () => {
    history.push(`/owners/${hash}/terminals`);
  };

  return (
    <div>
      <ListItem button onClick={handleClickDashboard}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t('owners.menu.overview')} />
      </ListItem>
      <ListItem button onClick={handleClickBranches}>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary={t('owners.menu.branches')} />
      </ListItem>
      <ListItem button onClick={handleClickTerminals}>
        <ListItemIcon>
          <TerminalIcon />
        </ListItemIcon>
        <ListItemText primary={t('owners.menu.terminals')} />
      </ListItem>
    </div>
  );
}
