import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import GroupsAccordion from './GroupsAccordion';
import './CustomAccordionStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '30%',
    flexShrink: 0,
  },
  accordion: {
    alignItems: 'center',
  },
}));

export default function GroupsAccordionStack({
  panelsArray,
  handleEdit,
  handleDelete,
  defaultGroupBranches,
}) {
  const classes = useStyles();

  return (
    <div>
      <Accordion
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordion}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>Group ID</Typography>
          <Typography className={classes.heading}>Name BG</Typography>
          <Typography className={classes.heading}>Name EN</Typography>
        </AccordionSummary>
      </Accordion>
      <GroupsAccordion
        nameBG='По подразбиране'
        nameEN='Default Group'
        showButtons={false}
        branchesArray={defaultGroupBranches}
      />
      {panelsArray.map((panel) => (
        <GroupsAccordion
          id={panel.id}
          nameBG={panel.name_bg}
          nameEN={panel.name_en}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          branchesArray={panel.branches}
          showButtons
          key={panel.id}
        />
      ))}
    </div>
  );
}
